import * as FaIconsMap from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import { StyledVmsIconBase, VmsIcons } from 'svr/common/VmsIcons'

const VmsIconsValues = Object.keys(VmsIcons).reduce(
  (acc, iconKey) => ({
    ...acc,
    [VmsIcons[iconKey]]: iconKey,
  }),
  {}
)

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${props => `${props.size}px`};
  min-width: ${props => `${props.size}px`};
  max-height: ${props => `${props.size}px`};
  max-width: ${props => `${props.size}px`};
  font-size: ${props => `${props.size}px`};
`

const IconWrapper = ({ icon }) => {
  if (VmsIconsValues[icon]) {
    return <StyledVmsIconBase>{icon}</StyledVmsIconBase>
  }

  if (!icon) {
    return null
  }

  return <FontAwesomeIcon icon={icon} />
}

const Icon = ({ icon, size, ...props }) => {
  const dataProps = passDataAttrs(props)
  return (
    <Container size={size} {...dataProps}>
      <IconWrapper icon={icon} />
    </Container>
  )
}

Icon.ICONS = { ...VmsIcons, ...FaIconsMap }

Icon.SIZES = {
  XX_SMALL: 12,
  X_SMALL: 14,
  SMALL: 20,
  MEDIUM: 40,
  LARGE: 60,
  SUB_HEADER: 28,
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.values(Icon.ICONS)).isRequired,
  size: PropTypes.number,
}

Icon.defaultProps = {
  size: Icon.SIZES.SMALL,
}

export default Icon
