export const initializeVenueReducer = (venue, widgetSettings, orderingBrandSettings) => ({
  ...venue,
  widgetSettings,
  orderingBrandSettings,
})

const venueReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default venueReducer
