import React from 'react'
import styled from 'styled-components'

const MarkerAddressLabelContainer = styled.div`
  display: inline-block;
  padding: 3px;
  background: ${props => props.theme.background};
  font-size: ${props => props.theme.fontSize.xxsmall};
  width: max-content;
  bottom: 35px;
  left: 13px;
  position: relative;
`

const MarkerAddressLabel = ({ addressFormatted, dataTest }) => (
  <MarkerAddressLabelContainer data-test={dataTest}>{addressFormatted}</MarkerAddressLabelContainer>
)

export const VenueMarker = ({ addressFormatted, dataTest }) => (
  <>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test={dataTest && `${dataTest}-image`}
    >
      <circle cx="9" cy="9" r="9" fill="#484848" />
      <circle cx="9" cy="9" r="4.5" fill="white" />
    </svg>
    <MarkerAddressLabel addressFormatted={addressFormatted} dataTest={dataTest && `${dataTest}-address`} />
  </>
)

export const DeliveryMarker = ({ addressFormatted, dataTest }) => (
  <>
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test={dataTest && `${dataTest}-image`}
    >
      <rect x="5.27307" width="15.8403" height="15.8403" transform="rotate(19.4444 5.27307 0)" fill="#484848" />
      <rect x="7.69019" y="5.05258" width="7.92016" height="7.92016" transform="rotate(19.4444 7.69019 5.05258)" fill="white" />
    </svg>
    <MarkerAddressLabel addressFormatted={addressFormatted} dataTest={dataTest && `${dataTest}-address`} />
  </>
)

export const LocationMark = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
    <path
      d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
      fill="#5F6377"
    />
  </svg>
)
