/* eslint-disable camelcase */
import { combineReducers } from 'redux'
import { camelCaseObject } from 'svr/common/ObjectUtils'
import cartReducer from 'widget/universal/reducers/ordering/cart'
import checkoutReducer, { loadCheckoutSettings } from 'widget/universal/reducers/ordering/checkout'
import errorModalReducer from 'widget/universal/reducers/ordering/errormodal'
import itemsReducer, { formatInitialItemsState } from 'widget/universal/reducers/ordering/items'
import menusReducer, { formatInitialMenuState } from 'widget/universal/reducers/ordering/menus'
import modifiersReducer, { formatInitialModifiersState } from 'widget/universal/reducers/ordering/modifiers'
import orderingSiteReducer from 'widget/universal/reducers/ordering/orderingsite'
import pickupDeliveryReducer, { formatInitialPickupDeliveryState } from 'widget/universal/reducers/ordering/pickupdelivery'
import successPageReducer from 'widget/universal/reducers/ordering/successpage'

export const initializeOrderingReducers = (
  menu_data,
  venue,
  ordering_site_data,
  checkout_settings_data,
  delivery_integration,
  ordering_room_numbers
) => ({
  menus: formatInitialMenuState(menu_data.menus, venue),
  items: formatInitialItemsState(menu_data.items),
  modifiers: formatInitialModifiersState(menu_data.modifierGroups, menu_data.modifiers),
  pickupDelivery: formatInitialPickupDeliveryState(ordering_site_data.ordering_site),
  orderingSite: {
    ...camelCaseObject(ordering_site_data),
    deliveryIntegration: delivery_integration,
    orderingRoomNumbers: camelCaseObject(ordering_room_numbers.ordering_room_numbers),
  },
  checkout: loadCheckoutSettings(camelCaseObject(checkout_settings_data)),
})

export default combineReducers({
  menus: menusReducer,
  items: itemsReducer,
  cart: cartReducer,
  modifiers: modifiersReducer,
  orderingSite: orderingSiteReducer,
  checkout: checkoutReducer,
  pickupDelivery: pickupDeliveryReducer,
  successPage: successPageReducer,
  errorModal: errorModalReducer,
})
