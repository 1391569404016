import React from 'react'
import styled from 'styled-components'

export const OrderSpecialInstructions = styled.div`
  margin-top: 24px;
`

const OrderInstructions = ({ instructions, label = 'Order Instructions:', dataTest, style }) =>
  instructions && (
    <OrderSpecialInstructions style={style} data-test={dataTest}>
      <b>{label}</b> {instructions}
    </OrderSpecialInstructions>
  )

export default OrderInstructions

OrderInstructions.propTypes = {
  label: React.PropTypes.string,
  instructions: React.PropTypes.array,
  style: React.PropTypes.object,
  dataTest: React.PropTypes.string,
}
