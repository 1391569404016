import { CLOSE_ERROR_MESSAGE, SHOW_ERROR_MESSAGE } from 'widget/universal/actions/ordering/ActionTypes'
import { selectOrderingSite } from 'widget/universal/selectors/ordering/schedules'
import { redirectBackToMenu } from 'widget/universal/utils/ordering/utilfunctions'

export const showError = (message = null, redirectToMenu = false, heading = null) => ({
  type: SHOW_ERROR_MESSAGE,
  message,
  redirectToMenu,
  heading,
})

export const closeError = () => (dispatch, getState) => {
  const state = getState()
  const { redirectToMenu } = state.ordering.errorModal
  dispatch({
    type: CLOSE_ERROR_MESSAGE,
  })
  if (redirectToMenu) {
    const venueUrlKey = state.venue.urlKey
    const orderingSiteUrlKey = selectOrderingSite(state).urlKey
    dispatch(redirectBackToMenu(venueUrlKey, orderingSiteUrlKey))
  }
}

export const handleError = (error, redirectToMenuHandler) => dispatch => {
  const redirectToMenu = error.status === 409
  dispatch(showError(error.msg, redirectToMenu))
  if (redirectToMenu) {
    dispatch(redirectToMenuHandler())
  }
}
