import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'

const initialState = {
  showError: false,
  errorMessage: '',
  errorHeading: 'Error',
  redirectToMenu: false,
}

const errorModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_ERROR_MESSAGE:
      return {
        ...state,
        showError: !!action.message,
        redirectToMenu: action.redirectToMenu,
        errorMessage: action.message,
        errorHeading: action.heading || 'Error',
      }
    case ActionTypes.CLOSE_ERROR_MESSAGE:
      return {
        ...state,
        showError: false,
        redirectToMenu: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default errorModalReducer
