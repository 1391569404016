import { faMapMarkerAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import AddressInput from 'svr/component-lib/Generic/TextInputs/AddressInput'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import Button from 'svr/component-lib/Widget/Buttons/Ordering'
import SegmentedControl from 'svr/component-lib/Widget/Navigation/SegmentedControl'
import { FULFILLMENT_METHOD } from 'svr/constants'
import { formatTimeSlotWindow, formatOrderAheadDate } from 'widget/universal/selectors/ordering/schedules'
import { SCHEDULE_NOW, SCHEDULE_FUTURE } from 'widget/universal/utils/ordering/constants'

const ICON_CHECKMARK = '\\2713'
const ICON_INVALID = '\\2757'
const ICON_ELLISPES = '\\22EF'

const PickupDeliveryContentContainer = styled.div`
  padding-top: 32px;
`

const SaveContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gutter.double};
  justify-content: center;
  align-items: center;
`

const saveButtonCss = css`
  width: 50%;
`

const SegControlContainer = styled.div`
  margin: 0 0 ${props => props.theme.gutter.medium};
`

const Header = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 ${props => props.theme.gutter.double};
`

const BodyText = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin: ${props => props.theme.gutter.standard} 0;
`

const ClosedBanner = styled(BodyText)`
  color: #fff;
  background: ${props => props.theme.error};
  padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.standard};
  border-radius: 5px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const InputContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gutter.medium};
`

const LocationContainer = styled.div`
  margin: ${props => props.theme.gutter.standard} 0 ${props => props.theme.gutter.medium};
`

const AddressInvalid = styled(BodyText)`
  color: ${props => props.theme.error};
`

const AddressWarning = styled(BodyText)`
  color: ${props => props.theme.warning};
`

const IconLine = styled.div`
  align-items: center;
  display: flex;
  line-height: 1.3;
`

const IconTweak = styled.span`
  flex-shrink: 0;
  font-size: 13px;
  margin-right: ${props => props.theme.gutter.standard};
  width: 16px;
  height: 16px;
  color: ${props => props.theme.ordering.primary};

  ${props => props.hide && 'display: none;'}
`

const Icon = ({ icon, hide = false }) => (
  <IconTweak hide={hide}>
    <FontAwesomeIcon class="fa-fw" icon={icon} />
  </IconTweak>
)

const RadioOptionContainer = styled.button`
  flex: 1;
  text-align: left;
  height: 48px;
  font-size: 14px;
  padding: ${props => props.theme.gutter.double};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.selected ? props.theme.ordering.primary : props.theme.ordering.lightGrey)};
  border-radius: 4px;
  color: ${props => (props.disabled ? props.theme.darkGrey : props.selected ? props.theme.ordering.primary : '#000')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: ${props => (props.disabled ? props.theme.lightGrey : props.theme.background)};
  display: flex;
  align-items: center;

  &:after {
    content: '${props => (props.selected ? props.selectedIcon : '')}';
  }
`

const radioHeight = 18
const selectOffset = 4
const selectHeight = radioHeight - selectOffset * 2

const RadioElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.greySecondary};
  border-radius: ${radioHeight}px;
  height: ${radioHeight}px;
  width: ${radioHeight}px;
  margin-right: ${props => props.theme.gutter.double};
`

const Selected = styled.div`
  background: ${props => props.theme.ordering.primary};
  border-radius: ${selectHeight}px;
  height: ${selectHeight}px;
  width: ${selectHeight}px;
`

const RadioOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > button:not(:last-child) {
    margin-bottom: ${props => props.theme.gutter.standard};
  }
`

const RadioOptions = ({ options }) => (
  <RadioOptionsContainer>
    {options.map(option => (
      <RadioOption
        selected={option.selected}
        key={option.key}
        testId={option.testId}
        onClick={() => option.onClick && option.onClick(option)}
        disabled={option.disabled}
      >
        {option.radioContent}
      </RadioOption>
    ))}
  </RadioOptionsContainer>
)

const RadioOption = ({ children, selected, testId = '', onClick, disabled }) => (
  <RadioOptionContainer
    disabled={disabled}
    data-test={testId}
    onClick={() => {
      if (!disabled) {
        onClick()
      }
    }}
  >
    <RadioElement>{selected && <Selected />}</RadioElement>
    {children}
  </RadioOptionContainer>
)

const XWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gutter.half};
  right: ${props => props.theme.gutter.standard};
  width: 48px;
  height: 48px;
  font-size: 28px;
  color: ${props => props.theme.ordering.iconGrey};
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

const PickupOrRoomServiceDisplay = (venue, selectedMethod) => (
  <>
    <Header>{selectedMethod === 'roomService' ? 'Room Service from' : 'Picking up at'}</Header>
    <BodyText data-test="sr-label-venue-address">
      <IconLine>
        <Icon icon={faMapMarkerAlt} />
        <span>
          {venue.address} {venue.cityState} {venue.postalCode}
        </span>
      </IconLine>
    </BodyText>
  </>
)
const PickupDisplay = ({ venue }) => PickupOrRoomServiceDisplay(venue, 'pickup')
const RoomServiceDisplay = ({ venue }) => PickupOrRoomServiceDisplay(venue, 'roomService')

const getAddress2Placeholder = countryCode => {
  if (countryCode === 'gb') {
    return 'Flat number / Name of building (optional)'
  }
  if (countryCode === 'au') {
    return 'Unit / Level / Building name (optional)'
  }
  return 'Apt / Floor / Suite # (optional)'
}

const OrderAheadContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const AddressForm = ({
  countryCode,
  latitude,
  longitude,
  boundsDistanceKm,
  deliveryAddress,
  addressLine2,
  isDeliveryAddressValid,
  isDeliveryAddressValidating,
  isDeliveryAddressMissingStreetNumber,
  updateDeliveryAddress,
  updateAddressLine2,
}) => {
  const showInvalidAddress = !_.isEmpty(deliveryAddress) && !isDeliveryAddressValidating && !isDeliveryAddressValid
  const icon = isDeliveryAddressValid
    ? ICON_CHECKMARK
    : showInvalidAddress
    ? ICON_INVALID
    : isDeliveryAddressValidating
    ? ICON_ELLISPES
    : ''
  return (
    <>
      <Header>Delivering to</Header>
      <InputContainer>
        <AddressInput
          googlePlacesApiKey={window.PRELOADED.google_places_api_key}
          placeholder="Enter your street address"
          showLabel={false}
          value={deliveryAddress}
          isValid={!showInvalidAddress}
          onChange={(address, place) => updateDeliveryAddress(address, place)}
          testId="sr-input-address"
          inputStyles={css`
            font-family: 'Inter', sans-serif;
          `}
          {...{
            latitude,
            longitude,
            icon,
            boundsDistanceKm,
          }}
        />
      </InputContainer>
      {(showInvalidAddress && (
        <AddressInvalid data-test="sr-label-delivery-out-of-range">Unfortunately we do not deliver to your address.</AddressInvalid>
      )) ||
        (isDeliveryAddressMissingStreetNumber && (
          <AddressWarning data-test="sr-label-delivery-out-of-range">Warning: No street number is specified.</AddressWarning>
        ))}
      <InputContainer>
        <TextInput
          placeholder={getAddress2Placeholder(countryCode)}
          value={addressLine2}
          onChange={v => updateAddressLine2(v)}
          testId="sr-input-address-line-2"
          inputPadding={10}
          fontSize={14}
          customInputWrapper={css`
            flex: 1;
            input {
              height: 42px;
            }
          `}
          customInput={css`
            font-family: 'Inter', sans-serif;
          `}
        />
      </InputContainer>
    </>
  )
}

const ScheduleOptions = ({
  orderingSite,
  scheduleNowRange,
  scheduleNextAvailable,
  scheduleType,
  saveScheduleNow,
  selectScheduleFuture,
  orderAheadDate,
  orderAheadTime,
  timeSlotWidthMinutes,
  locale,
}) => (
  <>
    {orderingSite.maxOrderAheadMins > 0 && <BodyText>When do you want your order?</BodyText>}
    <RadioOptions
      options={[
        {
          selected: scheduleType === SCHEDULE_NOW && !_.isNil(scheduleNowRange),
          radioContent: (
            <div>
              Now{' '}
              {scheduleNowRange === null
                ? `- not available${scheduleNextAvailable.menuText && ` until ${scheduleNextAvailable.menuText}`}`
                : scheduleNowRange === ''
                ? `(enter address for time estimate)`
                : `(${scheduleNowRange} min)`}
            </div>
          ),
          onClick: saveScheduleNow,
          testId: 'sr-schedule-option-now',
          key: 'schedule-now-option-key',
          disabled: _.isNil(scheduleNowRange),
        },
        ...(orderingSite.maxOrderAheadMins > 0
          ? [
              {
                selected: scheduleType === SCHEDULE_FUTURE,
                radioContent: (
                  <OrderAheadContainer>
                    <div>
                      {_.isNil(orderAheadDate) || _.isNil(orderAheadTime)
                        ? 'Schedule for future time'
                        : `
                      ${formatOrderAheadDate(orderAheadDate, locale)} at
                      ${formatTimeSlotWindow(orderAheadTime, timeSlotWidthMinutes)}
                    `}
                    </div>
                    <Icon hide={scheduleType !== SCHEDULE_FUTURE} icon={faPencilAlt} />
                  </OrderAheadContainer>
                ),
                onClick: selectScheduleFuture,
                testId: 'sr-schedule-option-future',
                key: 'schedule-future-option-key',
              },
            ]
          : []),
      ]}
    />
  </>
)

const canSaveOrderAhead = (orderAheadDate, orderAheadTime) => !_.isNil(orderAheadDate) && !_.isNil(orderAheadTime)

const canSaveModal = (pickupDeliverySelection, isDeliveryAddressValid, scheduleType, scheduleNowRange, orderAheadDate, orderAheadTime) => {
  const pickupOrRoomServiceOrValidAddress =
    pickupDeliverySelection === FULFILLMENT_METHOD.PICKUP ||
    pickupDeliverySelection === FULFILLMENT_METHOD.ROOM_SERVICE ||
    isDeliveryAddressValid
  const scheduleNowValid = scheduleType === SCHEDULE_NOW && !_.isNil(scheduleNowRange)
  const scheduleFutureValid = scheduleType === SCHEDULE_FUTURE && canSaveOrderAhead(orderAheadDate, orderAheadTime)
  const scheduleValid = scheduleNowValid || scheduleFutureValid
  return pickupOrRoomServiceOrValidAddress && scheduleValid
}

const segCtrlOptionPickup = {
  name: 'Pickup',
  value: FULFILLMENT_METHOD.PICKUP,
  testId: FULFILLMENT_METHOD.PICKUP,
}
const segCtrlOptionDelivery = {
  name: 'Delivery',
  value: FULFILLMENT_METHOD.DELIVERY,
  testId: FULFILLMENT_METHOD.DELIVERY,
}
const segCtrlOptionRoomService = {
  name: 'Room Service',
  value: FULFILLMENT_METHOD.ROOM_SERVICE,
  testId: FULFILLMENT_METHOD.ROOM_SERVICE,
}
const segCtrlOptions = {
  [FULFILLMENT_METHOD.PICKUP_OR_DELIVERY]: [segCtrlOptionPickup, segCtrlOptionDelivery],
  [FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE]: [segCtrlOptionPickup, segCtrlOptionRoomService],
}

const PickupDeliveryContent = ({
  venue,
  orderingSite,
  pickupDeliverySelection,
  isDeliveryAddressValid,
  isDeliveryAddressValidating,
  isDeliveryAddressMissingStreetNumber,
  deliveryAddress,
  addressLine2,
  updateDeliveryAddress,
  updateAddressLine2,
  cancelPickupDeliveryModal,
  savePickupDeliveryModal,
  changePickupDeliverySelection,
  scheduleNowRange,
  scheduleNextAvailable,
  scheduleType,
  saveScheduleNow,
  selectScheduleFuture,
  orderAheadDate,
  orderAheadTime,
  timeSlotWidthMinutes,
  requiresPickupDeliverySelection,
  showBrowseMenuOption,
}) => {
  const isSaveEnabled = canSaveModal(
    pickupDeliverySelection,
    isDeliveryAddressValid,
    scheduleType,
    scheduleNowRange,
    orderAheadDate,
    orderAheadTime
  )
  // We want closing modal to save if current state is valid (ORDER-402)
  const closeAction = isSaveEnabled ? savePickupDeliveryModal : cancelPickupDeliveryModal
  const { countryCode, latitude, longitude, boundsDistanceKm } = venue

  return (
    <PickupDeliveryContentContainer>
      <XWrapper data-test="sr-fulfillment-modal-button-x" onClick={closeAction}>
        &times;
      </XWrapper>
      {(orderingSite.fulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_DELIVERY ||
        orderingSite.fulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE) && (
        <SegControlContainer>
          <SegmentedControl
            options={segCtrlOptions[orderingSite.fulfillmentMethod]}
            value={pickupDeliverySelection}
            width="100%"
            height={48}
            theme="dark"
            onChangeHandler={changePickupDeliverySelection}
          />
        </SegControlContainer>
      )}

      <FormContainer>
        <LocationContainer>
          {pickupDeliverySelection === FULFILLMENT_METHOD.PICKUP ? (
            <PickupDisplay venue={venue} />
          ) : pickupDeliverySelection === FULFILLMENT_METHOD.ROOM_SERVICE ? (
            <RoomServiceDisplay venue={venue} />
          ) : (
            <AddressForm
              {...{
                countryCode,
                latitude,
                longitude,
                boundsDistanceKm,
                deliveryAddress,
                addressLine2,
                isDeliveryAddressValid,
                isDeliveryAddressValidating,
                isDeliveryAddressMissingStreetNumber,
                updateDeliveryAddress,
                updateAddressLine2,
              }}
            />
          )}
        </LocationContainer>

        {showBrowseMenuOption ? (
          <ClosedBanner data-test="sr-label-closed-banner">
            Sorry we are currently closed
            {scheduleNextAvailable.menuText && ` until ${scheduleNextAvailable.menuText}`}.
          </ClosedBanner>
        ) : (
          <ScheduleOptions
            {...{
              orderingSite,
              scheduleNowRange,
              scheduleNextAvailable,
              scheduleType,
              saveScheduleNow,
              selectScheduleFuture,
              orderAheadDate,
              orderAheadTime,
              timeSlotWidthMinutes,
              locale: venue.locale,
            }}
          />
        )}
      </FormContainer>

      <SaveContainer>
        {showBrowseMenuOption ? (
          <Button data-test="sr-button-browse-menu" onClick={closeAction} styled={saveButtonCss}>
            Browse Menu
          </Button>
        ) : (
          <Button
            data-test="sr-button-save-pickup-delivery-modal"
            onClick={savePickupDeliveryModal}
            disabled={!isSaveEnabled}
            styled={saveButtonCss}
          >
            {requiresPickupDeliverySelection ? 'Start Ordering' : 'Save'}
          </Button>
        )}
      </SaveContainer>
    </PickupDeliveryContentContainer>
  )
}

export default PickupDeliveryContent
