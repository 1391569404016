import Theme from 'mgr/layout/Theme'

// eslint-disable-next-line camelcase
declare const PRELOADED: { ordering_brand_settings: { color_primary: string; fonts_color_links: string } }

const { ordering_brand_settings: orderingBrandSettings } = PRELOADED

export const getTheme = () => ({
  ...Theme,
  ordering: {
    lightGrey: '#E0E0E0',
    primary: orderingBrandSettings.color_primary,
    fontsColorLinks: orderingBrandSettings.fonts_color_links,
    formOutline: '#CFD0D7',
    green: '#219653',
    black: '#1b1c1e',
    iconGrey: '#5F6377',
    disabledText: '#939393',
    imageScreenColor: 'rgb(244, 244, 245)',
  },
  error: '#F35757',
  warning: '#AA5D00',
  success: '#0D6834',
  background: '#FCFCFD',
  primary: '#2F80ED',
  primarySelected: '#2F80ED',
  darkGrey: '#8A8A8A',
})

export type WidgetTheme = ReturnType<typeof getTheme>
