import React from 'react'
import ErrorModal from 'widget/universal/components/ordering/ErrorModal'

const PrepTimeChangedModal = ({ scheduleNowRange, dismissPrepTimeChangedModal }) =>
  scheduleNowRange === null ? (
    <ErrorModal okAction={() => dismissPrepTimeChangedModal(false)} okText="Okay">
      <p>The time you selected for your order is no longer available. Please select a new time.</p>
    </ErrorModal>
  ) : (
    <ErrorModal
      okAction={() => dismissPrepTimeChangedModal(true)}
      okText="Place Order"
      cancelAction={() => dismissPrepTimeChangedModal(false)}
      cancelText="Cancel"
      heading="Warning"
    >
      <p>The estimated time has changed to {scheduleNowRange} minutes. Do you still want to place your order?</p>
    </ErrorModal>
  )

export default PrepTimeChangedModal
