import OrderInstructions from 'widget/universal/components/ordering/successPage/OrderInstructions'
import { CardInfoLine, CardHeading, BorderContainer } from './styles'

interface RoomServiceDetailsProps {
  orderFirstName: string
  orderLastName: string
  roomNumber: string
  orderEmail: string
  orderPhoneNumber: string
  notesForRestaurant?: string
  showNoteToRestaurant: boolean
  instructions?: string
}
function RoomServiceDetails({
  orderFirstName,
  orderLastName,
  roomNumber,
  orderEmail,
  orderPhoneNumber,
  notesForRestaurant,
  instructions,
  showNoteToRestaurant,
}: RoomServiceDetailsProps) {
  return (
    <BorderContainer>
      <CardHeading>Room Service Details</CardHeading>
      {orderFirstName && (
        <>
          <CardInfoLine data-test="confirmation-room_service-first_name-last_name">
            {orderFirstName} {orderLastName}
          </CardInfoLine>
          <CardInfoLine data-test="confirmation-room_service-address">Room {roomNumber}</CardInfoLine>
          <CardInfoLine data-test="confirmation-room_service-email">{orderEmail}</CardInfoLine>
          <CardInfoLine data-test="confirmation-room_service-phone_number">{orderPhoneNumber}</CardInfoLine>
          {showNoteToRestaurant && (
            <OrderInstructions
              instructions={notesForRestaurant}
              label="Notes for restaurant:"
              style={{ marginBottom: 0 }}
              dataTest="confirmation-room_service-notes_for_restaurant"
            />
          )}
          <OrderInstructions instructions={instructions} dataTest="confirmation-room_service-delivery_instructions" />
        </>
      )}
    </BorderContainer>
  )
}

export default RoomServiceDetails
