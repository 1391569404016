import { arrayToObjectById } from 'widget/universal/utils/objects'

export const formatInitialModifiersState = (modifierGroups, modifiers) => ({
  modifierGroupsById: arrayToObjectById(modifierGroups),
  modifiersById: arrayToObjectById(modifiers),
})

const modifiersReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default modifiersReducer
