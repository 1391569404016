// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components' // to be fixed in ORDER-819
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface ProductTagProps {
  content: string
  limitOnWidth?: boolean
  isDisabled?: boolean
}

export function ProductTag({ content, limitOnWidth = false, isDisabled = false }: ProductTagProps) {
  return (
    <Tag pr="xs" pl="xs" height="20px" isDisabled={isDisabled} limitOnWidth={limitOnWidth}>
      <Text
        color={isDisabled ? 'inactive' : 'primaryFont'}
        fontWeight="bold"
        textTransform="uppercase"
        fontSize="xs"
        lineHeight="xs"
        textOverflow="ellipsis"
      >
        {content}
      </Text>
    </Tag>
  )
}

const Tag = styled(Box)<{ isDisabled: boolean; limitOnWidth: boolean }>`
  border-radius: 3px;
  background: ${props => props.theme.colors.deactivated};

  ${props =>
    props.limitOnWidth &&
    css`
      max-width: 130px;
      @media (max-width: ${props => props.theme.breakpoints.xs}px) {
        max-width: 120px;
      }
    `}
`
