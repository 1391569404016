import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'svr/component-lib/Generic/Buttons/StandardButton'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'

const ModalContentContainer = styled.div`
  max-height: 90vh;
  min-height: 200px;
  width: 435px;

  @media (max-width: ${BREAKPOINTS.minMobile}px) {
    width: 100%;
  }
`
const Interior = styled.div`
  text-align: ${props => props.textAlign};
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: ${props => props.theme.gutter.triple};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  overflow-y: auto;
`

const Alert = styled.div`
  text-align: center;
  color: ${props => props.theme.error};
`

const buttonCss = css`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  height: 35px;
  line-height: 35px;
  width: 100px;
  &:not(:last-child) {
    margin-right: ${props => props.theme.gutter.double};
  }
`

const okButtonCss = css`
  ${buttonCss}
  background-color: ${props => props.theme.ordering.primary} !important;
  border-color: ${props => props.theme.ordering.primary} !important;
  color: white !important;
`

const cancelButtonCss = css`
  ${buttonCss}
  background-color: #FFF !important;
  border-color: ${props => props.theme.ordering.formOutline} !important;
  color: ${props => props.theme.ordering.black} !important;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Body = styled.div`
  overflow: auto;
`

const ErrorModal = ({
  okAction = () => {},
  okText = 'Okay',
  cancelAction = () => {},
  cancelText = null,
  heading = 'Error',
  textAlign = 'center',
  children,
}) => (
  <ModalCover
    customModalWrapper={css`
      @media (max-width: ${BREAKPOINTS.minMobile}px) {
        width: calc(100% - ${props => props.theme.gutter.standard});
      }
    `}
  >
    <ModalContentContainer>
      <Interior textAlign={textAlign}>
        <Alert data-test="error-modal-header">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {heading}
        </Alert>
        <Body data-test="error-modal-body">{children}</Body>
        <ButtonContainer>
          {_.some(cancelText) && (
            <Button data-test="cancel-error-button" customButton={cancelButtonCss} onClick={cancelAction}>
              {cancelText}
            </Button>
          )}
          <Button data-test="close-error-button" customButton={okButtonCss} onClick={okAction}>
            {okText}
          </Button>
        </ButtonContainer>
      </Interior>
    </ModalContentContainer>
  </ModalCover>
)

export default ErrorModal
