import moment from 'moment-timezone'
import { formatDateOnly, formatTimeOnly } from 'mgr/lib/utils/MomentUtils'

let nowOverride

export const setDefaultTimezone = timezone => {
  moment.tz.setDefault(timezone)
}

export const venueToday = venue => {
  const nowMoment = moment(nowOverride).tz(venue.timezone)
  const venueNowTime = formatTimeOnly(nowMoment)
  if (venueNowTime.hour() < venue.startOfDayHour) {
    nowMoment.subtract({ days: 1 })
  }
  return formatDateOnly(nowMoment)
}

export const nowToNearestMinute = state => moment(nowOverride).seconds(0).milliseconds(0).tz(state.venue.timezone)

// Expose hook for QA Automation
window.moment = moment
window.svr_setNowOverride = o => {
  nowOverride = o
}
