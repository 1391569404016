import moment from 'moment-timezone'
import type { OrderingMenuItem } from '@sevenrooms/core/domain'

let OVERRIDE_IS_ITEM_SNOOZED = false
window.svr_overrideIsItemSnoozed = (overrideIsItemSnoozed: boolean) => {
  OVERRIDE_IS_ITEM_SNOOZED = overrideIsItemSnoozed
}

export const checkIsItemSnoozed = (item: OrderingMenuItem | undefined): boolean => {
  if (OVERRIDE_IS_ITEM_SNOOZED) {
    return true
  }
  if (!item || !item.snoozeStartTime) {
    return false
  }
  const currentTime = moment()
  const itemIndefinitelySnoozed = !item.snoozeEndTime && currentTime.isAfter(moment(`${item.snoozeStartTime}Z`))
  const itemTemporarilySnoozed =
    item.snoozeEndTime && currentTime.isBetween(moment(`${item.snoozeStartTime}Z`), moment(`${item.snoozeEndTime}Z`))
  return itemIndefinitelySnoozed || !!itemTemporarilySnoozed
}
