import { type ForwardedRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import mergeRefs from 'react-merge-refs'
import type { OrderingMenu, OrderingMenuItem } from '@sevenrooms/core/domain'
import styled, { useTheme } from '@sevenrooms/core/ui-kit'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useMediaQuery } from '@sevenrooms/react-components/hooks/useMediaQuery'
import { checkIsItemSnoozed } from '../utils/menuItems'
import { MenuItemCard } from './MenuItemCard'

interface MenuCategoryProps {
  menuId: string
  name: string
  items: OrderingMenuItem[]
  itemsById: Record<string, OrderingMenuItem>
  cartItemsCountById: Record<string, number | undefined>
  onItemClick: (item: OrderingMenuItem, menuId: string) => void
  testId: string
  categoryId: string
  activeMenuNextAvailable: OrderingMenu
  currencyCode: string
  onCategoryVisibilityChange: (categoryId: string, inView: boolean) => void
  hideEightySixMenuItems: boolean
  categoryRef: ForwardedRef<HTMLDivElement>
}

export function MenuCategory({
  menuId,
  name,
  items,
  itemsById,
  cartItemsCountById,
  onItemClick,
  testId,
  categoryId,
  activeMenuNextAvailable,
  currencyCode,
  categoryRef,
  onCategoryVisibilityChange,
  hideEightySixMenuItems,
}: MenuCategoryProps) {
  const theme = useTheme()
  const isRegularDesktop = useMediaQuery(`(min-width:${theme.breakpoints.l}px)`)
  const menuItemCardSpacing = isRegularDesktop ? theme.spacing.l : theme.spacing.lm
  const categoryNameTopSpacing = isRegularDesktop ? 'xxl' : 'l'
  const categoryNameBottomSpacing = isRegularDesktop ? 'l' : 'lm'

  const { ref, inView } = useInView({
    threshold: 0.01,
  })

  useEffect(() => {
    onCategoryVisibilityChange(categoryId, inView)
  }, [categoryId, inView, onCategoryVisibilityChange])

  return (
    <div ref={mergeRefs([ref, categoryRef])}>
      <VStack data-test={categoryId}>
        <Box mt={categoryNameTopSpacing} mb={categoryNameBottomSpacing}>
          <Text color="primaryFont" fontWeight={600} fontSize="xl" lineHeight="l" data-test={testId}>
            {name}
          </Text>
        </Box>
        <CategoryItemContainer menuItemCardSpacing={menuItemCardSpacing}>
          {items.map((item, index) => {
            const itemData = itemsById[item.id] as OrderingMenuItem | undefined
            const isItemSnoozed = checkIsItemSnoozed(itemData)
            const displayMenuItemCard = itemData && (!hideEightySixMenuItems || !isItemSnoozed)
            return (
              displayMenuItemCard && (
                <MenuItemCardContainer key={item.id}>
                  <MenuItemCard
                    item={itemData}
                    quantity={cartItemsCountById[item.id]}
                    activeMenuNextAvailable={activeMenuNextAvailable}
                    currencyCode={currencyCode}
                    isItemSnoozed={isItemSnoozed}
                    testId={`menu-item-${item.id}-${index}`}
                    onItemClick={() => onItemClick(item, menuId)}
                  />
                  <Delimiter mt="m" />
                </MenuItemCardContainer>
              )
            )
          })}
        </CategoryItemContainer>
      </VStack>
    </div>
  )
}

const Delimiter = styled(Box)`
  border-top: 1px solid ${props => props.theme.colors.borders};
`

const MenuItemCardContainer = styled.div``

const CategoryItemContainer = styled.div<{ menuItemCardSpacing: string }>`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - ${props => parseFloat(props.menuItemCardSpacing) / 2}px));
  gap: ${props => props.menuItemCardSpacing};

  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    grid-template-columns: repeat(1, 100%);
  }
`
