// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in ORDER-819
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useOrderingBrandSettings } from '../hooks/useOrderingBrandSettings'

interface MenuItemCardCountProps {
  count: number
}

export function MenuItemCardCount({ count }: MenuItemCardCountProps) {
  const brandSettings = useOrderingBrandSettings()
  return (
    <Container width="32px" height="32px" alignItems="center" justifyContent="center" brandColor={brandSettings.colorPrimary}>
      <Text color="lightFont" fontWeight={500} textTransform="uppercase" fontSize="l" lineHeight="m">
        {count}
      </Text>
    </Container>
  )
}

const Container = styled(Flex)<{ brandColor: string }>`
  border-radius: 4px;
  background-color: ${props => props.brandColor};
`
