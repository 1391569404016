import React from 'react'
import { CardInfoLine, Link, LinkIcon } from './styles'

const VenueAddress = ({ venueAddress, venueCityState, venuePostalCode, venueGmapsLink, dataTest }) => (
  <CardInfoLine>
    <Link href={venueGmapsLink}>
      <span data-test={dataTest}>
        {venueAddress}, {venueCityState}, {venuePostalCode}
      </span>
      <LinkIcon src={`${window.PRELOADED.MEDIA_URL}images/order-email/location-mark.png`} height="24px" width="24px" />
    </Link>
  </CardInfoLine>
)

export default VenueAddress

VenueAddress.propTypes = {
  venueAddress: React.PropTypes.string.isRequired,
  venueCityState: React.PropTypes.string.isRequired,
  venuePostalCode: React.PropTypes.string.isRequired,
  venueGmapsLink: React.PropTypes.string.isRequired,
  dataTest: React.PropTypes.string,
}
