import fetch from 'isomorphic-fetch'
import { convertToFormData } from '@sevenrooms/core/api'

const CART_URL = '/api-yoa/ordering/cart'
export const fetchCart = ({ orderSiteId }) => {
  const searchParams = {
    orderSiteId,
  }
  const queryString = new URLSearchParams(searchParams).toString()
  const url = `${CART_URL}?${queryString}`
  return fetch(url)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject('Fetch error')
      }
      return response
    })
    .then(response => response.json())
}

export const updateCart = props =>
  fetch(CART_URL, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(props),
  })
    .then(response => response.json())
    .then(response => {
      if (response.status !== 200) {
        throw response
      }

      return response
    })

export const postCheckout = checkoutData =>
  fetch('/api-yoa/ordering/checkout', {
    body: JSON.stringify(checkoutData),
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => response.json())
    .then(response => {
      if (response.status !== 200) {
        throw response
      }
      return response
    })

export const updateGateway = (data, gateway) => {
  if (gateway) {
    gateway.update(data)
  }
}

export const getDeliverability = ({
  venueId,
  placeId,
  addressFormatted,
  address1,
  city,
  state,
  postalCode,
  country,
  latitude,
  longitude,
  desiredTimestamp,
  targetReadyTimestamp,
}) => {
  const searchParams = {
    venue_id: venueId,
    delivery_place_id: placeId,
    address_formatted: addressFormatted,
    address_1: address1,
    city,
    state,
    postal_code: postalCode,
    country,
    latitude,
    longitude,
    target_ready_timestamp: targetReadyTimestamp && targetReadyTimestamp.toISOString(),
  }
  if (desiredTimestamp) {
    searchParams.desired_timestamp = desiredTimestamp.toISOString()
  }
  const queryString = new URLSearchParams(searchParams).toString()
  const url = `/api-yoa/ordering/deliverability?${queryString}`

  return fetch(url)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject('Fetch error')
      }
      return response
    })
    .then(response => response.json())
}

export const verifyPromoCode = state => {
  const venueUrlKey = state.venue.urlKey
  const { promoCode } = state.ordering.cart
  const { pickupDelivery } = state.ordering
  const subtotal = state.ordering.cart.cartTotals.subtotal || 0
  const orderAheadDate = pickupDelivery.orderAheadDate ? pickupDelivery.orderAheadDate.format('YYYY-MM-DD') : ''
  const orderAheadTime = pickupDelivery.orderAheadTime ? pickupDelivery.orderAheadDate.format('HH:mm') : ''
  const platformApplyType = 'ORDERING'
  const postUrl = `/booking/widget/${venueUrlKey}/validate_promo`
  const promoData = convertToFormData({
    promo_code: promoCode,
    platform_apply_type: platformApplyType,
    order_ahead_date: orderAheadDate,
    order_ahead_time: orderAheadTime,
    subtotal,
  })

  return fetch(postUrl, {
    body: promoData,
    method: 'POST',
    credentials: 'same-origin',
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response)
      }
      return response
    })
    .then(response => response.json())
}

export const getSuccessPageData = orderId => {
  const url = `/api-yoa/ordering/success/${orderId}`

  return fetch(url)
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject('Fetch error')
      }
      return response
    })
    .then(response => response.json())
}
