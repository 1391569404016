import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { venueToday } from 'widget/universal/selectors/common/today'
import { formatTimeSlotWindow, formatOrderAheadDate } from 'widget/universal/selectors/ordering/schedules'

const ICON_LEFT_ARROW = '\\2190'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: start;
`

const Link = styled.a`
  cursor: pointer;
  color: ${props => props.theme.ordering.primary};
`

const BackLink = styled(Link)`
  &:before {
    content: '${ICON_LEFT_ARROW}';
    margin-right: ${props => props.theme.gutter.standard};
  }
  margin: ${props => props.theme.gutter.triple} 0;
  color: ${props => props.theme.ordering.fontsColorLinks};
`

const Header = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin: ${props => props.theme.gutter.standard} 0;
`

const SubHeader = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.theme.darkGrey};
  margin: ${props => props.theme.gutter.standard} 0 ${props => props.theme.gutter.medium};
`

const InputContainer = styled.div`
  display: flex;
  margin: ${props => props.theme.gutter.medium} 0;
`

const Select = styled.select`
  font-size: 14px;
  background: ${props => props.theme.background};
  padding: ${props => props.theme.gutter.medium};
  border: 1px solid ${props => props.theme.ordering.formOutline};
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 4px;
  appearance: none;
  width: 100%;
`

const ScheduleOrderAheadContent = ({
  venue,
  pickupDeliverySelection,
  saveScheduleFuture,
  cancelScheduleFuture,
  orderAheadMaxMinutes,
  orderAheadDateOptions,
  orderAheadTimeOptions,
  timeSlotWidthMinutes,
  orderAheadDate,
  orderAheadTime,
  changeOrderAheadDate,
  changeOrderAheadTime,
}) => {
  const [futureOrderAheadDate, setFutureOrderAheadDate] = useState(orderAheadDate)
  const todayMoment = venueToday(venue)
  const tomorrowMoment = todayMoment.clone().add({ days: 1 })
  const orderAheadMaxDays = _.round(orderAheadMaxMinutes / 60 / 24)

  return (
    <>
      <BackLink onClick={cancelScheduleFuture} data-test="sr-link-cancel-order-ahead">
        Back to Order Settings
      </BackLink>
      <FormContainer>
        <div>
          <Header>Schedule for your {pickupDeliverySelection.toLowerCase().replace('_', ' ')}</Header>
          <SubHeader data-test="sr-label-order-ahead-days">You can pre-order up to {orderAheadMaxDays} days</SubHeader>
        </div>
        <InputContainer>
          <Select
            onChange={el => {
              const { value } = el.target
              if (!value) {
                return
              }
              const dt = orderAheadDateOptions[parseInt(value, 10)]
              setFutureOrderAheadDate(dt)
              changeOrderAheadDate(dt)
            }}
            value={futureOrderAheadDate ? _.findIndex(orderAheadDateOptions, o => futureOrderAheadDate.isSame(o)) : -1}
            data-test="sr-select-order-ahead-date"
          >
            <option value="">Choose a date</option>
            {_.map(orderAheadDateOptions, (o, i) => (
              <option value={i} key={i} data-test={`sr-select-option-date-${o.format('L')}`}>
                {formatOrderAheadDateOption(o, todayMoment, tomorrowMoment, venue.locale)}
              </option>
            ))}
          </Select>
        </InputContainer>
        <InputContainer>
          <Select
            onChange={el => {
              const { value } = el.target
              if (!value) {
                return
              }
              changeOrderAheadTime(orderAheadTimeOptions[parseInt(value, 10)])
              saveScheduleFuture()
            }}
            value={orderAheadTime ? _.findIndex(orderAheadTimeOptions, o => orderAheadTime.isSame(o)) : -1}
            data-test="sr-select-order-ahead-time"
          >
            <option value="">Choose a time</option>
            {_.map(orderAheadTimeOptions, (o, i) => (
              <option value={i} key={i} data-test={`sr-select-option-time-${o.format('LT')}`}>
                {formatTimeSlotWindow(o, timeSlotWidthMinutes)}
              </option>
            ))}
          </Select>
        </InputContainer>
      </FormContainer>
    </>
  )
}

const formatOrderAheadDateOption = (orderAheadDate, todayMoment, tomorrowMoment, locale) => {
  let todayTomoSuffix = ''
  if (todayMoment.isSame(orderAheadDate)) {
    todayTomoSuffix = ' (Today)'
  } else if (tomorrowMoment.isSame(orderAheadDate)) {
    todayTomoSuffix = ' (Tomorrow)'
  }
  return `${formatOrderAheadDate(orderAheadDate, locale, true)}${todayTomoSuffix}`
}

export default ScheduleOrderAheadContent
