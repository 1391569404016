import React from 'react'
import { css } from 'styled-components'
import Button from 'svr/component-lib/Generic/Buttons/StandardButton'

const OrderingButton = ({ children, onClick, styled, ...rest }) => {
  const isDisabled = rest.disabled

  return (
    <Button
      customButton={css`
        background-color: ${props => (isDisabled ? props.theme.ordering.lightGrey : props.theme.ordering.primary)} !important;
        border-color: ${props => (isDisabled ? props.theme.ordering.lightGrey : props.theme.ordering.primary)} !important;
        color: ${props => (isDisabled ? props.theme.ordering.disabledText : '#fff')} !important;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 18px;
        ${styled || ''}
      `}
      {...rest}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default OrderingButton
