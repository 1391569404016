import styled from 'styled-components'
import { LocationMark } from 'widget/universal/components/ordering/successPage/map/MapLabels'
import { Skeleton } from '@sevenrooms/react-components/components/Skeleton'

export const CardInfoLine = styled.div`
  margin-bottom: 8px;
  line-height: 17px;
  font-size: 14px;
`

export const CardHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`

export const OrderHelp = styled.div`
  margin-top: 24px;
  color: rgba(0, 0, 0, 0.6);
`

export const Spinner = styled.img`
  height: 100px;
  display: block;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
`

export const BorderContainer = styled.div`
  border: solid 1px #c4c4c4;
  padding: 32px;
  @media (max-width: 300px) {
    padding: ${props => props.theme.gutter.double};
  }
`

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
`

export const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.ordering.fontsColorLinks};
  &:visited {
    color: ${props => props.theme.ordering.fontsColorLinks};
  }
`

export const LinkIcon = styled(LocationMark)`
  position: relative;
  bottom: 2px;
  margin-left: ${props => props.theme.gutter.standard};
`
