import { faMapMarkerAlt, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { PICKUP, DELIVERY, ON_PREMISE, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'

const DetailsContainer = styled.div`
  flex: 1;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
`

const OrderDetailLine = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: ${props => props.theme.gutter.double};
`

const IconTweak = styled.span`
  flex-shrink: 0;
  font-size: 13px;
  margin-right: ${props => props.theme.gutter.standard};
  width: 20px;
`

const Icon = ({ icon }) => (
  <IconTweak>
    <FontAwesomeIcon class="fa-fw" icon={icon} />
  </IconTweak>
)

const Edit = styled.span`
  color: ${props => props.theme.ordering.fontsColorLinks};
  margin-left: 5px;
`

const CheckoutFulfillmentDetails = ({
  venue,
  pickupDeliverySelection,
  deliveryAddress,
  scheduleTimeDisplay,
  orderAheadTime,
  scheduleNowRange,
  siteName,
}) => {
  let addressDisplay
  let venueDetailsDisplay
  const editDataTest = 'sr-delivery-or-pickup-details-edit-btn'
  const timeDisplay = _.isNil(orderAheadTime) && _.some(scheduleNowRange) ? `in ${scheduleNowRange} minutes` : scheduleTimeDisplay
  if (pickupDeliverySelection === PICKUP) {
    venueDetailsDisplay = (
      <OrderDetailLine>
        <Icon icon={faShoppingBag} />
        <span>
          <b>Picking up</b> from <b>{venue.name}</b> {timeDisplay} <Edit data-test={editDataTest}>Edit</Edit>
        </span>
      </OrderDetailLine>
    )
    addressDisplay = (
      <OrderDetailLine>
        <Icon icon={faMapMarkerAlt} />
        <span>
          {venue.address}, {venue.cityState}
        </span>
      </OrderDetailLine>
    )
  } else if (pickupDeliverySelection === DELIVERY) {
    venueDetailsDisplay = (
      <OrderDetailLine>
        <Icon icon={faShoppingBag} />
        <span>
          <b>Delivery</b> from <b>{venue.name}</b> {timeDisplay} <Edit data-test={editDataTest}>Edit</Edit>
        </span>
      </OrderDetailLine>
    )
    addressDisplay = (
      <OrderDetailLine>
        <Icon icon={faMapMarkerAlt} />
        <span>{deliveryAddress}</span>
      </OrderDetailLine>
    )
  } else if (pickupDeliverySelection === ROOM_SERVICE) {
    venueDetailsDisplay = (
      <OrderDetailLine>
        <Icon icon={faShoppingBag} />
        <span>
          <b>Room Service</b> from <b>{venue.name}</b> {timeDisplay} <Edit data-test={editDataTest}>Edit</Edit>
        </span>
      </OrderDetailLine>
    )
    addressDisplay = (
      <OrderDetailLine>
        <Icon icon={faMapMarkerAlt} />
        <span>
          {venue.address}, {venue.cityState}
        </span>
      </OrderDetailLine>
    )
  } else if (pickupDeliverySelection === ON_PREMISE) {
    venueDetailsDisplay = (
      <OrderDetailLine>
        <Icon icon={faShoppingBag} />
        <span>
          <b>Ordering</b> from <b>{venue.name}</b> at <b>{siteName}</b>
        </span>
      </OrderDetailLine>
    )
    addressDisplay = (
      <OrderDetailLine>
        <Icon icon={faMapMarkerAlt} />
        <span>
          {venue.address}, {venue.cityState}
        </span>
      </OrderDetailLine>
    )
  } else {
    throw new Error(`Unsupported delivery selection: ${pickupDeliverySelection}`)
  }
  return (
    <DetailsContainer disabled data-test="sr-delivery-or-pickup-details">
      {venueDetailsDisplay}
      {addressDisplay}
    </DetailsContainer>
  )
}

export default CheckoutFulfillmentDetails
