import React from 'react'
import styled from 'styled-components'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import { InputLabel } from 'svr/component-lib/Generic/TextInputs/TextInput'
import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'

const CartInputContainer = styled.div`
  padding-bottom: ${props => props.theme.gutter.double};
`

const ImportedInputLabel = styled(InputLabel)`
  color: ${props => props.theme.ordering.black};
  font-family: 'Inter', sans-serif;
  margin-bottom: ${props => `calc(${props.theme.gutter.standard} / 2)`};
`

const PaymentOptions = ({ channelSelector, payButtonOptions, checkoutDetails }) => (
  <CartInputContainer style={!checkoutDetails.paymentsLoaded ? { display: 'none' } : {}}>
    {payButtonOptions.length ? (
      <RadioList
        field="paymentChannel"
        selectedValue={checkoutDetails.paymentChannel}
        options={payButtonOptions}
        onClick={v => channelSelector('paymentChannel', v)}
      />
    ) : null}
    <div style={checkoutDetails.paymentChannel !== PAYMENT_CHANNELS.NEW_CREDIT_CARD ? { display: 'none' } : {}}>
      {payButtonOptions.length ? null : <ImportedInputLabel isValid>Pay with credit card</ImportedInputLabel>}
      <div id="card-input-mount" />
    </div>
  </CartInputContainer>
)

export default PaymentOptions
