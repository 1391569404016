import React from 'react'
import { connect } from 'react-redux'
import * as checkoutActions from 'widget/universal/actions/ordering/checkout'
import { closeError } from 'widget/universal/actions/ordering/errormodal'
import * as orderingSiteActions from 'widget/universal/actions/ordering/orderingsite'
import * as pickupDeliveryActions from 'widget/universal/actions/ordering/pickupdelivery'
import ErrorModal from 'widget/universal/components/ordering/ErrorModal'
import PrepTimeChangedModal from 'widget/universal/components/ordering/PrepTimeChangedModal'
import SiteDetailedInfoModal from 'widget/universal/components/ordering/SiteDetailedInfoModal'
import UnavailableItemsWarning from 'widget/universal/components/ordering/UnavailableItemsWarning'
import ItemModalContainer from 'widget/universal/containers/ordering/ItemModalContainer'
import PickupDeliveryModalContainer from 'widget/universal/containers/ordering/PickupDeliveryModalContainer'
import {
  selectScheduleNowRange,
  selectScheduleTimeDisplay,
  selectMenuNameAvailableHours,
} from 'widget/universal/selectors/ordering/schedules'

const CartModals = ({
  showItemModal = false,
  showPickupDeliveryModal = false,
  scheduleNowRange = null,
  showUnavailableItemsWarning = false,
  unavailableItems = [],
  scheduleTimeDisplay = '',
  agreeUnavailableItemsWarning = () => {},
  cancelUnavailableItemsWarning = () => {},
  showPrepTimeChangedModal,
  dismissPrepTimeChangedModal,
  closeError,
  showError,
  errorMessage,
  errorHeading,
  showSiteDetailedInfoModal,
  closeDetailedSiteInfoModal,
  menusNameAvailableHours = [],
  venue,
}) => (
  <>
    {showPickupDeliveryModal && <PickupDeliveryModalContainer />}
    {showItemModal && <ItemModalContainer />}
    {showUnavailableItemsWarning && (
      <UnavailableItemsWarning
        unavailableItems={unavailableItems}
        scheduleTimeDisplay={scheduleTimeDisplay}
        agreeUnavailableItemsWarning={agreeUnavailableItemsWarning}
        cancelUnavailableItemsWarning={cancelUnavailableItemsWarning}
      />
    )}
    {showPrepTimeChangedModal && (
      <PrepTimeChangedModal
        {...{
          scheduleNowRange,
          dismissPrepTimeChangedModal,
        }}
      />
    )}
    {showError && (
      <ErrorModal okAction={closeError} heading={errorHeading}>
        <p>{errorMessage}</p>
      </ErrorModal>
    )}
    {showSiteDetailedInfoModal && (
      <SiteDetailedInfoModal venue={venue} onClose={closeDetailedSiteInfoModal} menusNameAvailableHours={menusNameAvailableHours} />
    )}
  </>
)

const mapStateToProps = state => {
  const { cart, pickupDelivery, checkout, errorModal, orderingSite } = state.ordering
  const { venue } = state

  return {
    showItemModal: cart.showItemModal,
    showPickupDeliveryModal: pickupDelivery.showModal,
    scheduleNowRange: selectScheduleNowRange(state),
    showUnavailableItemsWarning: pickupDelivery.showUnavailableItemsWarning,
    unavailableItems: pickupDelivery.unavailableItems,
    scheduleTimeDisplay: selectScheduleTimeDisplay(state),
    showPrepTimeChangedModal: checkout.showPrepTimeChangedModal,
    showError: errorModal.showError,
    errorHeading: errorModal.errorHeading,
    errorMessage: errorModal.errorMessage,
    showSiteDetailedInfoModal: orderingSite.showSiteDetailedInfoModal,
    menusNameAvailableHours: selectMenuNameAvailableHours(state),
    venue,
  }
}

const mapDispatchToProps = {
  agreeUnavailableItemsWarning: pickupDeliveryActions.agreeUnavailableItemsWarning,
  cancelUnavailableItemsWarning: pickupDeliveryActions.cancelUnavailableItemsWarning,
  dismissPrepTimeChangedModal: checkoutActions.dismissPrepTimeChangedModal,
  closeDetailedSiteInfoModal: orderingSiteActions.closeDetailedSiteInfo,
  closeError,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModals)
