// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components' // to be fixed in ORDER-819
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface MenuItemCardImageProps {
  url: string
  isDisabled: boolean
  isSnoozed: boolean
}

export function MenuItemCardImage({ url, isDisabled, isSnoozed }: MenuItemCardImageProps) {
  return (
    <ImageContainer ml="s" isDisabled={isDisabled}>
      <Image src={url} isDisabled={isDisabled} />
      {isSnoozed && (
        <SoldOutText color="lightFont" fontSize="l" lineHeight="m" fontWeight={500}>
          Sold out
        </SoldOutText>
      )}
    </ImageContainer>
  )
}

const ImageContainer = styled(Box)<{ isDisabled: boolean }>`
  position: relative;
  min-width: 162px;
  width: 162px;
  height: 149px;

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
    `}
`

const Image = styled.div<{ src: string; isDisabled: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.tertiary};

  ${props =>
    props.isDisabled
      ? css`
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props.src});
        `
      : css`
          background-image: url(${props.src});
        `}

  background-size: cover;
  background-position: center;
`

const SoldOutText = styled(Text)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
