import GoogleMapReact, { fitBounds } from 'google-map-react'
import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import GetDirectionControl from './GetDirectionControl'
import { VenueMarker, DeliveryMarker } from './MapLabels'
import ZoomControl from './ZoomControl'

const DEFAULT_MAP_HEIGHT = '300px'

const MapSection = styled.div`
  height: ${props => (props.mapHeight ? props.mapHeight : DEFAULT_MAP_HEIGHT)};
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    height: ${props => (props.mobileFullHeight ? '198px' : '150px')};
  }
`

const InfoTextWrapper = styled.div`
  text-align: center;
  vertical-align: middle;
  background: #213f63;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  padding: ${props => props.theme.padding.medium} ${props => props.theme.padding.xLarge};
  @media (max-width: 768px) {
    padding: ${props => props.theme.gutter.double};
  }
`

const getTrackingText = (trackingLink, venuePhoneNumber) => {
  if (trackingLink) {
    return `You can track delivery through our partners: ${trackingLink} Questions about your order? Call us at ${venuePhoneNumber}.`
  }
  return `If you have questions about the status of your order, call us at ${venuePhoneNumber}.`
}

const geoDiagonalOpposites = {
  nw: 'se',
  ne: 'sw',
  sw: 'ne',
  se: 'nw',
}

class Map extends React.PureComponent {
  getCenterZoom() {
    const { venueLat, venueLng, isDelivery, singleMarkerMapZoom } = this.props
    let settings = {
      center: {
        lat: venueLat,
        lng: venueLng,
      },
      zoom: singleMarkerMapZoom || 13,
    }
    if (isDelivery) {
      settings = fitBounds(this.calculateBoundsDict(), {
        height: 145,
        width: 600,
      })
    }
    return settings
  }

  calculateBoundsDict() {
    const { deliveryLat, deliveryLng, venueLat, venueLng } = this.props
    let deliveryBoundMarker
    if (deliveryLat < venueLat) {
      if (deliveryLng < venueLng) {
        deliveryBoundMarker = 'nw'
      } else {
        deliveryBoundMarker = 'ne'
      }
    } else if (deliveryLng < venueLng) {
      deliveryBoundMarker = 'sw'
    } else {
      deliveryBoundMarker = 'se'
    }
    const venueBoundMarker = geoDiagonalOpposites[deliveryBoundMarker]
    return {
      [deliveryBoundMarker]: {
        lat: deliveryLat,
        lng: deliveryLng,
      },
      [venueBoundMarker]: {
        lat: venueLat,
        lng: venueLng,
      },
    }
  }

  buildGetDirectionControl() {
    const { venueGmapsLink } = this.props
    return this.renderMapComponent(<GetDirectionControl venueGmapsLink={venueGmapsLink} />)
  }

  buildZoomControl(map) {
    return this.renderMapComponent(<ZoomControl map={map} />)
  }

  addCustomControls(map, maps) {
    const { isDelivery, disableGetDirections, disableZoomControls } = this.props
    if (!disableZoomControls) {
      const zoomDiv = this.buildZoomControl(map)
      map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(zoomDiv)
    }

    if (!isDelivery && !disableGetDirections) {
      const getDirectionsDiv = this.buildGetDirectionControl()
      map.controls[maps.ControlPosition.RIGHT_TOP].push(getDirectionsDiv)
    }
  }

  renderMapComponent(Component) {
    const controlDiv = document.createElement('div')
    controlDiv.index = 1
    ReactDOM.render(Component, controlDiv)
    return controlDiv
  }

  render() {
    const {
      trackingLink,
      venuePhoneNumber,
      deliveryAddressFormatted,
      venueAddressFormatted,
      isDelivery,
      venueLat,
      venueLng,
      deliveryLat,
      deliveryLng,
      mapHeight,
    } = this.props

    const { center, zoom } = this.getCenterZoom()
    return (
      <>
        {venueLat && (
          <MapSection mobileFullHeight={!isDelivery} mapHeight={mapHeight}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: window.PRELOADED.google_places_api_key }}
              defaultCenter={center}
              defaultZoom={zoom}
              options={{
                minZoom: 4,
                fullscreenControl: false,
                zoomControl: false,
              }}
              onGoogleApiLoaded={({ map, maps }) => this.addCustomControls(map, maps, isDelivery, venueAddressFormatted)}
              yesIWantToUseGoogleMapApiInternals
            >
              <VenueMarker lat={venueLat} lng={venueLng} addressFormatted={venueAddressFormatted} dataTest="map-venue-marker" />
              {deliveryLat && (
                <DeliveryMarker
                  lat={deliveryLat}
                  lng={deliveryLng}
                  addressFormatted={deliveryAddressFormatted}
                  dataTest="map-delivery-marker"
                />
              )}
            </GoogleMapReact>
          </MapSection>
        )}
        {isDelivery && <InfoTextWrapper>{getTrackingText(trackingLink, venuePhoneNumber)}</InfoTextWrapper>}
      </>
    )
  }
}

/* eslint-disable react/no-deprecated */
Map.propTypes = {
  venueGmapsLink: React.PropTypes.string,
  trackingLink: React.PropTypes.string,
  venuePhoneNumber: React.PropTypes.string,
  deliveryAddressFormatted: React.PropTypes.string,
  venueAddressFormatted: React.PropTypes.string,
  isDelivery: React.PropTypes.bool,
  venueLat: React.PropTypes.number,
  venueLng: React.PropTypes.number,
  deliveryLat: React.PropTypes.number,
  deliveryLng: React.PropTypes.number,
  disableZoomControls: React.PropTypes.bool,
  disableGetDirections: React.PropTypes.bool,
  singleMarkerMapZoom: React.PropTypes.number,
  mapHeight: React.PropTypes.string,
}
/* eslint-enable react/no-deprecated */

export default Map
