import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter, Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import { loadCart, setTrackingSlug } from 'widget/universal/actions/ordering/cart'
import CheckoutOrSuccessSkeletonPage from 'widget/universal/containers/ordering/CheckoutOrSuccessSkeletonPage'
import Menu from 'widget/universal/containers/ordering/Menu'
import SuccessPage from 'widget/universal/containers/ordering/SuccessPage'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.minDesktop}px) {
    max-height: 100vh;
  }

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    overflow-y: auto;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
`

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.triple};
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};
  height: 68px;
  min-height: 68px;

  background-color: ${props => props.backgroundColor || 'white'};

  &::after {
    content: '';
    flex: 1;
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    height: 56px;
    min-height: 56px;
  }
`

const SwitchLocationContainer = styled.div`
  flex: 1;
`

const LogoContainer = styled.div`
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

const LogoImage = styled.img`
  max-height: 48px;
  width: auto;
  height: auto;

  @media (max-width: ${BREAKPOINTS.minMobile}px) {
    max-height: 20px;
  }
`

// placeholder
const SwitchLocation = () => <SwitchLocationContainer />

const Logo = ({ baseUrl, logoUrl }) => (
  <LogoContainer>
    {logoUrl && (
      <Link to={baseUrl}>
        <LogoImage src={logoUrl} />
      </Link>
    )}
  </LogoContainer>
)

const Nav = ({ baseUrl, logoUrl, backgroundColor }) => (
  <NavContainer backgroundColor={backgroundColor}>
    <SwitchLocation />
    <Logo baseUrl={baseUrl} logoUrl={logoUrl} />
  </NavContainer>
)

const App = ({ match, loadCart, venue, location, setTrackingSlug }) => {
  const { tracking: trackingSlug } = queryString.parse(location.search)
  useEffect(() => {
    loadCart(true)
    trackingSlug && setTrackingSlug(trackingSlug)
  }, [])
  const baseUrl = match.path

  return (
    <ContentContainer>
      <Nav backgroundColor={venue.venueColor} baseUrl={match.url} logoUrl={venue.largeLogoUrl} />
      <Switch>
        <Route
          path="/:url*"
          exact
          strict
          render={props => (
            <Redirect
              to={{
                pathname: `${props.location.pathname}/`,
                search: props.location.search,
              }}
            />
          )}
        />
        <Route exact path={baseUrl} component={Menu} />
        <Route exact path={`${baseUrl}/checkout`} component={CheckoutOrSuccessSkeletonPage} />
        <Route exact path={`${baseUrl}/success/:orderId?`} component={SuccessPage} />
        <Route path="*" render={() => <div>404 route not found</div>} />
      </Switch>
    </ContentContainer>
  )
}

const mapDispatchToProps = {
  loadCart,
  setTrackingSlug,
}

export default withRouter(connect(null, mapDispatchToProps)(App))
