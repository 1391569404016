import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { Route, Switch } from 'react-router'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import history from 'mgr/pages/shared/utils/History'
import { ErrorBoundary, ErrorNotFound } from 'widget/universal/components/error'
import OrderingApp from 'widget/universal/containers/ordering'
import { getTheme } from 'widget/universal/utils/theme'
import { Router } from '@sevenrooms/core/navigation'
import { Root } from '@sevenrooms/core/ui-kit/layout'

const GlobalStyle = createGlobalStyle`
  html, body, #app {
    height: 100%;
    width: 100%;
  }

  html {
    max-height: 100vh;
    overflow: hidden;
  }

  ::-webkit-scrollbar:not(.data-scroll) {
    display: none;
  }
`

const AppContainer = styled.div`
  width: 100%;
  height: 100%;

  font-family: 'Inter', sans-serif;

  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`
library.add(fab)

const App = ({ venue }) => (
  <Router>
    <Root theme="gx">
      <ThemeProvider theme={getTheme()}>
        <AppContainer>
          <GlobalStyle />
          <ErrorBoundary>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path="/explore/:venueId/ordering/:orderingSiteId" render={() => <OrderingApp venue={venue} />} />
                <Route path="*" component={ErrorNotFound} />
              </Switch>
            </ConnectedRouter>
          </ErrorBoundary>
        </AppContainer>
      </ThemeProvider>
    </Root>
  </Router>
)

export default App
