/* eslint-disable no-nested-ternary */
import { Skeleton } from '@sevenrooms/react-components/components/Skeleton'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { toggleCartOnlyMode } from 'widget/universal/actions/ordering/cart'
import { fetchSuccessPageData } from 'widget/universal/actions/ordering/successpage'
import ClientDetails from 'widget/universal/components/ordering/successPage/ClientDetails'
import DeliveryDetails from 'widget/universal/components/ordering/successPage/DeliveryDetails'
import Header from 'widget/universal/components/ordering/successPage/Header'
import LinkBack from 'widget/universal/components/ordering/successPage/LinkBack'
import Map from 'widget/universal/components/ordering/successPage/map/Map'
import OnPremiseDetails from 'widget/universal/components/ordering/successPage/OnPremiseDetails'
import OrderDetails from 'widget/universal/components/ordering/successPage/OrderDetails'
import PickupDetails from 'widget/universal/components/ordering/successPage/PickupDetails'
import RoomServiceDetails from 'widget/universal/components/ordering/successPage/RoomServiceDetails'
import VenueDetails from 'widget/universal/components/ordering/successPage/VenueDetails'
// import ProgressBar from 'widget/universal/components/ordering/successPage/ProgressBar'
import { DELIVERY, ON_PREMISE, PICKUP, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: ${props => props.contentHeight}px;
  max-width: 1114px;
  width: 100%;

  margin: 40px auto;
  font: Intel, sans-serif;
  color: ${props => props.theme.black};
  font-size: 14px;
  padding: 0 ${props => props.theme.padding.large};

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: ${props => props.mobileContentHeight}px;
  }
`
const FlexChildContainer = styled.div`
  display: ${props => (props?.notVisible ? 'none' : 'inline-block')};
  width: 36.5%;
  margin-bottom: ${props => props.marginBottom || props.theme.gutter.double};
  margin-right: ${props => props.theme.gutter.double};
  overflow-wrap: break-word;
  &:nth-of-type(4),
  &:nth-of-type(5) {
    width: 62%;

    @media only screen and (max-width: 768px) {
      margin: 0 auto ${props => props.theme.gutter.double} auto;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 0 auto ${props => props.theme.gutter.double} auto;
    width: 100%;
    order: ${props => props.mobileFlexOrder || 'inherit'};
  }
`

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
`

const Container = styled.div`
  display: ${props => (props.hideDuringCheckout ? 'none' : 'block')};
  overflow: auto;
`

/**
 *
 * HTML coding for this component is pretty weird.
 * Basically for desktop view, there must be 2 columns with different col width.
 *
 * Also Due to undetermined component content, we cant set fixed component height, so that it perfectly fits grid row
 * Margins between components must be static 8px.
 *
 * Also for mobile view we need to flatted components into 1 column in different order, for different order methods
 *
 * Flexbox is used here with a column flex-direction, and flex-wrap
 * Height of the flexbox container is precalculated to exactly fit height of 1st and 2nd columns,
 * and force creation of 2nd column, by limiting height
 *
 * Flexbox container width is also calculated due to flexbox processing bug described here:
 * https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
 * That ensures correct element placement on the page.
 *
 */
class SuccessPage extends PureComponent {
  constructor(props) {
    let orderId = null
    super(props)
    const { match, isCheckoutLoadingMode } = this.props

    if (match && match.params) {
      ;({ orderId } = match.params)
    }
    if (!isCheckoutLoadingMode && orderId) {
      this.props.fetchSuccessPageData(orderId)
    }

    this.pickupDeliveryDetailsRef = React.createRef()
    this.clientVenueDetailsRef = React.createRef()
    this.mapTrackingRef = React.createRef()
    this.orderDetailsRef = React.createRef()
    this.backToMenuLinkRef = React.createRef()
    this.state = {
      desktopContentContainerHeight: 0,
      desktopContentContainerWidth: 0,
      linkMarginBottom: 0,
    }
  }

  componentDidMount() {
    this.recalculateContentContainerDimensions()
    window.addEventListener('resize', this._resizeFlexContainer)
  }

  componentDidUpdate() {
    this.recalculateContentContainerDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeFlexContainer)
  }

  _resizeFlexContainer = () => {
    this.recalculateContentContainerDimensions()
  }

  calculateContentContainerHeightAndLinkMargin() {
    const Col1ItemsHeight =
      this.pickupDeliveryDetailsRef.current.offsetHeight +
      this.clientVenueDetailsRef.current.offsetHeight +
      this.backToMenuLinkRef.current.offsetHeight +
      16 * 3 +
      4
    const Col2ItemsHeight = this.mapTrackingRef.current.offsetHeight + this.orderDetailsRef.current.offsetHeight + 16 * 2 + 4

    const mobileContentContainerHeight = Col1ItemsHeight + Col2ItemsHeight

    return {
      height: Math.max(Col1ItemsHeight, Col2ItemsHeight),
      linkMarginBottom: Col1ItemsHeight > Col2ItemsHeight ? 0 : Col2ItemsHeight - Col1ItemsHeight + 16,
      mobileContentContainerHeight,
    }
  }

  recalculateContentContainerDimensions() {
    const contentContainerWidth = this.calculateContentContainerWidth()
    const {
      height: contentContainerHeight,
      linkMarginBottom,
      mobileContentContainerHeight,
    } = this.calculateContentContainerHeightAndLinkMargin()
    this.setState({
      desktopContentContainerHeight: contentContainerHeight,
      desktopContentContainerWidth: contentContainerWidth,
      mobileContentContainerHeight,
      linkMarginBottom,
    })
  }

  calculateContentContainerWidth() {
    return this.pickupDeliveryDetailsRef.current.offsetWidth + this.orderDetailsRef.current.offsetWidth + 64
  }

  render() {
    const {
      venue,
      order,
      orderItems,
      isCheckoutLoadingMode,
      processingCheckout,
      siteName,
      toggleCartOnlyMode,
      isCartOnlyMode,
      showNoteToRestaurant,
    } = this.props
    const { desktopContentContainerHeight, mobileContentContainerHeight, desktopContentContainerWidth, linkMarginBottom } = this.state

    const isSkeleton = isCheckoutLoadingMode || !Object.keys(order).length || !orderItems.length
    const hideDuringCheckout = isCheckoutLoadingMode && !processingCheckout
    const isDelivery = order.method === DELIVERY
    const isPickup = order.method === PICKUP
    const isOnPremise = order.method === ON_PREMISE
    const isRoomService = order.method === ROOM_SERVICE

    return (
      <Container hideDuringCheckout={hideDuringCheckout}>
        <Header
          method={order.method}
          monthDay={order.order_date_formatted}
          estimatedFrom={order.estimated_time_from}
          estimatedTo={order.estimated_time_to}
          showSkeleton={isSkeleton}
        />
        {/* {!isPickup && <ProgressBar status={orderStatus} />} */}
        <ContentContainer
          contentHeight={desktopContentContainerHeight}
          contentWidth={desktopContentContainerWidth}
          mobileContentHeight={mobileContentContainerHeight}
        >
          <FlexChildContainer ref={this.pickupDeliveryDetailsRef} mobileFlexOrder={1}>
            {isSkeleton ? (
              <StyledSkeleton variant="rectangular" height="250px" />
            ) : isPickup ? (
              <PickupDetails
                venueName={venue.name}
                venueAddress={venue.address}
                venueCityState={venue.cityState}
                venuePostalCode={venue.postalCode}
                venuePhoneNumber={venue.phoneNumber}
                venueGmapsLink={order.venue_gmaps_link}
                instructions={order.pickup_instructions}
              />
            ) : isOnPremise ? (
              <OnPremiseDetails
                venueName={venue.name}
                venueAddress={venue.address}
                venueCityState={venue.cityState}
                venuePostalCode={venue.postalCode}
                venueGmapsLink={order.venue_gmaps_link}
                instructions={order.pickup_instructions}
                onPremiseTableDisplayName={order.on_premise_table_display_name}
              />
            ) : isRoomService ? (
              <RoomServiceDetails
                orderFirstName={order.first_name}
                orderLastName={order.last_name}
                roomNumber={order.room_number}
                orderEmail={order.email}
                orderPhoneNumber={order.phone_number_formatted}
                notesForRestaurant={order.order_notes_for_restaurant}
                instructions={order.delivery_instructions}
                showNoteToRestaurant={showNoteToRestaurant}
              />
            ) : (
              <DeliveryDetails
                orderFirstName={order.first_name}
                orderLastName={order.last_name}
                orderAddressFormattedWithAddress2={order.address_formatted_with_address_2}
                orderEmail={order.email}
                orderPhoneNumber={order.phone_number_formatted}
                notesForRestaurant={order.order_notes_for_restaurant}
                instructions={order.delivery_instructions}
                showNoteToRestaurant={showNoteToRestaurant}
              />
            )}
          </FlexChildContainer>

          <FlexChildContainer ref={this.clientVenueDetailsRef} mobileFlexOrder={3}>
            {isSkeleton ? (
              <StyledSkeleton variant="rectangular" height="180px" />
            ) : isPickup || isOnPremise ? (
              <ClientDetails
                firstName={order.first_name}
                lastName={order.last_name}
                email={order.email}
                phoneNumberFormatted={order.phone_number_formatted}
                notesForRestaurant={order.order_notes_for_restaurant}
                showNoteToRestaurant={showNoteToRestaurant}
              />
            ) : (
              <VenueDetails
                venueName={venue.name}
                venueAddress={venue.address}
                venueCityState={venue.cityState}
                venuePostalCode={venue.postalCode}
                venuePhoneNumber={venue.phoneNumber}
              />
            )}
          </FlexChildContainer>

          <FlexChildContainer ref={this.backToMenuLinkRef} marginBottom={`${linkMarginBottom}px`} mobileFlexOrder={5}>
            {isSkeleton ? (
              <StyledSkeleton variant="rectangular" width="35%" height="35px" />
            ) : (
              <LinkBack to="../../" onClick={() => isCartOnlyMode && toggleCartOnlyMode()} />
            )}
          </FlexChildContainer>

          <FlexChildContainer ref={this.mapTrackingRef} mobileFlexOrder={2} notVisible={isOnPremise || isRoomService}>
            {isSkeleton ? (
              <StyledSkeleton variant="rectangular" height="300px" />
            ) : isDelivery || isPickup ? (
              <Map
                venuePhoneNumber={venue.phoneNumber}
                isDelivery={isDelivery}
                venueLat={order.venue_lat}
                venueLng={order.venue_lng}
                venueAddressFormatted={`${venue.address}, ${venue.cityState}`}
                deliveryLat={order.delivery_lat}
                deliveryLng={order.delivery_lng}
                deliveryAddressFormatted={order.address_formatted}
                venueGmapsLink={order.venue_gmaps_link}
                mapHeight="300px"
              />
            ) : (
              <div />
            )}
          </FlexChildContainer>

          <FlexChildContainer ref={this.orderDetailsRef} mobileFlexOrder={4}>
            {isSkeleton ? (
              <StyledSkeleton variant="rectangular" height="600px" />
            ) : (
              <OrderDetails
                orderNumber={order.order_number}
                orderMethod={order.method}
                orderItems={orderItems}
                subtotalFormatted={order.subtotal_formatted}
                discountFormatted={order.discount_formatted}
                promocodeTitle={order.promotions.length && order.promotions[0].name}
                taxFormatted={order.tax_formatted}
                taxIncludedFormatted={order.tax_included_formatted}
                taxLabel={order.tax_label}
                tipFormatted={order.tip_formatted}
                serviceChargesFormatted={order.service_charges_formatted}
                refundFormatted={order.refund_formatted}
                totalFormatted={order.total_formatted}
                cardLastFour={order.card_last_four}
                cardBrand={order.card_brand}
                payLater={order.pay_later}
              />
            )}
          </FlexChildContainer>
        </ContentContainer>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  venue: state.venue,
  order: state.ordering.successPage.order,
  orderItems: state.ordering.successPage.orderItems,
  processingCheckout: state.ordering.checkout.processingCheckout,
  showNoteToRestaurant: state.ordering.checkout.showNoteToRestaurant,
  siteName: state.ordering.orderingSite.orderingSite.name,
  isCartOnlyMode: state.ordering.cart.isCartOnlyMode,
})

const mapDispatchToProps = {
  fetchSuccessPageData,
  toggleCartOnlyMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage)
