import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useVisibleChildrenCount } from '@sevenrooms/core/ui-kit/hooks'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { hexToRgba } from '@sevenrooms/core/ui-kit/utils'

export const TABS_VARIATIONS = {
  default: 'default',
  open: 'open',
}

const TabsContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.triple} 0;
  ${props => props.customStyles};
`

const VisibleTabsContainer = styled.div`
  display: flex;
`

const MoreTabsContainer = styled.div``

const TabContainerBase = styled.button`
  display: flex;
  flex-direction: column;
  outline: none;
  cursor: pointer;
  background: none;
  position: relative;
`

const TabContainerDefault = styled(TabContainerBase)`
  border-radius: 4px;
  padding: ${props => props.theme.gutter.double};
  border: none;
  font-weight: 500;
  color: ${props => props.theme.ordering.black};

  &:not(:last-child) {
    margin-right: ${props => props.theme.gutter.standard};
  }

  &:hover {
    color: ${props => props.theme.ordering.primary};
  }
  ${props =>
    props.isActive &&
    css`
      background: ${hexToRgba(props.theme.ordering.primary, 0.1)};
      border: 1px solid ${props.theme.ordering.primary};
    `};
`

const TabContainerOpen = styled(TabContainerBase)`
  border: none;
  border-radius: 2px;
  font-weight: 500;
  font-size: 16px;
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.medium};
  max-width: 324px;
  overflow: hidden;
  align-items: center;
  background: transparent;
  border-bottom: 2px solid transparent;

  ${props =>
    props.isActive &&
    css`
      border-color: ${props.theme.ordering.primary};
    `};
`

const TabText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  font-weight: 400;
`

const TabHeader = styled(TabText)`
  display: block;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
`

const IconWrapper = styled.div`
  margin-left: ${props => props.theme.gutter.standard};
  svg {
    transform: scale(0.8);
  }
`

const TabSubHeader = styled(TabText)`
  font-size: 12px;
  line-height: 15px;
  margin-top: ${props => props.theme.gutter.standard};
  color: ${props => props.theme.darkGrey};
`

const Select = styled.select`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
`

const MoreContainer = styled.div`
  cursor: pointer;
`

const VariationWrapper = ({ variation, children, ...props }) =>
  variation === TABS_VARIATIONS.default ? (
    <TabContainerDefault {...props}>{children}</TabContainerDefault>
  ) : (
    <TabContainerOpen {...props}>{children}</TabContainerOpen>
  )

const SelectMoreTab = ({ items, onSelect, activeItemId, text, variation, isActive }) => (
  <VariationWrapper variation={variation} isActive={isActive} onClick={() => {}} data-test="">
    <MoreContainer>
      <HStack>
        <TabHeader>{text}</TabHeader>
        <IconWrapper>
          <FontAwesomeIcon icon={faChevronDown} />
        </IconWrapper>
      </HStack>

      <Select name="sr-select-more-items" onChange={onSelect} value={isActive ? activeItemId : 'default'} data-test="sr-select-more-items">
        <option value="default" disabled hidden />
        {items.map(item => (
          <option value={item.id} key={item.id} data-test={`sr-select-item-${item.id}`}>
            {item.name}
          </option>
        ))}
      </Select>
    </MoreContainer>
  </VariationWrapper>
)

const TabItem = ({ headerText, subHeaderText, onTabClick, isActive, testId, variation }) => (
  <VariationWrapper variation={variation} isActive={isActive} onClick={onTabClick} data-test={testId}>
    <>
      <TabHeader variation={variation}>
        <span>{headerText}</span>
      </TabHeader>
      {!_.isNil(subHeaderText) && <TabSubHeader>{subHeaderText}</TabSubHeader>}
    </>
  </VariationWrapper>
)

const MenuTabs = ({ items, onTabClick, activeItemId, identifier, customStyles, headerText, variation = TABS_VARIATIONS.default }) => {
  const [displayedCategoriesCount, categoriesContainerRef, visibleCategoriesContainerRef, moreTabsContainerRef] = useVisibleChildrenCount(
    true,
    items
  )
  const moreItems = useMemo(
    () => (items.length > displayedCategoriesCount ? items.slice(displayedCategoriesCount) : []),
    [displayedCategoriesCount, items]
  )
  const selectedMoreItem = moreItems.find(item => item.id === activeItemId)

  return (
    <TabsContainer ref={categoriesContainerRef} customStyles={customStyles}>
      <VisibleTabsContainer ref={visibleCategoriesContainerRef}>
        {items.map(item => {
          const itemId = item.id
          const key = `tab-item-${identifier}-${itemId || headerText}`
          return (
            <TabItem
              key={key}
              testId={key}
              headerText={item.name}
              subHeaderText={item.subHeaderText}
              onTabClick={() => onTabClick(itemId)}
              isActive={itemId === activeItemId}
              variation={variation}
            />
          )
        })}
      </VisibleTabsContainer>
      <MoreTabsContainer ref={moreTabsContainerRef}>
        <SelectMoreTab
          items={moreItems}
          onSelect={e => onTabClick(e.target.value)}
          activeItemId={activeItemId}
          text={selectedMoreItem ? selectedMoreItem.name : 'More'}
          variation={variation}
          isActive={!!selectedMoreItem}
        />
      </MoreTabsContainer>
    </TabsContainer>
  )
}

export default MenuTabs
