import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'
import FreedomPayGateway from './FreedomPayGateway'
import StripeGateway from './StripeGateway'

const GateMap = {
  STRIPE: StripeGateway,
  FREEDOMPAY: FreedomPayGateway,
}

// A note about error patterns: they are optimized
// on the backend to be as simple as raising a
// special GatewayError, which can ultimately be
// caught by the implementation as in
//
// gatewayInstance
//   .saveCard(whatevs)
//   .catch(error => {
//     console.log(error.msg)
//     console.log(error.code)
//   })
//
// This means that if errors occur in a specific
// providor, they can be tossed up the javascript
// chain via
//
// throw {
//   msg: 'Error recieved from providor',
//   code: 500,
// }
//
// ... or, preferably, import the GatewayError
// from Payments/Util and use:
//
// throw new GatewayError('Your message.')

class Gateway {
  // The info is a catchall for additional
  // parameters. It should be treated as an
  // cumultive additive: if one provider needs
  // it, all providers get it, and any can
  // ignore it. It is managed here and passed
  // wholesale to the gateways.
  constructor(venueInfo, isProd, product, info) {
    const Gate = GateMap[venueInfo.paymentType]
    this.venueInfo = venueInfo
    this.info = info
    this.gate = new Gate(venueInfo, isProd, product, info)
    this.setPayButtonCallback()
  }

  // Append any scripts, keys, or anything
  // else needed to initialize a payment
  // provider. The save parameter is passed
  // as sometimes the mounting is
  // different. mount is the id of the div
  // where the form is to be mounted. Pass
  // null for mount if you do not need a
  // card input form.
  attachPrerequisites(mount, errHandler, save) {
    this.gate.attachPrerequisites(mount, errHandler, save)
  }

  // Sometimes a rigid implementation
  // takes some bitter DOM warfare and
  // this is how we muck around with
  // its needs.
  componentUpdate() {
    if (this.gate.componentUpdate) {
      this.gate.componentUpdate()
    }
  }

  // See componentUpdate
  channelSelector(channel, supportInfo) {
    if (this.gate.channelSelector) {
      this.gate.channelSelector(channel, supportInfo)
    }
  }

  // Mounts the form from the processors
  // web SDK if there is one. This can be
  // called by attachPrerequisites, but
  // providing a standalone route if
  // needed. Save and mount dewcribed
  // above in attachPrerequisites.
  mountForm(mount, save) {
    this.gate.mountForm(mount, save)
  }

  // Some values set in mounting need to be
  // updated with information used internally
  // prior to the authorization step.
  update(info) {
    this.info = {
      ...this.info,
      ...info,
    }
    this.gate.update(this.info)
  }

  // Provsional pattern: the system pay buttons
  // return a kind of saved card. Revisit on
  // implmenting normal saved cards and upon
  // adding FreedomPay. Currently assumed to
  // be a callback pattern.
  setPayButtonCallback(func) {
    this.gate.payButtonCallback = func
  }

  setLoadedCallback(func) {
    this.gate.loadedCallback = func
  }

  // All idiomatic omplexities involved in
  // making a payment happen in the loaded
  // gateway. Only simple universal operations
  // happen in this file. Returns a boolean
  // success and a data object that can be
  // passed back to the server. The object
  // should remain unmolested between here
  // and its companion backend gateway.
  authorizePayment(chargeData, transactionType, paymentMethod = null, checkSubmitStillValid = null) {
    const historyUrl = `/api-yoa/payments/${this.venueInfo.urlKey}/create_billing_history`

    const authorization = this.gate.authorizePayment(chargeData, transactionType, paymentMethod, checkSubmitStillValid)

    return authorization.then(response => {
      const formData = new FormData()
      formData.append('transactionId', response.id)
      formData.append('transactionType', transactionType)
      formData.append('paymentMethod', paymentMethod)

      // transactionId is always gauranteed,
      // but some processors have limitations
      // requiring auth transaction information
      // to be retrieved during transactions.
      // Looking at you, FreeedomPay.
      const combined = { ...chargeData, ...response }
      Object.keys(combined).forEach(key => {
        formData.append(key, combined[key])
      })

      return fetch(historyUrl, {
        body: formData,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => response.json())
        .then(response => {
          if (response.status !== 200) {
            throw response
          }
          return response
        })
    })
  }

  // As in authorizePayment, this returns
  // a data object or an exception. This is
  // business agnostic: it should not go
  // beyond registering a saved card with the
  // providor and returning necessary
  // reference information.
  saveCard(cardData, transactionType) {
    const saveUrl = `/api-yoa/payments/${this.venueInfo.urlKey}/save_card`

    return this.gate.saveCard(cardData).then(response => {
      const formData = new FormData()
      formData.append('card_id', response.id)
      formData.append('transaction_type', transactionType)

      return fetch(saveUrl, {
        body: formData,
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(response => response.json())
        .then(response => {
          if (response.status !== 200) {
            throw response
          }
          return response
        })
    })
  }

  // Successful removal from our system
  // system in the functions below is
  // sufficient to return success, a
  // failure with the gateway should alert
  // developers.
  removeSavedCard() {}

  // Because our operations are different,
  // the following two functions are here
  // to at least keep them in one place,
  // hopefully to be further optimized with
  // backend support

  removeSavedCardFromReservation() {
    this.removeSavedCard()
  }

  removeSavedCardFromClient() {
    this.removeSavedCard()
  }

  // Refund a customer.
  refund({ historyId, clientId, amount, reason }) {
    return this.gate.refund({ historyId, clientId, amount, reason })
  }

  googlePayAvailable() {
    return !!window.PaymentRequest
  }

  applePayAvailable() {
    return !!window.ApplePaySession
  }

  /* eslint-disable no-param-reassign */
  scanForChannels(options) {
    options = options ?? []
    if (this.applePayAvailable()) {
      options = [...options, PAYMENT_CHANNELS.APPLE_PAY]
      this.gate.defaultChannel = PAYMENT_CHANNELS.APPLE_PAY
    }
    if (this.googlePayAvailable()) {
      options = [...options, PAYMENT_CHANNELS.GOOGLE_PAY]
      this.gate.defaultChannel = PAYMENT_CHANNELS.GOOGLE_PAY
    }
    return options
  }
  /* eslint-enable no-param-reassign */

  // eslint-disable-next-line no-warning-comments
  // TODO: theoretically all calculations
  // sorting out totals and percentages
  // will be done with a call to the backend
  // gateway to have a single, canonical
  // calculation function. This function would
  // call that.
  calculateTotal() {}
}

export default Gateway
