import React from 'react'
import { connect } from 'react-redux'
import * as pickupDeliveryActions from 'widget/universal/actions/ordering/pickupdelivery'
import PickupDeliveryModal from 'widget/universal/components/ordering/PickupDeliveryModal'
import {
  selectScheduleNowRange,
  selectScheduleNextAvailable,
  selectShowBrowseMenuOption,
  selectOrderAheadMaxMinutes,
  selectOrderAheadDateOptions,
  selectOrderAheadTimeOptions,
  selectTimeSlotWidthMinutes,
  selectIsDeliveryAddressMissingStreetNumber,
  selectRequiresPickupDeliverySelection,
} from 'widget/universal/selectors/ordering/schedules'

const PickupDeliveryModalContainer = ({
  venue,
  orderingSite,
  pickupDeliveryModalState,
  pickupDeliverySelection,
  isDeliveryAddressValid,
  isDeliveryAddressValidating,
  isDeliveryAddressMissingStreetNumber,
  deliveryAddress,
  addressLine2,
  cancelPickupDeliveryModal,
  savePickupDeliveryModal,
  changePickupDeliverySelection,
  updateDeliveryAddress,
  updateAddressLine2,
  scheduleNowRange,
  scheduleNextAvailable,
  showBrowseMenuOption,
  scheduleType,
  saveScheduleNow,
  selectScheduleFuture,
  saveScheduleFuture,
  cancelScheduleFuture,
  orderAheadDate,
  orderAheadTime,
  orderAheadMaxMinutes,
  orderAheadDateOptions,
  orderAheadTimeOptions,
  timeSlotWidthMinutes,
  changeOrderAheadDate,
  changeOrderAheadTime,
  requiresPickupDeliverySelection,
}) => (
  <PickupDeliveryModal
    {...{
      venue,
      orderingSite,
      pickupDeliveryModalState,
      pickupDeliverySelection,
      isDeliveryAddressValid,
      isDeliveryAddressValidating,
      isDeliveryAddressMissingStreetNumber,
      deliveryAddress,
      addressLine2,
      cancelPickupDeliveryModal,
      savePickupDeliveryModal,
      changePickupDeliverySelection,
      updateDeliveryAddress,
      updateAddressLine2,
      scheduleNowRange,
      scheduleNextAvailable,
      showBrowseMenuOption,
      scheduleType,
      saveScheduleNow,
      selectScheduleFuture,
      saveScheduleFuture,
      cancelScheduleFuture,
      orderAheadDate,
      orderAheadTime,
      orderAheadMaxMinutes,
      orderAheadDateOptions,
      orderAheadTimeOptions,
      timeSlotWidthMinutes,
      changeOrderAheadDate,
      changeOrderAheadTime,
      requiresPickupDeliverySelection,
    }}
  />
)

const mapStateToProps = state => {
  const { pickupDelivery, orderingSite } = state.ordering

  return {
    venue: state.venue,
    orderingSite: orderingSite.orderingSite,
    pickupDeliveryModalState: pickupDelivery.editState.modalState,
    pickupDeliverySelection: pickupDelivery.editState.selection,
    isDeliveryAddressValid: pickupDelivery.editState.isAddressValid,
    isDeliveryAddressValidating: pickupDelivery.editState.isAddressValidating,
    isDeliveryAddressMissingStreetNumber: selectIsDeliveryAddressMissingStreetNumber(state),
    deliveryAddress: pickupDelivery.editState.deliveryAddress,
    addressLine2: pickupDelivery.editState.addressLine2,
    scheduleNowRange: selectScheduleNowRange(state),
    scheduleNextAvailable: selectScheduleNextAvailable(state),
    showBrowseMenuOption: selectShowBrowseMenuOption(state),
    scheduleType: pickupDelivery.editState.scheduleType,
    orderAheadDate: pickupDelivery.editState.orderAheadDate,
    orderAheadTime: pickupDelivery.editState.orderAheadTime,
    orderAheadMaxMinutes: selectOrderAheadMaxMinutes(state),
    orderAheadDateOptions: selectOrderAheadDateOptions(state),
    orderAheadTimeOptions: selectOrderAheadTimeOptions(state),
    timeSlotWidthMinutes: selectTimeSlotWidthMinutes(state),
    requiresPickupDeliverySelection: selectRequiresPickupDeliverySelection(state),
  }
}

const mapDispatchToProps = {
  cancelPickupDeliveryModal: pickupDeliveryActions.cancelPickupDeliveryModal,
  savePickupDeliveryModal: pickupDeliveryActions.savePickupDeliveryModal,
  changePickupDeliverySelection: pickupDeliveryActions.changePickupDeliverySelection,
  updateDeliveryAddress: pickupDeliveryActions.updateDeliveryAddress,
  updateAddressLine2: pickupDeliveryActions.updateAddressLine2,
  saveScheduleNow: pickupDeliveryActions.saveScheduleNow,
  selectScheduleFuture: pickupDeliveryActions.selectScheduleFuture,
  saveScheduleFuture: pickupDeliveryActions.saveScheduleFuture,
  cancelScheduleFuture: pickupDeliveryActions.cancelScheduleFuture,
  changeOrderAheadDate: pickupDeliveryActions.changeOrderAheadDate,
  changeOrderAheadTime: pickupDeliveryActions.changeOrderAheadTime,
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupDeliveryModalContainer)
