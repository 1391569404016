import React from 'react'
import Link from 'react-router-dom/Link'
import styled from 'styled-components'
import keyboardBackspace from './keyboardBackspace.png'

const Button = styled.button`
  text-decoration-line: none;
  font-size: 14px;
  line-height: 19.6px;
  border: none;
  padding: 0;
  margin-top: 16px;
  @media screen {
    background: none;
  }
`

const LinkBack = ({ to, onClick }) => (
  <Link to={to} onClick={onClick}>
    <Button data-test="confirmation-button-back_to_menu">
      <img src={keyboardBackspace} height={24} width={24} alt="keyboard_backspace" style={{ verticalAlign: 'middle', marginRight: 8 }} />
      <span>Back to Menu</span>
    </Button>
  </Link>
)

export default LinkBack
