import React from 'react'
import styled, { css } from 'styled-components'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'
import { BREAKPOINTS, PICKUP, DELIVERY, ON_PREMISE, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'

const OverallContainer = styled.div`
  display: flex;
  width: 914px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -22%;
  padding: ${props => props.theme.gutter.triple};
  background: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    flex-direction: column;
    box-shadow: none;
    width: 100%;
    position: relative;
    top: -${props => props.theme.gutter.standard};
    padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple} 0;
  }
`

const VenueDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    align-items: center;
    padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple};
  }
`

const VenueInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    flex-direction: column;
  }
`

const VenueName = styled.div`
  font-size: 24px;
`

const VenueInfo = styled.div`
  font-size: 14px;
  margin: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.double} 0 0;
  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    margin-right: 0;
  }
`

const IconWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: ${props => props.theme.gutter.standard};
`

const VenueDetails = ({ venue, openOrClosedText, showDetailedSiteInfo }) => (
  <VenueDetailsContainer>
    <VenueName data-test="menu-venue-name">{venue.name}</VenueName>
    <VenueInfoContainer>
      <VenueInfo data-test="menu-venue-info-open-closed-method-time-text">{openOrClosedText}</VenueInfo>
      <VenueInfo data-test="menu-venue-info-full-address">
        {venue.address}, {venue.cityState}
        <IconWrapper data-test="menu-venue-info-open-detailed-modal" onClick={showDetailedSiteInfo}>
          <StyledVmsIconXS>{VmsIcons.Info}</StyledVmsIconXS>
        </IconWrapper>
      </VenueInfo>
    </VenueInfoContainer>
  </VenueDetailsContainer>
)

const DeliveryOrPickupMiniDetailsContainer = styled.button`
  padding: ${props => props.theme.gutter.medium};
  margin-top: ${props => props.theme.gutter.medium};
  font-size: 16px;
  line-height: 19px;
  border: 1px solid ${props => (props.disabled ? props.theme.ordering.black : props.theme.ordering.primary)};
  border-radius: 4px;
  background: #fff;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  flex-shrink: 0;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const DeliveryOrPickupMiniDetailsElement = styled.span`
  color: ${props => (props.disabled ? props.theme.ordering.black : props.theme.ordering.primary)};
  font-weight: bold;
`

const DeliveryOrPickupMiniDetails = ({ openPickupDeliveryModal, pickupDeliverySelection, scheduleTimeDisplay, siteName }) => {
  let orderTypeDisplay
  const disabled = pickupDeliverySelection === ON_PREMISE
  if (pickupDeliverySelection === PICKUP) {
    orderTypeDisplay = 'Pickup'
  } else if (pickupDeliverySelection === DELIVERY) {
    orderTypeDisplay = 'Delivery'
  } else if (pickupDeliverySelection === ROOM_SERVICE) {
    orderTypeDisplay = 'Room Service'
  } else if (pickupDeliverySelection === ON_PREMISE) {
    orderTypeDisplay = ''
  } else {
    throw new Error(`Unsupported delivery selection: ${pickupDeliverySelection}`)
  }
  return disabled ? (
    <DeliveryOrPickupMiniDetailsContainer disabled tabIndex={-1} data-test="sr-delivery-or-pickup-mini-details">
      <DeliveryOrPickupMiniDetailsElement disabled>{siteName}</DeliveryOrPickupMiniDetailsElement>
    </DeliveryOrPickupMiniDetailsContainer>
  ) : (
    <DeliveryOrPickupMiniDetailsContainer onClick={() => openPickupDeliveryModal()} data-test="sr-delivery-or-pickup-mini-details">
      <DeliveryOrPickupMiniDetailsElement>{orderTypeDisplay}</DeliveryOrPickupMiniDetailsElement>
      {' for '}
      <DeliveryOrPickupMiniDetailsElement>{scheduleTimeDisplay}</DeliveryOrPickupMiniDetailsElement>
    </DeliveryOrPickupMiniDetailsContainer>
  )
}

const MenuVenueDetails = ({
  venue,
  openOrClosedText,
  openPickupDeliveryModal,
  pickupDeliverySelection,
  scheduleTimeDisplay,
  siteName,
  showDetailedSiteInfo,
}) => (
  <OverallContainer>
    <VenueDetails {...{ venue, openOrClosedText, showDetailedSiteInfo }} />
    <DeliveryOrPickupMiniDetails
      {...{
        siteName,
        openPickupDeliveryModal,
        pickupDeliverySelection,
        scheduleTimeDisplay,
      }}
    />
  </OverallContainer>
)

export default MenuVenueDetails
