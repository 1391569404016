import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import history from 'mgr/pages/shared/utils/History'
import type { Venue } from '@sevenrooms/mgr-core'
import languageReducer from './language'
import ordering from './ordering'
import venueReducer from './venue'

export interface RootState {
  venue: Venue
  ordering: {
    menus: unknown
    items: unknown
    cart: unknown
    modifiers: unknown
    orderingSite: unknown
    checkout: unknown
    pickupDelivery: unknown
    successPage: unknown
    errorModal: unknown
  }
  language: {
    languageStrings: unknown
  }
}

export default combineReducers({
  router: connectRouter(history),
  venue: venueReducer,
  ordering,
  language: languageReducer,
})
