import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { VmsIcons, StyledVmsIconXS, StyledVmsIconS } from 'svr/common/VmsIcons'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import CustomLabelSelect from 'widget/universal/components/ordering/CustomLabelSelect'
import Map from 'widget/universal/components/ordering/successPage/map/Map'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'
import { FormatService } from '@sevenrooms/core/locales'
import { hexToRgba } from '@sevenrooms/core/ui-kit/utils'

const XWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gutter.medium};
  right: ${props => props.theme.gutter.medium};
  color: ${props => props.theme.ordering.black};
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    opacity: 0.7;
  }
`

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: ${props => props.theme.gutter.medium};
  width: 534px;
  min-height: min(465px, 92vh);
  max-height: min(812px, 92vh);
  overflow-y: auto;
  position: relative;
  z-index: 2;
  touch-action: manipulation;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }
`

const MapWrapper = styled.div`
  height: 195px;
  width: 100%;
`

const MapOverlay = styled(MapWrapper)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
`

const VenueInfoWrapper = styled.div`
  padding: 36px;
  font-size: 14px;
  border-radius: ${props => props.theme.gutter.medium};
  position: relative;
  z-index: 1;
  background: white;
  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    padding: ${props => props.theme.padding.large};
  }
`
const VenueNameSocials = styled.div`
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
`

const VenueName = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  max-width: 300px;
  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    max-width: 180px;
  }
  @media (max-width: ${BREAKPOINTS.smallMobile}px) {
    max-width: 140px;
  }
`

const VenueSocialBg = styled.div`
  background: ${props => hexToRgba(props.theme.ordering.primary, 0.1).cssString};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: ${props => props.theme.gutter.medium};
  text-align: center;
  padding-top: ${props => props.theme.padding.xSmall};
  display: inline-block;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.smallMobile}px) {
    margin-left: ${props => props.theme.gutter.standard};
  }
`

const IconCss = css`
  font-size: ${props => props.theme.fontSize.button};
  color: ${props => props.theme.ordering.primary};
`
const VenueSocialIcon = styled(FontAwesomeIcon)`
  ${IconCss}
`
const VenueSocialVmsIcon = styled(StyledVmsIconXS)`
  ${IconCss}
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 17px;
`

const VenueContactInfo = styled.div`
  margin-top: ${props => props.theme.gutter.double};
  display: flex;
`
const VenueContactInfoIcon = styled(StyledVmsIconS)`
  color: ${props => props.theme.ordering.primary};
  margin-right: ${props => props.theme.gutter.double};
  vertical-align: middle;
`
const DirectionsLink = styled.a`
  color: ${props => props.theme.ordering.primary};
  cursor: pointer;
  text-decoration: none;
  margin-left: ${props => props.theme.gutter.medium};
  white-space: nowrap;
`

const PhoneNumberLink = styled.a`
  color: ${props => props.theme.ordering.black};
  text-decoration: none;
`

const OrderingMenuHoursContainer = styled.div`
  padding-top: 28px;
  margin-top: ${props => props.theme.gutter.triple};
  border-top: solid 1px ${props => props.theme.ordering.formOutline};
  font-size: 14px;
`

const DropdownContainer = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
`

const DayAvailabilityEntry = styled.div`
  margin-top: ${props => props.theme.gutter.medium};
  display: flex;
  justify-content: space-between;
  color: ${props => (props.highlighted ? props.theme.ordering.primary : '#8A8A8A')};
  font-weight: ${props => (props.highlighted ? 500 : 400)};
`
const DayContainer = styled.div`
  width: 150px;
`
const HoursContainer = styled.div`
  max-width: 278px;
  width: 70%;
`

const SiteDetailedInfoModal = ({ venue, menusNameAvailableHours, onClose }) => {
  const [menuIdx, setMenuIndex] = useState(0)
  const gmapsLink = venue.gmapsLink || venue.gmapsLinkByPlaceId

  const menuOptions = menusNameAvailableHours.map((item, i) => ({
    value: i,
    label: item.dropdownLabel,
  }))
  const dropdownDisplayValue = menusNameAvailableHours[menuIdx].name

  return (
    <ModalCover
      customModalWrapper={css`
        @media (max-width: ${BREAKPOINTS.minMobile}px) {
          width: 100%;
          max-height: 100%;
          height: 100%;
        }
      `}
    >
      <ModalContentContainer>
        <XWrapper data-test="item-modal-button-x" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </XWrapper>
        <MapWrapper>
          <a href={gmapsLink} target="_blank" rel="noreferrer">
            <MapOverlay data-test="item-modal-google-map" />
          </a>
          <Map
            disableZoomControls
            disableGetDirections
            venueLat={venue.latitude}
            venueLng={venue.longitude}
            singleMarkerMapZoom={15}
            mapHeight="210px"
          />
        </MapWrapper>
        <VenueInfoWrapper>
          <div>
            <VenueNameSocials>
              <VenueName data-test="item-modal-venue-name">{venue.name}</VenueName>
              <div>
                {venue.website && (
                  <a href={venue.website} target="_blank" rel="noreferrer">
                    <VenueSocialBg data-test="item-modal-website-link">
                      <VenueSocialVmsIcon>{VmsIcons.LinkMDI}</VenueSocialVmsIcon>
                    </VenueSocialBg>
                  </a>
                )}
                {venue.facebookLink && (
                  <a href={venue.facebookLink} target="_blank" rel="noreferrer">
                    <VenueSocialBg data-test="item-modal-facebook-link">
                      <VenueSocialIcon icon={['fab', 'facebook']} />
                    </VenueSocialBg>
                  </a>
                )}
                {venue.instagramLink && (
                  <a href={venue.instagramLink} target="_blank" rel="noreferrer">
                    <VenueSocialBg data-test="item-modal-instagram-link" style={{ paddingLeft: '1px', paddingTop: '3px' }}>
                      <VenueSocialIcon icon={['fab', 'instagram']} style={{ fontSize: '17px' }} />
                    </VenueSocialBg>
                  </a>
                )}
                {venue.twitterLink && (
                  <a href={venue.twitterLink} target="_blank" rel="noreferrer">
                    <VenueSocialBg data-test="item-modal-twitter-link">
                      <VenueSocialIcon icon={['fab', 'twitter']} />
                    </VenueSocialBg>
                  </a>
                )}
              </div>
            </VenueNameSocials>
            <VenueContactInfo>
              <VenueContactInfoIcon>{VmsIcons.Location}</VenueContactInfoIcon>
              <div data-test="item-modal-venue-address">
                {venue.address || ''} {venue.cityState} {venue.postalCode}
              </div>
              <DirectionsLink data-test="item-modal-get-directions-link" href={gmapsLink} target="_blank" rel="noreferrer">
                Get Directions
              </DirectionsLink>
            </VenueContactInfo>
            {venue.phoneNumber && (
              <VenueContactInfo>
                <VenueContactInfoIcon>{VmsIcons.Phone}</VenueContactInfoIcon>
                <PhoneNumberLink data-test="item-modal-phone-link" href={`tel:${venue.phoneNumber}`}>
                  {FormatService.formatPhoneNumber(venue.phoneNumber, venue.countryCode)}
                </PhoneNumberLink>
              </VenueContactInfo>
            )}
          </div>
          <OrderingMenuHoursContainer>
            <DropdownContainer>
              {menusNameAvailableHours.length === 1 ? (
                <span data-test="item-modal-selected-menu-name">{dropdownDisplayValue}</span>
              ) : (
                <CustomLabelSelect
                  data-test="item-modal-menu-availability-dropdown"
                  options={menuOptions}
                  value={menuIdx}
                  displayValue={dropdownDisplayValue}
                  onChange={value => setMenuIndex(value)}
                />
              )}
            </DropdownContainer>
            {menusNameAvailableHours[menuIdx].availableHours.map(
              (item, i) =>
                !item.hideWeekday && (
                  <DayAvailabilityEntry highlighted={item.isToday} key={i}>
                    <DayContainer data-test={`item-modal-menu-availability-day-${i}`}>
                      {`${item.day}${item.weekdayDate ? `, ${item.weekdayDate}` : ''}`}
                    </DayContainer>
                    <HoursContainer data-test={`item-modal-menu-availability-hours-${i}`}>{item.rangesFormatted.join(', ')}</HoursContainer>
                  </DayAvailabilityEntry>
                )
            )}
          </OrderingMenuHoursContainer>
        </VenueInfoWrapper>
      </ModalContentContainer>
    </ModalCover>
  )
}

export default SiteDetailedInfoModal
