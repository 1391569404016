import React from 'react'
import Checkout from 'widget/universal/containers/ordering/Checkout'
import SuccessPage from 'widget/universal/containers/ordering/SuccessPage'

const CheckoutOrSuccessSkeletonPage = () => (
  <>
    <SuccessPage isCheckoutLoadingMode />
    <Checkout />
  </>
)

export default CheckoutOrSuccessSkeletonPage
