import { arrayToObjectById } from 'widget/universal/utils/objects'

export const formatInitialItemsState = items => ({
  byId: arrayToObjectById(items),
})

// state is initialized in src/index
const itemsReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default itemsReducer
