import { faPen, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS, PICKUP, DELIVERY, ON_PREMISE, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${props => props.theme.gutter.triple} ${props => props.theme.gutter.triple} 0 ${props => props.theme.gutter.triple};
  background: white;

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    padding-top: 0;
  }
`

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  align-items: center;
  justify-content: space-between;
`

const DetailsList = styled.div``

const OrderTypeLabel = styled.h3`
  margin: 0 0 ${props => props.theme.gutter.standard} 0;
  font-weight: 500;
  font-size: ${props => props.theme.fontSize.medium};
  line-height: 22px;
  text-transform: uppercase;
`

const Details = styled.div`
  margin-bottom: ${props => props.theme.gutter.standard};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: ${props => props.theme.darkGrey};
`

const Emphasis = styled.span`
  color: #000;
`

const IconTweak = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  color: ${props => props.theme.ordering.iconGrey};
`

const Icon = ({ icon }) => (
  <IconTweak>
    <FontAwesomeIcon class="fa-fw" icon={icon} />
  </IconTweak>
)

const BackArrowContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: ${props => props.theme.gutter.standard};
  margin-left: -${props => props.theme.gutter.standard};
  width: 46px;

  &:hover {
    opacity: 0.7;
    color: ${props => props.theme.ordering.primary};
  }
`

const VenueDetailsDisplay = ({ venueName, direction, address }) => (
  <Details>
    from <Emphasis>{venueName}</Emphasis>
    {` ${direction} `}
    <Emphasis>{address}</Emphasis>
  </Details>
)

const CartFulfillmentDetails = ({
  venue,
  pickupDeliverySelection,
  deliveryAddressLine1,
  scheduleTimeDisplay,
  siteName,
  onClick = () => {},
  isCartOnlyMode,
  toggleCartOnlyMode,
}) => {
  let orderTypeDisplay
  let venueDetailsDisplay
  const isOnPremiseMethod = pickupDeliverySelection === ON_PREMISE
  let forDisplay = scheduleTimeDisplay
  if (pickupDeliverySelection === PICKUP) {
    orderTypeDisplay = 'Pickup'
    venueDetailsDisplay = <VenueDetailsDisplay venueName={venue.name} direction="at" address={venue.address} />
  } else if (pickupDeliverySelection === DELIVERY) {
    orderTypeDisplay = 'Delivery'
    venueDetailsDisplay = <VenueDetailsDisplay venueName={venue.name} direction="to" address={deliveryAddressLine1} />
  } else if (pickupDeliverySelection === ROOM_SERVICE) {
    orderTypeDisplay = 'Room Service'
    venueDetailsDisplay = <VenueDetailsDisplay venueName={venue.name} direction="" address="" />
  } else if (isOnPremiseMethod) {
    orderTypeDisplay = ''
    forDisplay = siteName
    venueDetailsDisplay = <VenueDetailsDisplay venueName={venue.name} direction="at" address={venue.address} />
  } else {
    throw new Error(`Unsupported delivery selection: ${pickupDeliverySelection}`)
  }
  return (
    <DetailsContainer>
      {isCartOnlyMode && (
        <BackArrowContainer
          onClick={e => {
            e.stopPropagation()
            toggleCartOnlyMode()
          }}
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft} size="2x" />
        </BackArrowContainer>
      )}

      <DetailsRow disabled={isOnPremiseMethod} {...(!isOnPremiseMethod && { onClick })} data-test="sr-delivery-or-pickup-details">
        <DetailsList>
          <OrderTypeLabel>YOUR {orderTypeDisplay} ORDER</OrderTypeLabel>
          {venueDetailsDisplay}
          <Details>
            for <Emphasis>{forDisplay}</Emphasis>
          </Details>
        </DetailsList>
        {!isOnPremiseMethod && <Icon icon={faPen} />}
      </DetailsRow>
    </DetailsContainer>
  )
}

export default CartFulfillmentDetails
