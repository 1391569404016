import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const CounterContainer = styled.div`
  display: flex;
  align-items: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  & > button {
    &:first-child {
      margin-right: ${props => props.theme.gutter.double};
    }
  }
`

const Count = styled.input`
  border: none;
  margin-right: 0;
  width: 32px;
  cursor: default;
`

const Button = styled.button`
  border: none;
  background: none;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.disabled ? `${props.theme.ordering.lightGrey}` : `${props.theme.ordering.primary}`)};
  transition: opacity 0.3s ease-in-out;
  padding: 0;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const Counter = ({ onDecrementClick, onIncrementClick, count, disabled, incrementTestId, decrementTestId }) => (
  <CounterContainer>
    <Button disabled={disabled || count === 1} onClick={onDecrementClick} data-test={decrementTestId}>
      <FontAwesomeIcon icon={faMinusCircle} />
    </Button>
    <Count readOnly type="number" value={count} />
    <Button disabled={disabled} onClick={onIncrementClick} data-test={incrementTestId}>
      <FontAwesomeIcon icon={faPlusCircle} />
    </Button>
  </CounterContainer>
)

export default Counter
