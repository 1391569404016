import React, { Component } from 'react'
import styled from 'styled-components'

const OptionsContainer = styled.div`
  border-radius: ${props => `${props.height / 2}px/50%`};
  overflow: hidden;
  flex-shrink: 0;
  width: ${props => props.width};
  border: 1px solid ${props => props.theme.lightGrey};
  background-color: ${props => props.theme.background};
  padding: ${props => props.theme.gutter.half};
  ::after {
    content: '';
    display: block;
    clear: both;
  }
  ${props => props.styleds};
`

const OptionCell = styled.div`
  width: ${props => props.optionWidth}%;
  height: ${props => props.height - 2 * props.theme.gutterInt.half}px;
  line-height: ${props => props.height - 2 * props.theme.gutterInt.half}px;
  border-radius: ${props => `${(props.height - 2 * props.theme.gutterInt.half) / 2}px/50%`};
  transition: background-color 100ms ease-in;
  box-sizing: border-box;
  float: left;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => (props.isSelected ? props.theme.ordering.primary : props.theme.background)};
  color: ${props => (props.isSelected ? props.theme.white : props.theme.ordering.primary)};
`

const SegmentedControl = ({ options, height, value, width, styleds, onChangeHandler }) => {
  const optionWidth = 100 / options.length
  height -= 2 // for border
  return (
    <OptionsContainer {...{ width, styleds, height }}>
      {options.map((option, idx) => {
        const isLast = idx == options.length - 1
        const isSelected = option.value == value
        return (
          <OptionCell
            data-test={option.testId}
            key={option.value}
            {...{ isLast, isSelected, optionWidth, height }}
            onClick={() => onChangeHandler(option.value)}
          >
            {option.name}
          </OptionCell>
        )
      })}
    </OptionsContainer>
  )
}

SegmentedControl.propTypes = {
  options: React.PropTypes.array.isRequired,
  width: React.PropTypes.string.isRequired,
  height: React.PropTypes.number.isRequired,
  styleds: React.PropTypes.string,
  onChangeHandler: React.PropTypes.func.isRequired,
}

SegmentedControl.defaultProps = {
  height: 26,
  onChangeHandler: () => {},
}

export default SegmentedControl
