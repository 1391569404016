import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { parseDate } from 'mgr/lib/utils/MomentUtils'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'
import { venueToday } from 'widget/universal/selectors/common/today'
import { arrayToObjectById } from 'widget/universal/utils/objects'

export const formatInitialMenuState = (menus, venue) => {
  const menusProcessed = menus.map(menu => ({
    ...menu,
    ...convertMenuDateFields(menu),
    hierarchy: {
      ...menu.hierarchy,
      categories: menu.hierarchy.categories.map(category => ({
        ...category,
        id: uuidv4(),
      })),
    },
  }))
  const today = venueToday(venue)
  const activeMenus = menusProcessed.filter(
    menu => menu.hierarchy.categories.length && (!menu.dateRangeTo || !menu.dateRangeTo.isBefore(today))
  )

  return {
    menus: activeMenus,
    byId: arrayToObjectById(activeMenus),
    activeMenuId: activeMenus.length > 0 && activeMenus[0].id,
    activeCategoryId: activeMenus.length > 0 && activeMenus[0].hierarchy.categories[0].id,
    menuHierarchyItemsById: toMenuHierarchyItemsById(activeMenus),
  }
}

const convertMenuDateFields = menu => ({
  dateRangeFrom: parseDate(menu.dateRangeFrom),
  dateRangeTo: parseDate(menu.dateRangeTo),
  excludedDateRanges: menu.excludedDateRanges.map(excludedDateRange => ({
    ...excludedDateRange,
    from: parseDate(excludedDateRange.from),
    to: parseDate(excludedDateRange.to),
  })),
})

const toMenuHierarchyItemsById = menus => {
  const menuHierarchyItemsById = {}
  for (const menu of menus) {
    menuHierarchyItemsById[menu.id] = {}
    for (const category of menu.hierarchy.categories) {
      for (const item of category.items) {
        menuHierarchyItemsById[menu.id][item.id] = item
      }
    }
  }
  return menuHierarchyItemsById
}

// state is initialized in src/index
const menusReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SELECTED_MENU: {
      const newMenuId = action.menuId

      return {
        ...state,
        activeMenuId: newMenuId,
        activeCategoryId: state.byId[newMenuId].hierarchy.categories[0].id,
      }
    }
    case ActionTypes.UPDATE_SELECTED_CATEGORY: {
      return {
        ...state,
        activeCategoryId: action.categoryId,
      }
    }
    case ActionTypes.CART_SYNC_SUCCESSFUL: {
      const { menus } = action
      return {
        ...state,
        menus: state.menus.map(menu => {
          const updatedMenu = _.find(menus, { id: menu.id })
          return {
            ...menu,
            ...updatedMenu,
            ...convertMenuDateFields(updatedMenu),
          }
        }),
      }
    }
    default:
      return state
  }
}

export default menusReducer
