import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'

const Container = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.minDesktop}px) {
    display: flex;
  }
`
export const DesktopOnly = ({ children }) => <Container>{children}</Container>
