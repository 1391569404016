import { SHOW_DETAILED_SITE_INFO, CLOSE_DETAILED_SITE_INFO } from 'widget/universal/actions/ordering/ActionTypes'

const initialState = {
  showSiteDetailedInfoModal: false,
}

const orderingSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DETAILED_SITE_INFO:
      return {
        ...state,
        showSiteDetailedInfoModal: true,
      }
    case CLOSE_DETAILED_SITE_INFO:
      return {
        ...state,
        showSiteDetailedInfoModal: false,
      }
    default:
      return state
  }
}

export default orderingSiteReducer
