import React from 'react'
import styled from 'styled-components'

const DotsContainer = styled.div`
  .spinner {
    text-align: center;
  }

  .spinner > div {
    width: ${props => props.dotSize};
    height: ${props => props.dotSize};
    background-color: ${props => props.color};

    &:not(:last-child) {
      margin-right: 4px;
    }

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`

const Dots = ({ dotSize = '14px', color = 'black' }) => (
  <DotsContainer dotSize={dotSize} color={color}>
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  </DotsContainer>
)

export default Dots
