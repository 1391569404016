export const PICKUP = 'PICKUP'
export const DELIVERY = 'DELIVERY'
export const ROOM_SERVICE = 'ROOM_SERVICE'
export const PICKUP_OR_DELIVERY = 'PICKUP_OR_DELIVERY'
export const PICKUP_OR_ROOM_SERVICE = 'PICKUP_OR_ROOM_SERVICE'
export const ON_PREMISE = 'ON_PREMISE'
export const SCHEDULE_NOW = 'SCHEDULE_NOW'
export const SCHEDULE_FUTURE = 'SCHEDULE_FUTURE'
export const MODAL_STATE_DEFAULT = 'MODAL_STATE_DEFAULT'
export const MODAL_STATE_SELECT_FUTURE_TIME = 'MODAL_STATE_SELECT_FUTURE_TIME'
export const BREAKPOINTS = {
  minDesktop: 1342,
  maxMobile: 964,
  minMobile: 450,
  smallMobile: 374,
}
