import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'

const Container = styled.div`
  display: flex;
  min-height: 30px;
  align-items: center;
  ${props => props.containerStyles};
`

const SelectedIcon = styled.div`
  display: none;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: rgba(118, 154, 242, 1);
`

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? '#999' : props.theme.navigationDark)};
`

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  :focus + div {
    border: 2px solid rgba(95, 193, 250, 1);
  }
  :checked + div > div {
    display: block;
  }
`

const Icon = styled.div`
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  left: 0;
  top: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 1);
  border-radius: 50%;
  outline: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.customRadioIcon};
`

/**
 * @typedef {{
 *   name?: string;
 *   disabled?: boolean;
 *   checked: boolean;
 *   value?: string;
 *   onChange: function;
 * }} RadioButtonProps
 *
 * @type {React.FC<RadioButtonProps>}
 */
const RadioButton = ({ name, disabled, value, checked, onChange, customRadioIcon, containerStyles, children, ...props }) => {
  const dataProps = passDataAttrs(props)
  const handleChange = useCallback(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <Label {...dataProps} disabled={disabled}>
      <Container containerStyles={containerStyles}>
        <Input name={name} type="radio" value={value} checked={checked} disabled={disabled} onChange={handleChange} />
        <Icon disabled={disabled} customRadioIcon={customRadioIcon}>
          <SelectedIcon />
        </Icon>
        <div>{children}</div>
      </Container>
    </Label>
  )
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customRadioIcon: PropTypes.array,
  containerStyles: PropTypes.array,
}

RadioButton.defaultProps = {
  disabled: false,
  checked: false,
}

export default RadioButton
