// For extracting particulars from
// address_components provided by
// the google places api

const Address = {
  STREET_NUMBER: 'street_number',
  ROUTE: 'route',
  NEIGHBORHOOD: 'neighborhood',
  LOCALITY: 'locality',
  SUBLOCALITY: 'sublocality_level_1',
  ADMINISTRATIVE_1: 'administrative_area_level_1',
  ADMINISTRATIVE_3: 'administrative_area_level_3',
  POSTAL_TOWN: 'postal_town',
  COUNTRY: 'country',
  POSTAL: 'postal_code',
}

const extractAddress = (place, type) => {
  const part = place.address_components.find(item => item.types.includes(type))
  return part ? part.short_name : ''
}

export const addressPartsFromGooglePlace = place => {
  const loc = place.geometry.location
  return {
    googlePlaceId: place.place_id,
    address1: `${extractAddress(place, Address.STREET_NUMBER)} ${extractAddress(place, Address.ROUTE)}`,
    city:
      extractAddress(place, Address.LOCALITY) ||
      extractAddress(place, Address.SUBLOCALITY) ||
      extractAddress(place, Address.POSTAL_TOWN) ||
      extractAddress(place, Address.ADMINISTRATIVE_3),

    state: extractAddress(place, Address.ADMINISTRATIVE_1),
    postalCode: extractAddress(place, Address.POSTAL),
    country: extractAddress(place, Address.COUNTRY),
    latitude: typeof loc.lat === 'function' ? loc.lat() : loc.lat,
    longitude: typeof loc.lng === 'function' ? loc.lng() : loc.lng,
    addressComponents: place.address_components,
    addressFormatted: place.formatted_address,
  }
}
