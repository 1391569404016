/* eslint-disable no-prototype-builtins */
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import CheckboxLine from 'svr/component-lib/Generic/Checkbox/CheckboxLine'
import Dots from 'svr/component-lib/Generic/Loading/Dots'
import { PhoneTextInput } from 'svr/component-lib/Generic/TextInputs/PhoneNumberInput'
import TextInput, { InputLabel } from 'svr/component-lib/Generic/TextInputs/TextInput'
import OrderingButton from 'svr/component-lib/Widget/Buttons/Ordering'
import { CheckoutFooter } from 'widget/universal/components/ordering/CheckoutFooter'
import CheckoutFulfillmentDetails from 'widget/universal/components/ordering/CheckoutFulfillmentDetails'
import inputStyle from 'widget/universal/components/ordering/checkoutInputStyle'
import CheckoutTip from 'widget/universal/components/ordering/CheckoutTip'
import CheckoutTitle from 'widget/universal/components/ordering/CheckoutTitle'
import PaymentOptions from 'widget/universal/components/ordering/PaymentOptions'
import { MaxWidthContainer } from 'widget/universal/components/ordering/Responsive'
import { DesktopOnly } from 'widget/universal/utils/DesktopOnly'
import { BREAKPOINTS, ON_PREMISE, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { Box } from '@sevenrooms/core/ui-kit/layout'

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gutter.double} 0 ${props => props.theme.gutter.triple} 0;

  a {
    text-decoration: none;
  }

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    min-height: min-content;
    padding-bottom: 0;
  }
`

const CheckoutDetailsForm = styled.div``

const CheckoutFulfillmentDetailsContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.standard} 0;
  margin-bottom: ${props => props.theme.gutter.double};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  min-height: min-content;
`

const InputContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gutter.standard};

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    flex-direction: column;
  }
`

const PhoneNumberContainer = styled.div`
  width: 100%;
  flex: 1;
  position: relative;

  & > div {
    height: auto;
  }

  label {
    padding: 0;
    margin-bottom: ${props => `calc(${props.theme.gutter.standard} / 2)`};
    color: #878787;
    ${props => !props.isValid && `color: ${props.theme.error};`}
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 400;

    & + div {
      border-width: 2px;
      border-radius: 5px;
      ${props => props.isValid && `border-color: rgba(236,236,236,1);`}
    }
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    width: 100%;
  }
`

const interCss = css`
  font-family: 'Inter', sans-serif;
`

const ErrorMessage = styled.div`
  padding: ${props => props.theme.gutter.standard} 0;
  color: ${props => props.theme.error};
  font-size: 12px;
`

const PhoneNumberError = styled(ErrorMessage)``

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const AlternatePaymentInterface = styled.div`
  position: fixed;
  top: 10vh;
  left: 50%;
  margin-left: -174px;
  border-radius: 12px;
  padding: 24px;
  background: white;
  z-index: ${props => (props.hide ? 50 : 96)};
  display: block;
  width: 350px;
  display: none;
  max-height: 80vh;
  overflow: scroll;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  color: #999;
  cursor: pointer;
  z-index: 97;
  font-size: 25px;
`

const Cover = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 95;
  display: none;
`

const OptionsWrapper = styled.div`
  padding: 0 10px;
`

const PopUpHeader = styled.div`
  padding: 25px 0;
  line-height: 1.5;
`
const PaymentsSection = styled.div`
  ${props => props.hide && `display: none;`}
`

const PosTableInputContainer = styled(InputContainer)`
  ${inputStyle}
  width: calc(50% - ${props => props.theme.gutter.double});
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    width: 100%;
  }
`

const LocalTextInput = ({ value, label, onChange, disabled, customInputWrapper, isValid, error, dataTest }) => (
  <TextInputContainer>
    <TextInput
      label={label}
      value={value}
      onChange={onChange}
      customInputWrapper={customInputWrapper}
      customInputLabel={interCss}
      customInput={interCss}
      isValid={isValid}
      dataTest={dataTest}
      disabled={disabled}
    />
    {error && <ErrorMessage data-test={`${dataTest}-error-text`}>{error}</ErrorMessage>}
  </TextInputContainer>
)

const BackToMenu = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  padding: ${props => props.theme.gutter.standard} 0;

  svg {
    margin-right: ${props => props.theme.gutter.medium};
    color: ${props => props.theme.ordering.fontsColorLinks};
  }

  div {
    text-decoration: none;
    color: ${props => props.theme.ordering.fontsColorLinks};
  }

  &:hover {
    opacity: 0.7;
  }
`

const customFooterStyles = css`
  margin-top: ${props => props.theme.gutter.double};
  border-top: 1px solid ${props => props.theme.ordering.lightGrey};
`

const CheckoutForm = ({
  venue,
  siteName,
  checkoutDetails,
  updateCheckoutDetails,
  updateCustomFieldValue,
  customFields,
  toggleMarketingOptIn,
  openPickupDeliveryModal,
  pickupDeliverySelection,
  isGuestWillPickUpRoomNumberFromList,
  orderingRoomNumbers,
  deliveryAddress,
  scheduleTimeDisplay,
  orderAheadTime,
  scheduleNowRange,
  toggleCartOnlyMode,
  payButtonOptions,
  invalidFields,
  updateTip,
  tipItems,
  shouldOfferTip,
  widgetSettings,
  channelSelector,
  checkoutWithoutPayment,
  hideAlternateInterface,
  ageToConsent,
  cartTotals,
  defaultChannel,
  isPosTableMultiselect,
  posTables,
  showNoteToRestaurant,
}) => {
  const isPhoneNumberValid = !invalidFields.hasOwnProperty('phoneNumber')
  const isOnPremiseMethod = pickupDeliverySelection === ON_PREMISE
  const isRoomService = pickupDeliverySelection === ROOM_SERVICE
  const showReadOnlyPosTableField = isOnPremiseMethod && !isPosTableMultiselect && !!posTables?.length
  const showSelectDropdownPosTableField = isOnPremiseMethod && isPosTableMultiselect && !!posTables?.length
  const showTextPosTableInputField = isOnPremiseMethod && isPosTableMultiselect && !posTables?.length

  useEffect(() => {
    if (showReadOnlyPosTableField) {
      updateCheckoutDetails('posTableId', posTables[0].id)
    }
  }, [updateCheckoutDetails, showReadOnlyPosTableField, posTables])
  const onVerifyAgeClick = useCallback(() => {
    updateCheckoutDetails('ageVerified', !checkoutDetails.ageVerified)
  }, [updateCheckoutDetails, checkoutDetails.ageVerified])
  return (
    <MaxWidthContainer checkingOut>
      <FormContainer>
        <Link to="../" onClick={toggleCartOnlyMode} data-test="checkout-button-back_to_menu">
          <BackToMenu onClick={toggleCartOnlyMode}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <div>Back to menu</div>
          </BackToMenu>
        </Link>

        <CheckoutTitle>Order Details</CheckoutTitle>

        <CheckoutFulfillmentDetailsContainer
          disabled={isOnPremiseMethod}
          {...(!isOnPremiseMethod && { onClick: () => openPickupDeliveryModal() })}
        >
          <CheckoutFulfillmentDetails
            {...{
              venue,
              siteName,
              pickupDeliverySelection,
              deliveryAddress,
              scheduleTimeDisplay,
              orderAheadTime,
              scheduleNowRange,
            }}
          />
        </CheckoutFulfillmentDetailsContainer>

        <CheckoutDetailsForm className="checkout-details-form">
          <CheckoutTitle>Your Information</CheckoutTitle>

          <InputContainer>
            <LocalTextInput
              label="First Name*"
              dataTest="checkout-input-first_name"
              value={checkoutDetails.firstName}
              onChange={v => updateCheckoutDetails('firstName', v)}
              customInputWrapper={css`
                ${inputStyle}

                margin-right: ${props => props.theme.gutter.double};
                width: calc(100% - ${props => props.theme.gutter.double});
              `}
              isValid={!invalidFields.hasOwnProperty('firstName')}
              error={_.get(invalidFields, 'firstName.error', null)}
            />
            <LocalTextInput
              label="Last Name*"
              dataTest="checkout-input-last_name"
              value={checkoutDetails.lastName}
              onChange={v => updateCheckoutDetails('lastName', v)}
              customInputWrapper={css`
                ${inputStyle}
              `}
              isValid={!invalidFields.hasOwnProperty('lastName')}
              error={_.get(invalidFields, 'lastName.error', null)}
            />
          </InputContainer>

          <InputContainer>
            <LocalTextInput
              label="Email*"
              dataTest="checkout-input-email"
              value={checkoutDetails.email}
              onChange={v => updateCheckoutDetails('email', v)}
              customInputWrapper={css`
                ${inputStyle}

                width: calc(100% - ${props => props.theme.gutter.double});
                margin-right: ${props => props.theme.gutter.double};
              `}
              isValid={!invalidFields.hasOwnProperty('email')}
              error={_.get(invalidFields, 'email.error', null)}
            />
            <PhoneNumberContainer isValid={isPhoneNumberValid}>
              <PhoneTextInput
                disabled={false}
                label="Phone number*"
                dataTest="checkout-input-phone_number"
                country={checkoutDetails.phoneNumberLocale || venue.countryCode.toUpperCase()}
                value={checkoutDetails.phoneNumber}
                displayInitialValueAsLocalNumber={venue.countryCode === checkoutDetails.phoneNumberLocale}
                onChange={v => updateCheckoutDetails('phoneNumber', v)}
                onCountryChange={v => updateCheckoutDetails('phoneNumberLocale', v)}
                displayIcon=""
                isValid={isPhoneNumberValid}
              />
              {!isPhoneNumberValid && (
                <PhoneNumberError data-test="checkout-input-phone_number-error-text">
                  {_.get(invalidFields, 'phoneNumber.error', null)}
                </PhoneNumberError>
              )}
            </PhoneNumberContainer>
          </InputContainer>

          {!!(showTextPosTableInputField || showReadOnlyPosTableField) && (
            <InputContainer>
              <LocalTextInput
                label="Table number*"
                dataTest="checkout-input-pos_table"
                value={showReadOnlyPosTableField ? posTables[0].name : checkoutDetails.posTableId}
                disabled={showReadOnlyPosTableField}
                onChange={v => updateCheckoutDetails('posTableId', v)}
                isValid={!invalidFields.hasOwnProperty('posTableId')}
                error={_.get(invalidFields, 'posTableId.error', null)}
                customInputWrapper={css`
                  @media (min-width: ${BREAKPOINTS.maxMobile}px) {
                    width: calc(50% - ${props => props.theme.gutter.double});
                  }
                  ${inputStyle}
                  background-color: rgba(255, 255, 255, 1);
                `}
              />
            </InputContainer>
          )}
          {!!showSelectDropdownPosTableField && (
            <PosTableInputContainer>
              <InputLabel isValid={!invalidFields.hasOwnProperty('posTableId')}>Table Number*</InputLabel>
              <Select
                placeholder="Choose table"
                data-test="checkout-table_number"
                value={checkoutDetails.posTableId}
                onChange={v => updateCheckoutDetails('posTableId', v)}
                options={posTables.map(({ id, name }) => ({ id, label: name }))}
                errorMessage={_.get(invalidFields, 'posTableId.error', null)}
                searchable
              />
            </PosTableInputContainer>
          )}
          {isRoomService &&
            (isGuestWillPickUpRoomNumberFromList ? (
              <Box mt="s" mb="s">
                <InputLabel isValid={!invalidFields.hasOwnProperty('roomNumber')}>Room number*</InputLabel>
                <Select
                  withEmpty
                  value={checkoutDetails.roomNumber}
                  dataTest="checkout-select-room_number"
                  onChange={v => updateCheckoutDetails('roomNumber', v)}
                  placeholder="Search for Room number"
                  options={orderingRoomNumbers
                    .map(({ number, sortOrder }, index) => ({ id: number, label: number, sortOrder: sortOrder ?? index }))
                    .sort((a, b) => a.sortOrder - b.sortOrder)}
                  invalid={invalidFields.hasOwnProperty('roomNumber')}
                  errorMessage={_.get(invalidFields, 'roomNumber.error', null)}
                />
              </Box>
            ) : (
              <InputContainer>
                <LocalTextInput
                  label="Room number*"
                  dataTest="checkout-input-room_number"
                  value={checkoutDetails.roomNumber}
                  onChange={v => updateCheckoutDetails('roomNumber', v)}
                  isValid={!invalidFields.hasOwnProperty('roomNumber')}
                  error={_.get(invalidFields, 'roomNumber.error', null)}
                  customInputWrapper={css`
                    width: auto;
                    ${inputStyle}
                  `}
                />
              </InputContainer>
            ))}

          {customFields.map(customField => (
            <InputContainer key={customField.key}>
              <LocalTextInput
                label={customField.name + (customField.required ? '*' : '')}
                dataTest="custom-field-{customField.key}"
                value={checkoutDetails.customFields[customField.key] || ''}
                onChange={v => updateCustomFieldValue(customField.key, v)}
                customInputWrapper={css`
                  width: auto;
                  ${inputStyle}
                `}
                isValid={!invalidFields.hasOwnProperty(`customField-${customField.key}`)}
                error={_.get(invalidFields, `customField-${customField.key}.error`, null)}
              />
            </InputContainer>
          ))}

          {showNoteToRestaurant && (
            <InputContainer>
              <LocalTextInput
                label="Notes for restaurant"
                dataTest="checkout-input-notes_for_restaurant"
                value={checkoutDetails.specialInstructions}
                onChange={v => updateCheckoutDetails('specialInstructions', v)}
                customInputWrapper={css`
                  width: auto;
                  margin-bottom: ${props => props.theme.gutter.double};

                  ${inputStyle}
                `}
              />
            </InputContainer>
          )}

          <InputContainer>
            <CheckboxLine
              label={`Receive news and offers from ${venue.name}`}
              field="opt_in_marketing"
              dataTest="checkout-checkbox-opt_in_marketing"
              isSelected={checkoutDetails.marketingOptIn}
              onClick={toggleMarketingOptIn}
              customCheckboxLabel={{
                fontSize: '14px',
                lineHeight: '16px',
                paddingTop: '8px',
              }}
            />
          </InputContainer>
          {checkoutDetails.verifyAge && (
            <InputContainer>
              <CheckboxLine
                label={`By checking this box, I confirm that I am ${ageToConsent}+ years old*`}
                field="verify_age"
                dataTest="checkout-verify_age"
                isValid={!invalidFields.hasOwnProperty('ageVerified')}
                isSelected={checkoutDetails.ageVerified}
                onClick={onVerifyAgeClick}
                customCheckboxLabel={{
                  fontSize: '14px',
                  lineHeight: '16px',
                  paddingTop: '8px',
                }}
              />
            </InputContainer>
          )}
        </CheckoutDetailsForm>

        <PaymentsSection data-test="payment-interface-selection" hide={cartTotals.total === 0}>
          <div id="standard-payment-interface">
            <CheckoutTitle>Payment</CheckoutTitle>
            <PaymentOptions
              {...{
                channelSelector,
                payButtonOptions,
                checkoutDetails,
              }}
            />
          </div>
          <AlternatePaymentInterface data-test="alternate-payment-interface" id="alternate-payment-interface" hide={hideAlternateInterface}>
            <CloseButton id="close-button" onClick={() => channelSelector('paymentChannel', defaultChannel, true)}>
              &times;
            </CloseButton>
            {payButtonOptions.length ? (
              <OptionsWrapper>
                <PopUpHeader>Which type of payment would you like to proceed with?</PopUpHeader>
                <PaymentOptions
                  {...{
                    channelSelector,
                    payButtonOptions,
                    checkoutDetails,
                  }}
                />
              </OptionsWrapper>
            ) : null}
            <div data-test="alternate-interface-loader" id="alternate-interface-loader">
              <Dots dotSize="12px" color="#878787" />
            </div>
            <div id="alternate-interface-mount" />
            <OrderingButton
              data-test="place-order-button"
              id="place-order-button"
              onClick={checkoutWithoutPayment}
              styled={css`
                height: 55px;
                margin: 0 10px;
                width: 280px;
                display: none;
              `}
            >
              Place Order
            </OrderingButton>
          </AlternatePaymentInterface>
          <Cover id="alternate-interface-cover" />
        </PaymentsSection>

        {shouldOfferTip && (
          <CheckoutTip
            {...{
              venue,
              checkoutDetails,
              updateTip,
              tipItems,
            }}
          />
        )}

        <DesktopOnly>
          <CheckoutFooter
            privacyPolicyUrl={widgetSettings.privacyPolicyUrl}
            gdprPolicyUrl={widgetSettings.textCustomGdprPolicyLink}
            termsOfServiceUrl={widgetSettings.termsOfServiceUrl}
            customStyles={customFooterStyles}
          />
        </DesktopOnly>

        <CheckoutTitle mobileOnly>Your Order</CheckoutTitle>
      </FormContainer>
    </MaxWidthContainer>
  )
}

export default CheckoutForm
