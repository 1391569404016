import { useCallback, useState } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { TouchScrollable } from 'react-scrolllock'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, Modal, ModalBody, ModalHeader, Window } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { useMediaQuery } from '@sevenrooms/react-components/hooks/useMediaQuery'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

interface BannerProps {
  text: string
}

/**
 * There are problems with width calculations inside ellipses plugin if we put icon on the same level with ellipses component.
 * (Under the hood it takes parent width and tries to fit text inside with all available width, but due to we have also icon we have wrong
 * calculations. That's why we are building page differently depends on isClamped param.)
 *
 * @param text - string content of banner
 */
export function Banner({ text }: BannerProps) {
  const [isClamped, seIsClamped] = useState(true)
  const isMobile = useMediaQuery(`(max-width:${BREAKPOINTS.maxMobile}px)`)
  const [isBannerModalOpen, setBannerModalOpen] = useState(false)

  const handleReflow = useCallback(({ clamped }: { clamped: boolean }) => {
    seIsClamped(clamped)
  }, [])

  return (
    <>
      <BannerContainer pl="lm" pr="lm" alignItems="center" justifyContent="center">
        <TextContainer>
          {isClamped && (
            <StickyIconContainer>
              <Icon name="GX-bullhorn" size="lg" />
            </StickyIconContainer>
          )}
          <StyledBannerText type="div" fontSize="l" lineHeight="m" fontWeight={500} isClamped={isClamped}>
            {!isClamped && (
              <Box as="span" pr="s" minWidth="29px">
                <Icon name="GX-bullhorn" size="lg" />
              </Box>
            )}
            <ResponsiveEllipsis
              text={text}
              component={isClamped ? 'div' : 'span'}
              maxLine={isMobile ? '2' : '1'}
              onReflow={handleReflow}
              ellipsis={
                <span>
                  ...
                  <MoreLabel
                    onClick={() => {
                      setBannerModalOpen(!isBannerModalOpen)
                    }}
                  >
                    &nbsp;view more
                  </MoreLabel>
                </span>
              }
              trimRight
              basedOn="letters"
            />
          </StyledBannerText>
        </TextContainer>
      </BannerContainer>
      <Window active={isBannerModalOpen}>
        <Modal ariaLabel="Modal" minWidth="232px" maxWidth="408px" width="100%">
          <ModalHeader onClose={() => setBannerModalOpen(false)}>
            <HStack justifyContent="center" pt="m">
              <Header type="h2">Message</Header>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <TouchScrollable>
              <ModalContent data-scroll>
                <Text color="secondaryFont">{text}</Text>
              </ModalContent>
            </TouchScrollable>
          </ModalBody>
          <Box pt="m" />
        </Modal>
      </Window>
    </>
  )
}

const StickyIconContainer = styled(Box)`
  position: absolute;
`

const StyledBannerText = styled(Text)<{ isClamped: boolean }>`
  ${props =>
    props.isClamped &&
    css`
      text-align: left;
      margin-left: 29px !important;
      width: calc(100% - 29px);
    `}
`

const ModalContent = styled.div`
  width: 100%;
  max-height: 255px;
  overflow-y: auto;
  text-align: center;
`

const BannerContainer = styled(HStack)`
  position: relative;
  height: 56px;
  background-color: ${props => props.theme.color.lightGrey};
`

const TextContainer = styled.div`
  width: 100%;
  max-width: 904px;
  min-width: 232px;
  text-align: center;
`

const MoreLabel = styled.span`
  width: max-content;
  color: ${props => props.theme.ordering.fontsColorLinks};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`
