import React from 'react'
import ErrorModal from 'widget/universal/components/ordering/ErrorModal'

const UnavailableItemsWarning = ({
  unavailableItems,
  scheduleTimeDisplay,
  agreeUnavailableItemsWarning,
  cancelUnavailableItemsWarning,
}) => (
  <ErrorModal
    okAction={agreeUnavailableItemsWarning}
    okText="Yes"
    cancelAction={cancelUnavailableItemsWarning}
    cancelText="Cancel"
    heading="Warning"
    textAlign="left"
  >
    <p>
      There are items in your cart that are not available at {scheduleTimeDisplay}. Are you sure you want to proceed and remove these items
      from your cart?
    </p>
    <ul>
      {unavailableItems.map(item => (
        <li key={item.id}>
          {item.qty}
          {'x '}
          {item.name}
        </li>
      ))}
    </ul>
  </ErrorModal>
)

export default UnavailableItemsWarning
