import { Skeleton } from '@sevenrooms/react-components/components/Skeleton'
import React from 'react'
import styled from 'styled-components'
import { ON_PREMISE } from 'widget/universal/utils/ordering/constants'

const Wrapper = styled.div`
  text-align: center;
  margin: 32px;
  margin-bottom: 40px;
  @media only screen and (max-width: 768px) {
    margin: 24px;
    text-align: start;
  }
`

const ThankYouHeading = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 39px;
  letter-spacing: +0.035em;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`

const ScheduledForText = styled.div`
  margin: 0 auto;
  font-size: 18px;
  line-height: 127.3%;
  letter-spacing: +0.035em;
  @media only screen and (max-width: 768px) {
    max-width: unset;
    text-align: start;
    margin: 0;
  }
`

const HeadingSkeleton = styled(Skeleton)`
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`

const Header = ({ method, monthDay, estimatedFrom, estimatedTo, showSkeleton }) => {
  const methodLower = method ? method.toLowerCase().replace('_', ' ') : ''
  const isOnPremiseMethod = method === ON_PREMISE
  return (
    <Wrapper>
      {showSkeleton ? (
        <>
          <HeadingSkeleton height="40px" width="581px" />
          <HeadingSkeleton height="30px" width="440px" />
          <HeadingSkeleton height="23px" width="250px" />
        </>
      ) : (
        <>
          <ThankYouHeading>Thank you for placing {isOnPremiseMethod ? 'an' : `a ${methodLower}`} order!</ThankYouHeading>
          {!isOnPremiseMethod && (
            <ScheduledForText>
              <p>
                This order is scheduled for {methodLower} on{' '}
                <div style={{ display: 'inline-block' }}>
                  <b data-test="confirmation-scheduled-date">{monthDay}</b>
                </div>{' '}
              </p>
              <p>
                between{' '}
                <b data-test="confirmation-scheduled-time">
                  {estimatedFrom} - {estimatedTo}
                </b>
                .
              </p>
            </ScheduledForText>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Header
