/* eslint-disable import/no-unresolved */
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { validatorFuncs } from 'mgr/lib/forms/TextInput'
import { TransactionTypes, PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'
import { loadCart, syncCart } from 'widget/universal/actions/ordering/cart'
import { showError, handleError } from 'widget/universal/actions/ordering/errormodal'
import { postCheckout } from 'widget/universal/actions/ordering/services'
import { redirectToSuccessPage } from 'widget/universal/actions/ordering/successpage'
import { selectIsSubtotalAllowed } from 'widget/universal/selectors/ordering/cart'
import { selectCustomFields } from 'widget/universal/selectors/ordering/checkout'
import {
  selectDesiredTimestamp,
  selectDesiredTimestampForCheckout,
  selectTargetReadyTimestamp,
  selectTimeSlotWidthMinutes,
  selectOrderingSite,
  selectCheckoutPrepTime,
  selectScheduleNowRange,
  ROOM_SERVICE_DRIVING_TIME_SECONDS,
} from 'widget/universal/selectors/ordering/schedules'
import { addressPartsFromGooglePlace } from 'widget/universal/utils/convert'
import { DELIVERY, ROOM_SERVICE, ON_PREMISE } from 'widget/universal/utils/ordering/constants'

export const initPayments = gateway => ({
  type: ActionTypes.INIT_PAYMENTS,
  gateway,
})

const checkIsOtherTip = tipId => tipId === 'other'

export const updateTip = (tipId, forceUpdateCart, tipInputValue) => (dispatch, getState) => {
  const state = getState()
  let tipAmount = 0
  if (checkIsOtherTip(tipId)) {
    const cartTotalsTip = state.ordering.cart.cartTotals.tip / 100
    tipAmount = tipInputValue === undefined ? cartTotalsTip : tipInputValue
  }

  dispatch({
    type: ActionTypes.UPDATE_TIP_VALUES,
    tipPercentage: tipId,
    tipAmount,
  })

  if (forceUpdateCart) {
    dispatch(syncCart())
  }
}

export const paymentsLoaded = options => ({
  type: ActionTypes.PAYMENTS_LOADED,
  options: options || [],
})

export const updateCheckoutDetails = (field, value) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_CHECKOUT_DETAILS,
    field,
    value,
  })

  if (Object.prototype.hasOwnProperty.call(FIELDS, field)) {
    dispatch(validateCheckoutForm(field))
  }
}

export const updateCustomFieldValue = (field, value) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_CUSTOM_FIELD_VALUE,
    field,
    value,
  })

  dispatch(validateCheckoutForm(field))
}

export const toggleMarketingOptIn = () => ({
  type: ActionTypes.TOGGLE_MARKETING_OPT_IN,
})

const toggleProcessing = (manual = null) => ({
  type: ActionTypes.TOGGLE_PROCESSING,
  manual,
})

const getChargeData = state => {
  const { cart } = state.ordering
  const { checkout } = state.ordering
  const isOtherTip = checkIsOtherTip(checkout.tipPercentage)
  const chargeGratuity = isOtherTip
    ? Math.round((cart.cartTotals.tip / cart.cartTotals.subtotal) * 100)
    : parseFloat(checkout.tipPercentage)

  return {
    firstName: checkout.firstName,
    lastName: checkout.lastName,
    chargeAmount: cart.cartTotals.total,
    chargeBaseAmount: cart.cartTotals.subtotal,
    chargeTax: null,
    chargeTaxAmount: cart.cartTotals.tax,
    chargeGratuity,
    chargeGratuityAmount: cart.cartTotals.tip,

    serviceChargeAmount: cart.cartTotals.serviceChargeTotal,
    discountAmount: cart.cartTotals.discount,
    promoCodeId: cart.promoCodeId || '',

    // For extra data required by
    // some payment processors
    cart,
  }
}

const REQUIRED_FIELD_TEXT = '* This is a required field.'

const FIELDS = {
  firstName: {
    validator: validatorFuncs.nameRequired,
    errorText: REQUIRED_FIELD_TEXT,
  },
  lastName: {
    validator: validatorFuncs.nameRequired,
    errorText: REQUIRED_FIELD_TEXT,
  },
  email: {
    validator: validatorFuncs.emailRequired,
    errorText: REQUIRED_FIELD_TEXT,
  },
  phoneNumber: {
    validator: validatorFuncs.phoneRequired,
    errorText: REQUIRED_FIELD_TEXT,
  },
  roomNumber: {
    validator: (roomNumber, checkoutDetails, pickupDelivery) =>
      validatorFuncs.notEmpty(roomNumber) || pickupDelivery.selection !== ROOM_SERVICE,
    errorText: REQUIRED_FIELD_TEXT,
  },
  posTableId: {
    validator: (posTableId, checkoutDetails, pickupDelivery, { orderingSite: { posTables, isPosTableMultiselect } }) =>
      pickupDelivery.selection !== ON_PREMISE ||
      validatorFuncs.notEmpty(posTableId) ||
      (!isPosTableMultiselect && (!posTables || !posTables.length)),
    errorText: REQUIRED_FIELD_TEXT,
  },
  ageVerified: {
    validator: (ageVerified, checkoutDetails) => ageVerified || !checkoutDetails.verifyAge,
  },
}

const CUSTOM_FIELD_VALIDATORS = {
  customRequiredField: {
    validator: validatorFuncs.notEmpty,
    errorText: REQUIRED_FIELD_TEXT,
  },
  customOptionalField: {
    validator: () => true,
    errorText: '',
  },
}

const validateCheckoutSubtotal = () => (dispatch, getState) => {
  const state = getState()
  const isSubtotalAllowed = selectIsSubtotalAllowed(state)
  if (isSubtotalAllowed.valid) {
    return true
  }
  dispatch(showError(isSubtotalAllowed.message))
  return false
}

const validateCheckoutForm = field => (dispatch, getState) => {
  const state = getState()
  const { checkout, pickupDelivery, orderingSite } = state.ordering
  const customFields = selectCustomFields(state)
  const requiredCustomFieldKeys = customFields.filter(cf => cf.required).map(cf => cf.key)
  let errors = { ...checkout.invalidFields }

  const fieldConfig = f =>
    FIELDS[f] ||
    (_.includes(requiredCustomFieldKeys, f) ? CUSTOM_FIELD_VALIDATORS.customRequiredField : CUSTOM_FIELD_VALIDATORS.customOptionalField)
  const fieldValue = f => (FIELDS[f] ? checkout[f] : checkout.customFields[f])
  const errorKey = f => (FIELDS[f] ? f : `customField-${f}`)

  // only validate one field
  if (field) {
    if (!fieldConfig(field).validator(fieldValue(field), checkout, pickupDelivery, orderingSite)) {
      errors[errorKey(field)] = {
        error: fieldConfig(field).errorText,
      }
    } else {
      delete errors[errorKey(field)]
    }
  } else {
    const allFields = [...requiredCustomFieldKeys, ...Object.keys(FIELDS)]
    errors = allFields.reduce((acc, field) => {
      const isValid = fieldConfig(field).validator(fieldValue(field), checkout, pickupDelivery, orderingSite)

      if (!isValid) {
        /* eslint no-param-reassign: "off" */
        acc[errorKey(field)] = {
          error: fieldConfig(field).errorText,
        }
        /* eslint no-param-reassign: "error" */
      }

      return acc
    }, {})
  }

  dispatch(updateCheckoutDetails('invalidFields', errors))

  return errors
}

const validateBeforeSubmitOrder = () => dispatch => dispatch(validateCheckoutSubtotal()) && _.isEmpty(dispatch(validateCheckoutForm()))

export const checkoutWithoutPayment = () => dispatch => {
  const isValid = dispatch(validateBeforeSubmitOrder())
  if (!isValid) {
    return Promise.resolve()
  }
  dispatch({
    type: ActionTypes.OBSCURE_INTERFACE,
    value: true,
  })
  return dispatch(checkout(null))
    .then(() => {
      dispatch({
        type: ActionTypes.OBSCURE_INTERFACE,
        value: false,
      })
    })
    .catch(error => {
      dispatch(handleError(error, loadCart))
      dispatch(toggleProcessing(false))
      dispatch({
        type: ActionTypes.OBSCURE_INTERFACE,
        value: false,
      })
    })
}

const getCheckoutData = (state, billingHistoryId) => {
  const { pickupDelivery } = state.ordering
  const { place } = pickupDelivery
  const { cart } = state.ordering
  const { checkout } = state.ordering
  const customFieldsConfig = selectCustomFields(state)
  const customFields = _.toPairs(
    _.pick(
      checkout.customFields,
      customFieldsConfig.map(cf => cf.key)
    )
  ).map(([key, value]) => ({
    key,
    value,
  }))

  const payLater =
    PAYMENT_CHANNELS.PAY_LATER === checkout.paymentChannel
      ? {
          payLater: true,
        }
      : {}

  const roomServiceDetails =
    pickupDelivery.selection === ROOM_SERVICE
      ? {
          roomNumber: checkout.roomNumber,
          drivingTimeSeconds: ROOM_SERVICE_DRIVING_TIME_SECONDS,
        }
      : {
          roomNumber: '',
        }

  const address =
    pickupDelivery.selection === DELIVERY
      ? {
          ...addressPartsFromGooglePlace(place),
          address2: pickupDelivery.addressLine2,
          drivingTimeSeconds: pickupDelivery.drivingTimeSeconds,
        }
      : {
          googlePlaceId: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
          latitude: 0.0,
          longitude: 0.0,
          addressComponents: '',
          addressFormatted: '',
          drivingTimeSeconds: 0,
        }

  return {
    venueId: state.venue.id,
    firstName: checkout.firstName,
    lastName: checkout.lastName,
    phone: checkout.phoneNumber,
    email: checkout.email,
    marketingOptIn: checkout.marketingOptIn,
    posTableId: checkout.posTableId,
    method: pickupDelivery.selection,
    cartId: cart.cartId,
    lastModifiedGuid: cart.lastModifiedGuid,
    specialInstructions: checkout.specialInstructions,
    orderReferenceId: uuidv4(),
    orderingSiteId: selectOrderingSite(state).id,
    desiredTimestamp: selectDesiredTimestampForCheckout(state),
    trackingSlug: checkout.trackingSlug,
    emailCampaignTracker: decodeURIComponent(state.router.location.query._ct ?? ''),
    desiredBufferMinutes: selectTimeSlotWidthMinutes(state),
    menuPrepTimeMinutes: selectCheckoutPrepTime(state),
    targetReadyTimestamp: selectTargetReadyTimestamp(state, new Date()),
    customFields,
    billingHistoryId,
    ...address,
    ...payLater,
    ...roomServiceDetails,
  }
}

export const submitOrder =
  (paymentMethod = null) =>
  dispatch => {
    const isValid = dispatch(validateBeforeSubmitOrder())
    if (!isValid) {
      return Promise.resolve()
    }

    return dispatch(checkDidAsapOrderTimeChange()).then(didChange => {
      if (didChange) {
        return dispatch(showPrepTimeChangedModal({ paymentMethod }))
      }
      return dispatch(authorizePaymentAndSubmit(paymentMethod))
    })
  }

const checkDidAsapOrderTimeChange = () => (dispatch, getState) => {
  const stateBefore = getState()
  return dispatch(syncCart()).then(() => {
    const stateAfter = getState()
    const desiredTimestamp = selectDesiredTimestamp(stateAfter)
    if (desiredTimestamp !== null) {
      return false // Not an ASAP order
    }
    if (selectScheduleNowRange(stateAfter) === null) {
      return true // Time no longer available
    }
    return selectCheckoutPrepTime(stateBefore) < selectCheckoutPrepTime(stateAfter)
  })
}

const authorizePaymentAndSubmit = paymentMethod => (dispatch, getState) => {
  const state = getState()
  const { gateway } = state.ordering.checkout
  const { delayProcessing } = gateway.gate
  if (!delayProcessing) {
    dispatch(toggleProcessing())
  }

  const chargeData = getChargeData(state)
  const checkSubmitStillValid = (proceedCallback, rejectCallback) =>
    dispatch(checkDidAsapOrderTimeChange()).then(didChange => {
      if (didChange) {
        dispatch(toggleProcessing(false))
        dispatch(showPrepTimeChangedModal({ proceedCallback, rejectCallback }))
      } else {
        proceedCallback()
      }
    })
  return gateway
    .authorizePayment(chargeData, TransactionTypes.WEB_ORDER_CHARGE, paymentMethod, checkSubmitStillValid)
    .then(result => {
      if (delayProcessing) {
        dispatch(toggleProcessing())
      }
      return dispatch(checkout(result.data.billing_history_id)).then(() => dispatch(toggleProcessing(false)))
    })
    .catch(error => {
      dispatch(handleError(error, loadCart))
      dispatch(toggleProcessing(false))
    })
}

export const gatewayException = () => dispatch => {
  dispatch(showError('Cannot connect to the payment provider.', true))
}

const showPrepTimeChangedModal = pendingPaymentState => ({
  type: ActionTypes.SHOW_PREP_TIME_CHANGED_MODAL,
  pendingPaymentState,
})

const hidePrepTimeChangedModal = () => ({
  type: ActionTypes.HIDE_PREP_TIME_CHANGED_MODAL,
})

const checkoutSuccess = () => ({
  type: ActionTypes.CHECKOUT_SUCCESS,
})

export const dismissPrepTimeChangedModal = doProceed => (dispatch, getState) => {
  const { pendingPaymentState } = getState().ordering.checkout
  const { paymentMethod, proceedCallback, rejectCallback } = pendingPaymentState

  dispatch(hidePrepTimeChangedModal())

  if (doProceed) {
    if (proceedCallback) {
      proceedCallback()
    } else {
      // Re-run submitOrder workflow
      return dispatch(submitOrder(paymentMethod))
    }
  } else if (rejectCallback) {
    rejectCallback()
  }
  return Promise.resolve()
}

const checkout = billingHistoryId => (dispatch, getState) => {
  const checkoutData = getCheckoutData(getState(), billingHistoryId)
  return postCheckout(checkoutData).then(response => {
    dispatch(checkoutSuccess())
    dispatch(redirectToSuccessPage(response.data.orderId))
    dispatch(loadCart())
  })
}
