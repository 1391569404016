import React from 'react'
import styled, { css } from 'styled-components'
import { CSSTransitionGroup } from 'react-transition-group'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import { BREAKPOINTS, MODAL_STATE_DEFAULT, MODAL_STATE_SELECT_FUTURE_TIME } from 'widget/universal/utils/ordering/constants'
import PickupDeliveryContent from './PickupDeliveryContent'
import ScheduleOrderAheadContent from './ScheduleOrderAheadContent'

const TRANSITION_MS = 100

const ModalContentContainer = styled.div`
  position: relative;
  width: 435px;

  @media (max-width: ${BREAKPOINTS.minMobile}px) {
    width: 100%;
  }
`

const BaseNavigationDiv = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: ${props => props.theme.gutter.triple};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  overflow-y: auto;
`

const RootNavigationDiv = styled(BaseNavigationDiv)`
  ${props =>
    props.pickupDeliveryModalState === MODAL_STATE_DEFAULT
      ? ''
      : `
    height: 400px;
  `}
`

const PushNavigationDiv = styled(BaseNavigationDiv)`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  &.push-navigation-enter {
    left: 100%;
  }

  &.push-navigation-enter.push-navigation-enter-active {
    left: 0;
    transition: left ${TRANSITION_MS}ms ease-in;
  }

  &.push-navigation-leave {
    left: 0;
  }

  &.push-navigation-leave.push-navigation-leave-active {
    left: 100%;
    transition: left ${TRANSITION_MS}ms ease-in;
  }
`

const PickupDeliveryModal = ({ pickupDeliveryModalState, ...args }) => (
  <ModalCover
    customModalWrapper={css`
      @media (max-width: ${BREAKPOINTS.minMobile}px) {
        width: calc(100% - ${props => props.theme.gutter.standard});
      }
    `}
  >
    <ModalContentContainer>
      <CSSTransitionGroup transitionName="push-navigation" transitionEnterTimeout={TRANSITION_MS} transitionLeaveTimeout={TRANSITION_MS}>
        <RootNavigationDiv key="pickupDeliveryContent" {...{ pickupDeliveryModalState }}>
          <PickupDeliveryContent {...{ ...args }} />
        </RootNavigationDiv>
        {pickupDeliveryModalState === MODAL_STATE_SELECT_FUTURE_TIME && (
          <PushNavigationDiv key="orderAheadContent">
            <ScheduleOrderAheadContent {...{ ...args }} />
          </PushNavigationDiv>
        )}
      </CSSTransitionGroup>
    </ModalContentContainer>
  </ModalCover>
)

export default PickupDeliveryModal
