import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { DELIVERY } from 'widget/universal/utils/ordering/constants'
import { BorderContainer } from './styles'
import { useStoreSelector } from '@sevenrooms/admin'

const OrderDetailsTable = styled.table`
  width: 100%;
`

const Col1 = styled.td`
  padding-right: 5px;
`

const Col2 = styled.td`
  width: 100%;
  white-space: initial;
  word-break: break-word;
  @media (max-width: 300px) {
    width: 65%;
  }
`

const Col3 = styled.td`
  padding-left: 5px;
  text-align: right;
`

const SpecialInstructionCol = styled(Col2)`
  font-style: italic;
  width: 100%;
`

const QuantityCol = styled(Col1)`
  padding-right: 12px;
  font-weight: 600;
  @media (max-width: 300px) {
    padding-right: 0px;
  }
`

const MenuItemNameCol = styled(Col2)`
  font-weight: 600;
`

const MenuItemPriceWithModsCol = styled(Col3)`
  font-size: 14px;
  text-align: right;
  line-height: 17px;
  font-weight: 400;
`

const PaidWithWrapper = styled.div`
  font-size: 12px;
`
const HeadingWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  margin-bottom: ${props => props.theme.gutter.standard};
  white-space: initial;
`
const HeadingContainer = styled.div`
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: ${props => props.theme.padding.medium};
`

const ModifiersRaw = styled.tr`
  color: ${props => props.theme.darkGrey};
  font-size: 12px;
`
const TaxIncludedCol = styled.td`
  color: ${props => props.theme.darkGrey};
  font-size: 12px;
  font-weight: normal;
  width: 100%;
`

const OrderItemsWrapper = styled.div`
  border-bottom: solid 1px #e0e0e0;
  margin: ${props => props.theme.gutter.medium} 0;
  padding-bottom: 13px;
`

const OrderItemTable = styled.table`
  margin-bottom: ${props => props.theme.gutter.double};
  line-height: 19px;
`

const BillingTable = styled.table`
  margin-bottom: 11px;
  width: 100%;
`

const BillingRows = styled.tr`
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
`

const TotalTable = styled.table`
  font-size: 18px;
  line-height: 21.6px;
  font-weight: bold;
  width: 100%;
`

const PayLater = styled.div`
  font-weight: bold;
`

const BillingCol1 = styled.td`
  white-space: break-spaces;
  word-break: break-all;
`

const BillingCol2 = styled(Col3)``

const OrderDetails = ({
  orderNumber,
  orderMethod,
  cardLastFour,
  cardBrand,
  orderItems,
  subtotalFormatted,
  taxFormatted,
  tipFormatted,
  serviceChargesFormatted,
  discountFormatted,
  promocodeTitle,
  refundFormatted,
  totalFormatted,
  payLater,
  taxIncludedFormatted,
  taxLabel,
}) => {
  const orderingSiteTipLabel = useStoreSelector(state => state.language.languageStrings.orderingSiteTipLabel)

  return (
    <BorderContainer>
      <OrderDetailsTable border="0" cellPadding="0" cellSpacing="0" align="center">
        <tbody>
          <tr>
            <Col1 align="center" valign="top">
              <div>
                <HeadingContainer>
                  <HeadingWrapper>Order Details (#{orderNumber})</HeadingWrapper>
                  {!!payLater && <PayLater>Pay {orderMethod === DELIVERY ? 'on Delivery' : 'at Pickup'}</PayLater>}
                  {!!cardLastFour && (
                    <PaidWithWrapper data-test="confirmation-paid-with">
                      Paid with {cardBrand.toUpperCase()} ending with {cardLastFour}
                    </PaidWithWrapper>
                  )}
                </HeadingContainer>

                <OrderItemsWrapper>
                  {orderItems &&
                    orderItems.map((entry, itemIndex) => {
                      const modifiers = _.reduce(
                        entry.modifiers,
                        (accum, modifier) => {
                          for (let i = 0; i < modifier.quantity; i += 1) {
                            accum.push(modifier)
                          }
                          return accum
                        },
                        []
                      )
                      const itemKey = `item-${itemIndex}`
                      return (
                        <OrderItemTable key={itemKey} data-test={`confirmation-order-item-${itemIndex}`}>
                          <tbody>
                            <tr>
                              <QuantityCol data-test={`confirmation-order-item-${itemIndex}-qty`}>{entry.quantity}x</QuantityCol>
                              <MenuItemNameCol data-test={`confirmation-order-item-${itemIndex}-name`}>{entry.name}</MenuItemNameCol>
                              <MenuItemPriceWithModsCol data-test={`confirmation-order-item-${itemIndex}-price`}>
                                {entry.subtotal_formatted}
                              </MenuItemPriceWithModsCol>
                            </tr>
                            {modifiers.map((modifier, modIndex) => {
                              const modKey = `mod-${modIndex}`
                              return (
                                <ModifiersRaw key={`${itemKey}_${modKey}`}>
                                  <Col1 />
                                  <Col2 data-test={`confirmation-order-item-mod-${modIndex}`}>
                                    {`${modifier.display_string}${
                                      modifier.sub_modifiers_display_string && `, ${modifier.sub_modifiers_display_string}`
                                    }`}
                                  </Col2>
                                  <Col3 />
                                </ModifiersRaw>
                              )
                            })}
                            {!!entry.special_instructions && (
                              <ModifiersRaw>
                                <Col1 />
                                <SpecialInstructionCol data-test={`confirmation-order-item-${itemIndex}-spec_instruction`}>
                                  {`"${entry.special_instructions}"`}
                                </SpecialInstructionCol>
                                <Col3 />
                              </ModifiersRaw>
                            )}
                          </tbody>
                        </OrderItemTable>
                      )
                    })}
                </OrderItemsWrapper>

                <BillingTable>
                  <tbody>
                    <BillingRows>
                      <BillingCol1>Item Subtotal</BillingCol1>
                      <BillingCol2 data-test="confirmation-order-subtotal">{subtotalFormatted}</BillingCol2>
                    </BillingRows>
                  </tbody>
                </BillingTable>
                {!!discountFormatted && (
                  <BillingTable>
                    <tbody>
                      <BillingRows>
                        <BillingCol1>Promo Applied{promocodeTitle && ` (${promocodeTitle})`}</BillingCol1>
                        <BillingCol2 data-test="confirmation-order-discount">({discountFormatted})</BillingCol2>
                      </BillingRows>
                    </tbody>
                  </BillingTable>
                )}
                {!!taxFormatted && (
                  <BillingTable>
                    <tbody>
                      <BillingRows>
                        <BillingCol1>Tax</BillingCol1>
                        <BillingCol2 data-test="confirmation-order-tax">{taxFormatted}</BillingCol2>
                      </BillingRows>
                    </tbody>
                  </BillingTable>
                )}

                {serviceChargesFormatted.map(
                  (chargeObj, i) =>
                    !!chargeObj.amount_formatted && (
                      <BillingTable>
                        <tbody>
                          <BillingRows>
                            <BillingCol1>{chargeObj.name}</BillingCol1>
                            <BillingCol2 data-test={`confirmation-order-service_charge-${i}`}>{chargeObj.amount_formatted}</BillingCol2>
                          </BillingRows>
                        </tbody>
                      </BillingTable>
                    )
                )}

                {!!tipFormatted && (
                  <BillingTable>
                    <tbody>
                      <BillingRows>
                        <BillingCol1>{orderingSiteTipLabel}</BillingCol1>
                        <BillingCol2 data-test="confirmation-order-tip">{tipFormatted}</BillingCol2>
                      </BillingRows>
                    </tbody>
                  </BillingTable>
                )}

                {!!refundFormatted && (
                  <BillingTable>
                    <tbody>
                      <BillingRows>
                        <BillingCol1>Refund</BillingCol1>
                        <BillingCol2 data-test="confirmation-order-refund">- {refundFormatted}</BillingCol2>
                      </BillingRows>
                    </tbody>
                  </BillingTable>
                )}

                <TotalTable>
                  <tbody>
                    <tr>
                      <BillingCol1>Total</BillingCol1>
                      <BillingCol2 data-test="confirmation-order-total">{totalFormatted}</BillingCol2>
                    </tr>
                    {!!taxIncludedFormatted && (
                      <tr data-test="total-tax-included">
                        <TaxIncludedCol>
                          Total includes {taxLabel} {taxIncludedFormatted}
                        </TaxIncludedCol>
                      </tr>
                    )}
                  </tbody>
                </TotalTable>
              </div>
            </Col1>
          </tr>
        </tbody>
      </OrderDetailsTable>
    </BorderContainer>
  )
}

export default OrderDetails
