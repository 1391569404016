import React from 'react'
import OrderInstructions from 'widget/universal/components/ordering/successPage/OrderInstructions'
import { CardInfoLine, CardHeading, BorderContainer } from './styles'

const DeliveryDetails = ({
  orderFirstName,
  orderLastName,
  orderAddressFormattedWithAddress2,
  orderEmail,
  orderPhoneNumber,
  notesForRestaurant,
  instructions,
  showNoteToRestaurant,
}) => (
  <BorderContainer>
    <CardHeading>Delivery Details</CardHeading>
    {orderFirstName && (
      <>
        <CardInfoLine data-test="confirmation-delivery-first_name-last_name">
          {orderFirstName} {orderLastName}
        </CardInfoLine>
        <CardInfoLine data-test="confirmation-delivery-address">{orderAddressFormattedWithAddress2}</CardInfoLine>
        <CardInfoLine data-test="confirmation-delivery-email">{orderEmail}</CardInfoLine>
        <CardInfoLine data-test="confirmation-delivery-phone_number">{orderPhoneNumber}</CardInfoLine>
        {showNoteToRestaurant && (
          <OrderInstructions
            instructions={notesForRestaurant}
            label="Notes for restaurant:"
            style={{ marginBottom: 0 }}
            dataTest="confirmation-delivery-notes_for_restaurant"
          />
        )}
        <OrderInstructions instructions={instructions} dataTest="confirmation-delivery-delivery_instructions" />
      </>
    )}
  </BorderContainer>
)

export default DeliveryDetails
