import history from 'mgr/pages/shared/utils/History'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'
import { getSuccessPageData } from 'widget/universal/actions/ordering/services'

export const fetchSuccessPageData = orderId => dispatch =>
  getSuccessPageData(orderId).then(
    response => {
      const { data } = response
      dispatch({
        type: ActionTypes.SUCCESS_PAGE_FETCH_SUCCESSFUL,
        order: data.order,
        orderItems: data.order_items,
      })
    },
    _error => {
      // Not found current order, so silently go back to venue's menu.
      dispatch(redirectBackToMenu())
    }
  )

export const redirectToSuccessPage = orderId => dispatch => {
  dispatch({
    type: ActionTypes.SUCCESS_PAGE_START,
  })
  history.push(`../success/${orderId}`)
}

export const redirectBackToMenu = () => _dispatch => {
  history.push('../../')
}
