import React from 'react'
import styled from 'styled-components'

export const CheckoutFooter = ({ privacyPolicyUrl, gdprPolicyUrl, termsOfServiceUrl, customStyles }) => (
  <FooterContainer customStyles={customStyles}>
    <a href={termsOfServiceUrl} target="_blank" rel="noreferrer">
      Terms of Service
    </a>
    <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
    <a href={gdprPolicyUrl} target="_blank" rel="noreferrer">
      GDPR Policy
    </a>
  </FooterContainer>
)

CheckoutFooter.propTypes = {
  privacyPolicyUrl: React.PropTypes.string.isRequired,
  gdprPolicyUrl: React.PropTypes.string.isRequired,
  termsOfServiceUrl: React.PropTypes.string.isRequired,
  customStyles: React.PropTypes.array,
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  font-size: ${props => props.theme.fontSize.small};
  line-height: ${props => props.theme.fontSize.subheader};

  a {
    color: ${props => props.theme.ordering.fontsColorLinks};
    text-decoration: none;
  }
  & > a:not(:last-child) {
    padding-right: ${props => props.theme.gutter.standard};

    &:after {
      content: '|';
      position: relative;
      right: -4px;
    }
  }
  ${props => props.customStyles};
`
