import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  cursor: pointer;
  background-color: #f7f7f7;
  height: 42px;
  width: 21px;
  margin-bottom: 32px;
  margin-right: 32px;
`
const ControlButton = styled.div`
  height: 50%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  &:nth-of-type(2) {
    background: #b7b7b7;
  }
`

const ZoomControl = ({ map }) => (
  <Container>
    <ControlButton
      onClick={() => {
        const currentZoomLevel = map.getZoom()
        const maxZoom = 21
        if (currentZoomLevel !== maxZoom) {
          map.setZoom(currentZoomLevel + 1)
        }
      }}
    >
      +
    </ControlButton>
    <ControlButton
      onClick={() => {
        const currentZoomLevel = map.getZoom()
        const minZoom = 0
        if (currentZoomLevel !== minZoom) {
          map.setZoom(currentZoomLevel - 1)
        }
      }}
    >
      -
    </ControlButton>
  </Container>
)

export default ZoomControl
