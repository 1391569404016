import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { FULFILLMENT_METHOD, ORDER_ACCOUNT_TYPES_ENABLED } from 'svr/constants'
import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'
import { FormatService } from '@sevenrooms/core/locales'

const computeTipItemsParams = state => ({
  subtotal: state.ordering.cart.cartTotals.subtotal,
  discount: state.ordering.cart.cartTotals.discount,
  currencyCode: state.venue.currency,
  tipItems: state.ordering.checkout.tipItems,
})

const computeTipItemsValues = ({ subtotal = 0, discount = 0, currencyCode = 'USD', tipItems = [] }) =>
  tipItems.map(item => ({
    ...item,
    subHeaderText:
      item.id === 'other'
        ? null
        : FormatService.formatCurrencyFromCents((subtotal - Math.abs(discount)) * (parseFloat(item.id) / 100), currencyCode),
  }))

export const selectTipItems = createShallowEqualSelector(computeTipItemsParams, computeTipItemsValues)

const computeShouldOfferTipParams = state => ({
  tipItems: selectTipItems(state),
  paymentChannel: state.ordering.checkout.paymentChannel,
})

const computeShouldOfferTipValues = ({ tipItems, paymentChannel }) => {
  const isPayLater = PAYMENT_CHANNELS.PAY_LATER === paymentChannel
  const tipItemsSansOther = _.initial(tipItems)
  return !isPayLater && _.some(tipItemsSansOther.map(ti => ti.id !== 0))
}

export const selectShouldOfferTip = createShallowEqualSelector(computeShouldOfferTipParams, computeShouldOfferTipValues)

const computeCustomFieldsParams = state => {
  const {
    customFieldsEnabled,
    customFieldsRequired,
    deliveryCustomFieldsEnabled,
    deliveryCustomFieldsRequired,
    roomServiceCustomFieldsEnabled,
    roomServiceCustomFieldsRequired,
  } = state.ordering.orderingSite.orderingSite
  return {
    customFieldsConfig: state.venue.customFieldsConfig,
    pickupDeliverySelection: state.ordering.pickupDelivery.selection,
    customFieldsEnabled,
    customFieldsRequired,
    deliveryCustomFieldsEnabled,
    deliveryCustomFieldsRequired,
    roomServiceCustomFieldsEnabled,
    roomServiceCustomFieldsRequired,
  }
}

const computeCustomFieldsValues = ({
  customFieldsConfig,
  pickupDeliverySelection,
  customFieldsEnabled,
  customFieldsRequired,
  deliveryCustomFieldsEnabled,
  deliveryCustomFieldsRequired,
  roomServiceCustomFieldsEnabled,
  roomServiceCustomFieldsRequired,
}) => {
  const enabledFields =
    pickupDeliverySelection === FULFILLMENT_METHOD.DELIVERY
      ? deliveryCustomFieldsEnabled
      : pickupDeliverySelection === FULFILLMENT_METHOD.ROOM_SERVICE
      ? roomServiceCustomFieldsEnabled
      : customFieldsEnabled
  const requiredFields =
    pickupDeliverySelection === FULFILLMENT_METHOD.DELIVERY
      ? deliveryCustomFieldsRequired
      : pickupDeliverySelection === FULFILLMENT_METHOD.ROOM_SERVICE
      ? roomServiceCustomFieldsRequired
      : customFieldsRequired
  return customFieldsConfig
    .map(customField => ({
      key: customField.dbName || customField.systemName,
      name: customField.name,
      required: _.includes(requiredFields, customField.dbName || customField.systemName),
    }))
    .filter(({ key }) => _.includes(enabledFields, key))
}

export const selectCustomFields = createShallowEqualSelector(computeCustomFieldsParams, computeCustomFieldsValues)

const selectOnlinePaymentEnabledParams = state => ({
  paymentType: state.venue.paymentType,
  allowPayLaterOnly: state.ordering.checkout.allowPayLaterOnly,
})

const selectOnlinePaymentEnabledVal = ({ paymentType, allowPayLaterOnly }) =>
  !allowPayLaterOnly && ORDER_ACCOUNT_TYPES_ENABLED.has(paymentType)
export const selectOnlinePaymentEnabled = createShallowEqualSelector(selectOnlinePaymentEnabledParams, selectOnlinePaymentEnabledVal)
