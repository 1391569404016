import React from 'react'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'

const headerHeight = 68
const headerHeightMobile = 56
export const MaxWidthContainer = styled.div`
  & > div {
    width: 928px;
    max-width: 928px;
  }

  display: flex;
  flex: 1;
  justify-content: center;
  padding: ${props => props.theme.gutter.triple};
  min-height: min-content;

  ${props =>
    props.checkingOut &&
    css`
      max-height: calc(100vh - ${headerHeight}px);
      min-height: initial;
      overflow: auto;
      padding-bottom: 0;
      padding-top: 0;
    `};

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    max-height: unset;
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    & > div {
      width: 100%;
      max-width: auto;
    }
  }
`

export const MobileCheckoutContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  width: 100%;
  padding: 0 ${props => props.theme.gutter.triple};

  button {
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    display: block;
    ${props => props.isCartOnlyMode && 'display: none;'}
  }
`
