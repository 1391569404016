import _ from 'lodash'
import React, { useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Dots from 'svr/component-lib/Generic/Loading/Dots'
import CartFulfillmentDetails from 'widget/universal/components/ordering/CartFulfillmentDetails'
import CheckoutButton from 'widget/universal/components/ordering/CheckoutButton'
import { CheckoutFooter } from 'widget/universal/components/ordering/CheckoutFooter'
import PromoCodeForm from 'widget/universal/components/ordering/PromoCodeForm'
import { ExceptDesktopOnly } from 'widget/universal/utils/ExceptDesktopOnly'
import { BREAKPOINTS, DELIVERY } from 'widget/universal/utils/ordering/constants'
import { FormatService } from '@sevenrooms/core/locales'
import { useStoreSelector } from '@sevenrooms/admin'

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-basis: 370px;
  border-left: 1px solid ${props => props.theme.ordering.lightGrey};

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    display: none;
    ${props => (props.isCartOnlyMode || props.checkingOut) && 'display: flex;'}
    ${props => props.isCartOnlyMode && 'flex-basis: 100%;'}
    ${props =>
      props.checkingOut &&
      css`
        flex-basis: auto;
        flex: 0;
        min-height: min-content;
      `}
  }
`

const headerHeight = 68
const headerHeightMobile = 56
const promocodeHeight = 119
const deliveryPickupDetailsHeight = 116
const deliveryPickupDetailsHeightMobile = 140

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.gutter.triple} ${props => props.theme.gutter.triple} ${props => props.theme.gutter.triple};
  flex: 1;
  overflow-y: auto;
  max-height: ${props =>
    props.checkingOut
      ? `calc(100vh - ${headerHeight}px - ${promocodeHeight}px - ${props.totalsContainerHeight}px
    )`
      : `calc(100vh - ${headerHeight}px - ${props.totalsContainerHeight}px - ${deliveryPickupDetailsHeight}px)`};

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    ${props => !props.checkingOut && `padding-top: ${deliveryPickupDetailsHeightMobile - headerHeightMobile}px;`}
    max-height: 100%;
    min-height: min-content;
  }
`

const CartItemsContainer = styled.div`
  flex: 1;
  margin-top: ${props => props.theme.gutter.double};

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    ${props => props.isCartOnlyMode && 'padding-bottom: 133px;'}
  }
`

const CartItemLabel = styled.div`
  color: ${props => props.theme.ordering.fontsColorLinks};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

const CartItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${props => props.theme.gutter.double};
  margin-bottom: ${props => props.theme.gutter.double};
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};

  & > div:not(:last-child) {
    padding-right: ${props => props.theme.gutter.double};
  }
`

const CartItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ModifiersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gutter.double};
`

const Modifier = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #8a8a8a;
  margin-bottom: ${props => props.theme.gutter.double};
`

const CartItemControlsContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gutter.double};

  & > button {
    &:first-child {
      margin-right: ${props => props.theme.gutter.triple};
    }
  }
`

const SpecialInstructions = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #8a8a8a;
`

const TotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: ${props => props.theme.gutter.triple};

  button {
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    ${props => props.isCartOnlyMode && 'position: fixed;'}
    bottom: 0;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    ${props => !props.isCartOnlyMode && 'position: static;'}
  }
`

const PriceLineContainer = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gutter.triple};
`

const TotalLineContainer = styled(PriceLineContainer)`
  border-top: 1px solid #cfd0d7;
  padding-top: 22px;
`

const Total = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const PromoDisplay = styled.div`
  color: ${props => props.theme.ordering.green};
`

const ControlButton = styled.button`
  font-weight: 600;
  font-size: 12px;
  border: none;
  background: transparent;
  color: ${props => props.theme.ordering.black};
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
    color: ${props => props.theme.ordering.primary};
  }
`

const customFooterStyles = css`
  margin-bottom: ${props => props.theme.gutter.triple};
`

const CartItem = ({ item, onEditItemClick, onRemoveItemClick, testId, isLoading, currencyCode }) => {
  const cartItemModifiers = _.reduce(
    item.modifiers,
    (accum, modifier) => {
      for (let i = 0; i < modifier.qty; i += 1) {
        accum.push(modifier)
      }
      return accum
    },
    []
  )
  return (
    <CartItemContainer data-test={testId}>
      <div data-test={`${testId}-qty`}>{item.qty}x</div>
      <CartItemInfoContainer>
        <CartItemLabel onClick={() => onEditItemClick(item.id)} data-test={`${testId}-name`}>
          {item.name}
        </CartItemLabel>

        {cartItemModifiers.length > 0 && (
          <ModifiersContainer>
            {cartItemModifiers.map((modifier, index) => (
              <Modifier key={modifier.id} data-test={`${testId}-mod-${index}`}>
                {buildNestedModifierDisplay(modifier, currencyCode)}
              </Modifier>
            ))}
          </ModifiersContainer>
        )}

        {item.specialInstructions && (
          <SpecialInstructions data-test={`${testId}-special_instructions`}>"{item.specialInstructions}"</SpecialInstructions>
        )}

        <CartItemControlsContainer>
          <ControlButton onClick={() => onEditItemClick(item.id)} data-test={`${testId}-button-edit`}>
            Edit
          </ControlButton>
          <ControlButton onClick={() => onRemoveItemClick(item.id)} data-test={`${testId}-button-remove`}>
            Remove
          </ControlButton>
        </CartItemControlsContainer>
      </CartItemInfoContainer>

      <div data-test={`${testId}-subtotal_with_mods`}>
        {isLoading ? (
          <Dots dotSize="12px" color="#2f80ed" />
        ) : (
          FormatService.formatCurrencyFromCents(item.itemSubtotalWithModifiers, currencyCode)
        )}
      </div>
    </CartItemContainer>
  )
}

const buildNestedModifierDisplay = (modifier, currencyCode) => {
  const modifierDisplays = [formatModifierDisplay(modifier, currencyCode)]
  const nestedModifiers = _.reduce(
    modifier.modifiers,
    (accum, modifier) => {
      for (let i = 0; i < modifier.qty; i += 1) {
        accum.push(modifier)
      }
      return accum
    },
    []
  )

  for (const nestedMod of nestedModifiers) {
    modifierDisplays.push(buildNestedModifierDisplay(nestedMod, currencyCode))
  }

  return modifierDisplays.join(', ')
}

const formatModifierDisplay = (modifier, currencyCode) =>
  `${modifier.name} ${modifier.price > 0 ? `(+ ${FormatService.formatCurrencyFromCents(modifier.price, currencyCode)})` : ''}`

const canCheckout = (items, orderAheadTime, scheduleNowRange) =>
  items.length > 0 && (!_.isNil(orderAheadTime) || !_.isEmpty(scheduleNowRange))

const Cart = ({
  items,
  venue,
  siteName,
  onEditItemClick,
  onRemoveItemClick,
  applyPromoCodeClick,
  promoCode,
  updatePromoCode,
  removePromoCode,
  promoCodeId,
  promoCodeErrors,
  cartTotals,
  openPickupDeliveryModal,
  pickupDeliverySelection,
  deliveryAddressLine1,
  checkingOut,
  paymentChannel,
  action,
  actionText,
  scheduleTimeDisplay,
  isCartOnlyMode,
  toggleCartOnlyMode,
  orderAheadTime,
  processingCheckout,
  scheduleNowRange,
  paymentsLoaded,
  invalidFields,
  syncingCartItemId,
  shouldOfferTip,
  deliveryIntegration,
  widgetSettings,
  checkoutWithoutPayment,
  launchButtonRequired,
  showPromoCode,
}) => {
  const totalsContainerRef = useRef()
  const [totalsContainerElementHeight, setTotalsContainerElementHeight] = useState(0)
  const orderingSiteTipLabel = useStoreSelector(state => state.language.languageStrings.orderingSiteTipLabel)
  useLayoutEffect(() => {
    setTotalsContainerElementHeight(totalsContainerRef.current.getBoundingClientRect().height)
  }, [])

  const { currencySymbol, currency: currencyCode } = venue

  return (
    <CartContainer checkingOut={checkingOut} isCartOnlyMode={isCartOnlyMode} data-test="checkout-cart-container">
      {!checkingOut && (
        <CartFulfillmentDetails
          onClick={() => openPickupDeliveryModal()}
          {...{
            venue,
            siteName,
            pickupDeliverySelection,
            deliveryAddressLine1,
            scheduleTimeDisplay,
            isCartOnlyMode,
            toggleCartOnlyMode,
          }}
        />
      )}

      <PaddedContainer totalsContainerHeight={totalsContainerElementHeight} checkingOut={checkingOut}>
        <CartItemsContainer isCartOnlyMode={isCartOnlyMode}>
          {items.map((item, index) => (
            <CartItem
              key={`cart-item-${item.id}`}
              testId={`cart-item-${index}`}
              id={item.id}
              index={index}
              item={item}
              onEditItemClick={onEditItemClick}
              onRemoveItemClick={onRemoveItemClick}
              currencyCode={currencyCode}
              isLoading={syncingCartItemId === item.id}
            />
          ))}
        </CartItemsContainer>
      </PaddedContainer>

      {checkingOut && showPromoCode && (
        <PromoCodeForm
          applyPromoCodeClick={applyPromoCodeClick}
          promoCode={promoCode}
          updatePromoCode={updatePromoCode}
          removePromoCode={removePromoCode}
          promoCodeId={promoCodeId}
          promoCodeErrors={promoCodeErrors}
        />
      )}

      <TotalsContainer data-test="sr-total-container" ref={totalsContainerRef} isCartOnlyMode={isCartOnlyMode}>
        <PriceLineContainer>
          <div>Item Subtotal</div>
          <div data-test="checkout-subtotal">{FormatService.formatCurrencyFromCents(cartTotals.subtotal || 0, currencyCode)}</div>
        </PriceLineContainer>
        {checkingOut && (
          <div>
            {!!cartTotals.discount && (
              <PriceLineContainer>
                <div>Promo Applied ({promoCode})</div>
                <PromoDisplay data-test="checkout-promo-discount">
                  -{FormatService.formatCurrencyFromCents(cartTotals.discount, currencyCode)}
                </PromoDisplay>
              </PriceLineContainer>
            )}
            {cartTotals.serviceCharges.map(charge =>
              charge.amount ? (
                <PriceLineContainer>
                  <div>{charge.name}</div>
                  <div data-test={`checkout-${charge.name}`}>{FormatService.formatCurrencyFromCents(charge.amount, currencyCode)}</div>
                </PriceLineContainer>
              ) : null
            )}
            {!!cartTotals.tax && (
              <PriceLineContainer>
                <div>Tax</div>
                <div data-test="checkout-tax">{FormatService.formatCurrencyFromCents(cartTotals.tax, currencyCode)}</div>
              </PriceLineContainer>
            )}
            {shouldOfferTip && (
              <PriceLineContainer>
                <div>{deliveryIntegration && pickupDeliverySelection === DELIVERY ? 'Driver Tip' : orderingSiteTipLabel}</div>
                <div data-test="checkout-tip">{FormatService.formatCurrencyFromCents(cartTotals.tip || 0, currencyCode)}</div>
              </PriceLineContainer>
            )}
            <TotalLineContainer>
              <div>Total</div>
              <Total data-test="checkout-total">{FormatService.formatCurrencyFromCents(cartTotals.total || 0, currencyCode)}</Total>
            </TotalLineContainer>
          </div>
        )}
        {checkingOut && (
          <ExceptDesktopOnly>
            <CheckoutFooter
              privacyPolicyUrl={widgetSettings.privacyPolicyUrl}
              gdprPolicyUrl={widgetSettings.textCustomGdprPolicyLink}
              termsOfServiceUrl={widgetSettings.termsOfServiceUrl}
              customStyles={customFooterStyles}
            />
          </ExceptDesktopOnly>
        )}
        <CheckoutButton
          canCheckout={canCheckout(items, orderAheadTime, scheduleNowRange)}
          {...{
            invalidFields,
            paymentsLoaded,
            paymentChannel,
            checkingOut,
            processingCheckout,
            action,
            actionText,
            checkoutWithoutPayment,
            cartTotals,
            launchButtonRequired,
          }}
        />
      </TotalsContainer>
    </CartContainer>
  )
}

export default Cart
