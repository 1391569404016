import _ from 'lodash'
import moment from 'moment-timezone'
import {
  adjustForStartOfDay,
  dateParts,
  formatDateOnly,
  formatTimeOnly,
  getPythonWeekday,
  secondsSinceMidnight,
  timeParts,
  venueDateForDatetime,
} from 'mgr/lib/utils/MomentUtils'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { FULFILLMENT_METHOD } from 'svr/constants'
import { mergeMenuAvailableHours } from 'svr/lib/Ordering/mergeMenuAvailableHours'
import { nowToNearestMinute, venueToday } from 'widget/universal/selectors/common/today'
import { SCHEDULE_NOW } from 'widget/universal/utils/ordering/constants'

const OPEN_CLOSED_STATUS_FULFILLMENT_METHOD_TITLE = {
  [FULFILLMENT_METHOD.PICKUP]: 'Pickup',
  [FULFILLMENT_METHOD.DELIVERY]: 'Delivery',
  [FULFILLMENT_METHOD.ROOM_SERVICE]: 'Room Service',
  [FULFILLMENT_METHOD.PICKUP_OR_DELIVERY]: 'Pickup and Delivery',
  [FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE]: 'Pickup and Room Service',
  [FULFILLMENT_METHOD.ON_PREMISE]: 'Ordering',
}

export const ROOM_SERVICE_DRIVING_TIME_SECONDS = 300
const ROOM_SERVICE_DRIVING_TIME_MIN = ROOM_SERVICE_DRIVING_TIME_SECONDS / 60

export const selectEditOrSavedState = state =>
  _.isEmpty(state.ordering.pickupDelivery.editState) ? state.ordering.pickupDelivery : state.ordering.pickupDelivery.editState
const selectOrderAheadDate = state => selectEditOrSavedState(state).orderAheadDate
const selectOrderAheadTime = state => selectEditOrSavedState(state).orderAheadTime
const selectScheduleType = state => selectEditOrSavedState(state).scheduleType
export const selectOrderingSite = state => state.ordering.orderingSite.orderingSite
const selectMenus = state => state.ordering.menus.menus
const selectPickupDeliverySelection = state => selectEditOrSavedState(state).selection
const selectDrivingTimeSeconds = state => selectEditOrSavedState(state).drivingTimeSeconds
const selectStartOfDayHour = state => state.venue.startOfDayHour
const selectVenueLocale = state => state.venue.locale
export const selectOrderAheadMaxMinutes = state => selectOrderingSite(state).maxOrderAheadMins
const selectTimeSlotSpacingMinutes = state => selectOrderingSite(state).timeSlotSpacingMins
export const selectTimeSlotWidthMinutes = state => selectOrderingSite(state).timeSlotWidthMins
const selectIsAddressValid = state => selectEditOrSavedState(state).isAddressValid
const selectIsAddressValidating = state => selectEditOrSavedState(state).isAddressValidating
const selectThrottledTimeKeys = state => state.ordering.pickupDelivery.throttledTimeKeys[selectPickupDeliverySelection(state)] || new Set()

export const selectDeliveryAddressLine1 = state => {
  const addressComponents = selectDeliveryPlaceAddressComponents(state)
  if (_.isEmpty(addressComponents)) {
    return ''
  }
  return [_.find(addressComponents, { types: ['street_number'] }) || {}, _.find(addressComponents, { types: ['route'] }) || {}]
    .map(c => _.get(c, 'short_name', ''))
    .join(' ')
}
export const selectIsDeliveryAddressMissingStreetNumber = state => {
  const addressComponents = selectDeliveryPlaceAddressComponents(state)
  return addressComponents && !_.find(addressComponents, { types: ['street_number'] })
}

const selectDeliveryPlaceAddressComponents = state => (selectEditOrSavedState(state).place || {}).address_components

const computeRequiresPickupDeliverySelectionParams = state => ({
  fulfillmentMethod: selectOrderingSite(state).fulfillmentMethod,
  fulfillmentSelectionTimestamp: state.ordering.pickupDelivery.fulfillmentSelectionTimestamp,
  venueNowMoment: nowToNearestMinute(state),
})

const FULFILLMENT_SELECTION_SESSION_DURATION = moment.duration(12, 'hours')

const computeRequiresPickupDeliverySelectionValue = ({ fulfillmentSelectionTimestamp, venueNowMoment, fulfillmentMethod }) =>
  fulfillmentMethod !== FULFILLMENT_METHOD.ON_PREMISE &&
  (_.isNil(fulfillmentSelectionTimestamp) || venueNowMoment.diff(fulfillmentSelectionTimestamp) > FULFILLMENT_SELECTION_SESSION_DURATION)

export const selectRequiresPickupDeliverySelection = createShallowEqualSelector(
  computeRequiresPickupDeliverySelectionParams,
  computeRequiresPickupDeliverySelectionValue
)

const computeDesiredTimestampParams = state => ({
  orderAheadDate: selectOrderAheadDate(state),
  orderAheadTime: selectOrderAheadTime(state),
  startOfDayHour: selectStartOfDayHour(state),
})

const computeDesiredTimestampValue = ({ orderAheadDate, orderAheadTime, startOfDayHour }) =>
  orderAheadDate &&
  orderAheadTime &&
  adjustForStartOfDay(
    moment({
      ...dateParts(orderAheadDate),
      ...timeParts(orderAheadTime),
    }),
    startOfDayHour
  )

export const selectDesiredTimestamp = createShallowEqualSelector(computeDesiredTimestampParams, computeDesiredTimestampValue)

const computeTargetReadyTimestampParams = (state, _forceReselect) => ({
  venueNowMoment: nowToNearestMinute(state),
  desiredTimestamp: selectDesiredTimestamp(state),
  cartFilteredMenus: selectMenusForCartItems(state),
  hasCartItems: selectHasCartItems(state),
  pickupDeliverySelection: selectPickupDeliverySelection(state),
  drivingTimeSeconds: selectDrivingTimeSeconds(state),
  startOfDayHour: selectStartOfDayHour(state),
  orderAheadTimeOptionsParams: computeOrderAheadTimeOptionsParams(state),
})

const computeTargetReadyTimestampValue = ({
  venueNowMoment,
  desiredTimestamp,
  cartFilteredMenus,
  hasCartItems,
  pickupDeliverySelection,
  drivingTimeSeconds,
  startOfDayHour,
  orderAheadTimeOptionsParams,
}) => {
  if (_.isNil(desiredTimestamp)) {
    // ASAP orders
    const defaultToNowIfFullyThrottled = true
    return computeAsapOrderTargetReadyTimestamp(
      cartFilteredMenus,
      hasCartItems,
      venueNowMoment,
      orderAheadTimeOptionsParams,
      startOfDayHour,
      defaultToNowIfFullyThrottled
    )
  }

  // Order ahead orders
  const drivingOffset = computeDrivingTimeOffset(pickupDeliverySelection, drivingTimeSeconds, roundToNext5)
  return desiredTimestamp.clone().subtract({ minutes: drivingOffset })
}

const computeAsapOrderTargetReadyTimestamp = (
  cartFilteredMenus,
  hasCartItems,
  venueNowMoment,
  orderAheadTimeOptionsParams,
  startOfDayHour,
  defaultToNowIfFullyThrottled = false
) => {
  const { throttledTimeKeys, venueTodayMoment } = orderAheadTimeOptionsParams
  const prepTimeMinutes = getPrepTimeForMenus(cartFilteredMenus, hasCartItems)
  const { prepTargetTimeMoment: targetReadyTimeMoment, prepTargetTimeOnly: targetReadyTimeOnly } = addPrepTimeToTargetTimeMoment(
    prepTimeMinutes,
    startOfDayHour,
    venueNowMoment
  )
  if (!isIterMomentThrottled(targetReadyTimeMoment, startOfDayHour, throttledTimeKeys)) {
    return targetReadyTimeMoment
  }

  const nextMenuEndTime = getNextEndTimeForCartMenusAvailableHours(
    cartFilteredMenus,
    startOfDayHour,
    targetReadyTimeMoment,
    targetReadyTimeOnly
  )

  const orderAheadDate = venueTodayMoment.clone()
  const orderAheadMaxMinutes = nextMenuEndTime ? nextMenuEndTime.diff(targetReadyTimeOnly, 'minutes') : -1
  const mutatedOrderAheadTimeOptionsParams = {
    ...orderAheadTimeOptionsParams,
    orderAheadDate,
    orderAheadMaxMinutes,
    drivingTimeSeconds: 0,
    returnFirstResult: true,
  }
  const orderAheadTimeOptions = computeOrderAheadTimeOptionsValue(mutatedOrderAheadTimeOptionsParams)
  if (orderAheadTimeOptions.length) {
    return orderAheadTimeOptions[0]
  }
  if (defaultToNowIfFullyThrottled) {
    return targetReadyTimeMoment
  }
  return null
}

const getNextEndTimeForCartMenusAvailableHours = (cartFilteredMenus, startOfDayHour, targetReadyTimeMoment, targetReadyTimeOnly) => {
  cacheMenuTimeRangeMoments(cartFilteredMenus, startOfDayHour)

  let nextMenuEndTime
  for (const menu of cartFilteredMenus) {
    const timeRangeMoments = getAvailableHourMomentsForDate(menu, targetReadyTimeMoment, startOfDayHour)
    for (const [menuStart, menuEnd] of timeRangeMoments) {
      if (targetReadyTimeOnly.isSameOrAfter(menuStart) && targetReadyTimeOnly.isBefore(menuEnd)) {
        if (!nextMenuEndTime || menuEnd.isAfter(nextMenuEndTime)) {
          // Last menu end time that's currently open
          nextMenuEndTime = menuEnd
        }
        break
      }
    }
  }
  return nextMenuEndTime
}

export const selectTargetReadyTimestamp = createShallowEqualSelector(computeTargetReadyTimestampParams, computeTargetReadyTimestampValue)

const computeDesiredTimestampForCheckoutParams = (state, _forceReselect) => ({
  venueNowMoment: nowToNearestMinute(state),
  desiredTimestamp: selectDesiredTimestamp(state),
  cartFilteredMenus: selectMenusForCartItems(state),
  hasCartItems: selectHasCartItems(state),
  startOfDayHour: selectStartOfDayHour(state),
  throttledTimeKeys: selectThrottledTimeKeys(state),
  pickupDeliverySelection: selectPickupDeliverySelection(state),
  drivingTimeSeconds: selectDrivingTimeSeconds(state),
  targetReadyTimestamp: selectTargetReadyTimestamp(state),
})

const computeDesiredTimestampForCheckoutValue = ({
  venueNowMoment,
  desiredTimestamp,
  cartFilteredMenus,
  hasCartItems,
  startOfDayHour,
  throttledTimeKeys,
  pickupDeliverySelection,
  drivingTimeSeconds,
  targetReadyTimestamp,
}) => {
  if (!_.isNil(desiredTimestamp)) {
    return desiredTimestamp
  }
  const prepTimeMinutes = getPrepTimeForMenus(cartFilteredMenus, hasCartItems)
  const { prepTargetTimeMoment: targetReadyTimeMoment } = addPrepTimeToTargetTimeMoment(prepTimeMinutes, startOfDayHour, venueNowMoment)
  if (!isIterMomentThrottled(targetReadyTimeMoment, startOfDayHour, throttledTimeKeys)) {
    return null
  }
  // If throttled, we inject asap orders as future orders
  const drivingOffset = computeDrivingTimeOffset(pickupDeliverySelection, drivingTimeSeconds, roundToNext5)
  return targetReadyTimestamp.clone().add({ minutes: drivingOffset })
}

export const selectDesiredTimestampForCheckout = createShallowEqualSelector(
  computeDesiredTimestampForCheckoutParams,
  computeDesiredTimestampForCheckoutValue
)

export const formatTimeSlotWindow = (timeSlot, timeSlotWidthMinutes) =>
  `${timeSlot.format('LT')} - ${moment(timeSlot).add({ minutes: timeSlotWidthMinutes }).format('LT')}`

export const formatOrderAheadDate = (momentDate, locale = 'en_US', longDayOfWeek = false) =>
  `${momentDate.format(longDayOfWeek ? 'dddd' : 'ddd')}, ${formatMonthDay(momentDate, locale)}`
export const formatMonthDay = (momentDate, locale = 'en_US') => `${momentDate.format(locale === 'en_US' ? 'MMM D' : 'D MMM')}`

const MENU_TIME_RANGE_MOMENTS_CACHE = {}

const cacheMenuTimeRangeMoments = (menus, startOfDayHour) => {
  for (const menu of menus) {
    if (MENU_TIME_RANGE_MOMENTS_CACHE[menu.id] !== undefined) {
      continue
    }
    const menuTimeRangeMomentsByDay = _.mapValues(menu.availableHours, timeRanges =>
      expandTimeRangesStraddlingStartOfDay(convertTimeRangesToMoments(timeRanges), startOfDayHour).map(([menuStart, menuEnd]) => [
        adjustForStartOfDay(menuStart, startOfDayHour),
        adjustForStartOfDay(menuEnd, startOfDayHour, true),
      ])
    )
    MENU_TIME_RANGE_MOMENTS_CACHE[menu.id] = menuTimeRangeMomentsByDay
  }
}

const convertTimeRangesToMoments = timeRanges => timeRanges.map(convertMenuTimeRangeToMoment)

export const expandTimeRangesStraddlingStartOfDay = (timeRangeMoments, startOfDayHour) =>
  /* If a venue's day starts at 6am and we have a time range that runs from 4am-10am
   * then we split that time range into two: 4am-5:59am, 6am-10am
   */
  timeRangeMoments.reduce((result, [menuStart, menuEnd]) => {
    const isStraddlingRange =
      menuStart.hour() < startOfDayHour && (menuEnd.hour() > startOfDayHour || (menuEnd.hour() === startOfDayHour && menuEnd.minute() > 0))
    if (!isStraddlingRange) {
      result.push([menuStart, menuEnd])
      return result
    }

    const startOfDayMoment = makeStartOfDayMoment(startOfDayHour, menuStart)
    result.push([menuStart, startOfDayMoment.clone().subtract({ minutes: 1 })])
    result.push([startOfDayMoment, menuEnd])
    return result
  }, [])

const makeStartOfDayMoment = (startOfDayHour, date = null) => moment({ hour: startOfDayHour, ...(date ? dateParts(date) : {}) })

const convertMenuTimeRangeToMoment = timeRange => {
  const menuStart = formatTimeOnly(moment(timeRange.start, 'h:m:s'))
  const menuEnd = formatTimeOnly(moment(timeRange.end, 'h:m:s'))
  return [menuStart, menuEnd]
}

const getAvailableHourMomentsForDate = (menu, targetTimeMoment, startOfDayHour) => {
  let weekday = getPythonWeekday(targetTimeMoment)
  if (targetTimeMoment.hour() < startOfDayHour) {
    weekday = weekday === 0 ? 6 : weekday - 1
  }
  return MENU_TIME_RANGE_MOMENTS_CACHE[menu.id][weekday]
}

const anyMenuOpenOnDay = (menus, targetDateOnly) => _.some(menus.map(menu => getAvailableHoursForDay(menu, targetDateOnly).length > 0))

const getAvailableHoursForDay = (menu, targetDateOnly) =>
  isDateInMenuRange(menu, targetDateOnly) ? menu.availableHours[getPythonWeekday(targetDateOnly)] : []

const computeMenusAvailableAtTime = (
  menus,
  venueNowMoment,
  targetTimeMoment,
  startOfDayHour,
  isAsapNextAvailableSearch = false,
  throttledTimeKeys = null, // Required if isAsapNextAvailableSearch=true
  includeMenusBeyondPrepTimeLimit = false
) => {
  cacheMenuTimeRangeMoments(menus, startOfDayHour)
  const targetDateOnly = venueDateForDatetime(targetTimeMoment, startOfDayHour)
  const targetTimeOnly = adjustForStartOfDay(formatTimeOnly(targetTimeMoment), startOfDayHour)
  const availableMenus = []
  for (const menu of menus) {
    if (!isDateInMenuRange(menu, targetDateOnly)) {
      continue
    }
    const { prepTimeMins } = menu
    const prepTimeMinsIfIncluded = isAsapNextAvailableSearch ? prepTimeMins : 0
    if (
      !includeMenusBeyondPrepTimeLimit &&
      venueNowMoment
        .clone()
        .add({ minutes: prepTimeMins })
        .isAfter(targetTimeMoment.clone().add({ minutes: prepTimeMinsIfIncluded }))
    ) {
      continue
    }
    const { prepTargetTimeMoment, prepTargetTimeOnly } = addPrepTimeToTargetTimeMoment(
      prepTimeMinsIfIncluded,
      startOfDayHour,
      targetTimeMoment,
      targetTimeOnly
    )
    const timeRangeMoments = getAvailableHourMomentsForDate(menu, prepTargetTimeMoment, startOfDayHour)
    for (const [menuStart, menuEnd] of timeRangeMoments) {
      if (prepTargetTimeOnly.isSameOrAfter(menuStart) && prepTargetTimeOnly.isBefore(menuEnd)) {
        if (
          !isAsapNextAvailableSearch ||
          !isThrottledTime(prepTargetTimeMoment, menuEnd.diff(prepTargetTimeOnly, 'minutes'), startOfDayHour, throttledTimeKeys)
        ) {
          availableMenus.push(menu)
        }
        break
      }
    }
  }
  return availableMenus
}

const isDateInMenuRange = (menu, targetDateOnly) => {
  const { dateRangeFrom, dateRangeTo, excludedDateRanges } = menu
  if (dateRangeFrom && dateRangeFrom.isAfter(targetDateOnly)) {
    return false
  }
  if (dateRangeTo && dateRangeTo.isBefore(targetDateOnly)) {
    return false
  }
  for (const excludedDateRange of excludedDateRanges) {
    if (targetDateOnly.isSameOrAfter(excludedDateRange.from) && targetDateOnly.isSameOrBefore(excludedDateRange.to)) {
      return false
    }
  }
  return true
}

const addPrepTimeToTargetTimeMoment = (prepTimeMins, startOfDayHour, targetTimeMoment, targetTimeOnly) => {
  const safeTargetTimeOnly = targetTimeOnly || adjustForStartOfDay(formatTimeOnly(targetTimeMoment), startOfDayHour)
  const prepTargetTimeMoment = targetTimeMoment.clone().add({ minutes: prepTimeMins })
  const prepTargetTimeOnly = safeTargetTimeOnly.clone().add({ minutes: prepTimeMins })
  const prepTimeCarriesIntoNextDay = prepTargetTimeOnly.hour() >= startOfDayHour && safeTargetTimeOnly.hour() < startOfDayHour
  if (prepTimeCarriesIntoNextDay) {
    prepTargetTimeOnly.subtract({ days: 1 })
  }
  return { prepTargetTimeMoment, prepTargetTimeOnly }
}

const isThrottledTime = (startTimeMoment, numMinutes, startOfDayHour, throttledTimeKeys) => {
  if (throttledTimeKeys.size === 0) {
    return false
  }
  const iterMoment = startTimeMoment.clone()
  const interval = 15
  for (let i = 0; i < numMinutes; i += interval) {
    if (!isIterMomentThrottled(iterMoment, startOfDayHour, throttledTimeKeys)) {
      return false
    }
    iterMoment.add({ minutes: interval })
  }
  return true
}

const computeMenusAvailableParams = state => ({
  menus: selectMenus(state),
  desiredTimestamp: selectDesiredTimestamp(state),
  venueNowMoment: nowToNearestMinute(state),
  startOfDayHour: selectStartOfDayHour(state),
  pickupDeliverySelection: selectPickupDeliverySelection(state),
  drivingTimeSeconds: selectDrivingTimeSeconds(state),
  throttledTimeKeys: selectThrottledTimeKeys(state),
})

const computeMenusAvailableValue = ({
  menus,
  desiredTimestamp,
  venueNowMoment,
  startOfDayHour,
  pickupDeliverySelection,
  drivingTimeSeconds,
  throttledTimeKeys,
}) => {
  const drivingOffset = computeDrivingTimeOffset(pickupDeliverySelection, drivingTimeSeconds, roundToNext5)
  const targetTimeMoment = _.isNil(desiredTimestamp) ? venueNowMoment : desiredTimestamp.clone().subtract({ minutes: drivingOffset })
  const isAsapNextAvailableSearch = _.isNil(desiredTimestamp)
  return computeMenusAvailableAtTime(menus, venueNowMoment, targetTimeMoment, startOfDayHour, isAsapNextAvailableSearch, throttledTimeKeys)
}

export const selectMenusAvailable = createShallowEqualSelector(computeMenusAvailableParams, computeMenusAvailableValue)

const computeMenusInCartParams = state => ({
  menusAvailable: selectMenusAvailable(state),
  cartItems: _.values(selectCartItemsById(state)),
})

const computeMenusInCartValue = ({ menusAvailable, cartItems }) => {
  if (_.isEmpty(cartItems)) {
    return menusAvailable
  }

  const menusWithCartItems = _.filter(menusAvailable, menu => _.find(cartItems, { menuId: menu.id }))
  return _.some(menusWithCartItems) ? menusWithCartItems : menusAvailable
}

export const selectMenusForCartItems = createShallowEqualSelector(computeMenusInCartParams, computeMenusInCartValue)

const computeCartItemsUnavailableParams = state => ({
  menuIdsAvailable: selectMenusAvailable(state).map(menu => menu.id),
  cartItems: _.values(selectCartItemsById(state)),
  itemsById: state.ordering.items.byId,
})

const computeCartItemsUnavailableValue = ({ menuIdsAvailable, cartItems, itemsById }) => {
  if (_.isEmpty(cartItems)) {
    return []
  }

  const unavailableCartItems = _.filter(cartItems, cartItem => !_.includes(menuIdsAvailable, cartItem.menuId))
  return unavailableCartItems.map(cartItem => ({
    id: cartItem.id,
    qty: cartItem.qty,
    name: itemsById[cartItem.itemId].name,
  }))
}

export const selectCartItemsUnavailable = createShallowEqualSelector(computeCartItemsUnavailableParams, computeCartItemsUnavailableValue)

const computeNavMenusParams = state => ({
  menus: selectMenus(state),
  menusAvailable: selectMenusAvailable(state),
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeNavMenusValue = params => {
  const { menus, menusAvailable } = params
  const orderAheadTimeOptionsParams = { ...params }
  return _.orderBy(
    menus.map(menu => ({
      id: menu.id,
      name: menu.name,
      ...navMenuAvailability(menu, menusAvailable, orderAheadTimeOptionsParams),
    })),
    ['isAvailable'],
    ['desc']
  )
}

const navMenuAvailability = (menu, menusAvailable, orderAheadTimeOptionsParams) => {
  if (_.find(menusAvailable, { id: menu.id })) {
    return {
      isAvailable: true,
      subHeaderText: 'Available',
    }
  }
  const mutatedOrderAheadTimeOptionsParams = {
    ...orderAheadTimeOptionsParams,
    menus: [menu],
  }
  const nextAvailable = scheduleNextAvailableForMenus(mutatedOrderAheadTimeOptionsParams)
  return {
    isAvailable: false,
    subHeaderText: _.isEmpty(nextAvailable.menuText) ? 'Not available' : `Next available ${nextAvailable.menuText}`,
  }
}

export const selectNavMenus = createShallowEqualSelector(computeNavMenusParams, computeNavMenusValue)

const computeActiveMenuNextAvailableParams = state => ({
  navMenus: selectNavMenus(state),
  menuId: state.ordering.menus.activeMenuId,
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeSelectedItemNextAvailableParams = state => ({
  navMenus: selectNavMenus(state),
  menuId: _.get(state.ordering.cart.currentCartItem, 'menuId'),
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeItemNextAvailableValue = params => {
  const { navMenus, menuId } = params
  const orderAheadTimeOptionsParams = { ...params }
  const isDisabled = computeIsMenuDisabledValue({ navMenus, menuId })
  const itemText = isDisabled ? computeItemTextForNextAvailableValue({ orderAheadTimeOptionsParams, menuId }) : ''
  return {
    isDisabled,
    itemText,
  }
}

const computeIsMenuDisabledValue = ({ navMenus, menuId }) => !_.get(_.find(navMenus, { id: menuId }), 'isAvailable', false)

const computeItemTextForNextAvailableValue = ({ orderAheadTimeOptionsParams, menuId }) => {
  const mutatedOrderAheadTimeOptionsParams = {
    ...orderAheadTimeOptionsParams,
    menus: _.filter(orderAheadTimeOptionsParams.menus, { id: menuId }),
  }
  return scheduleNextAvailableForMenus(mutatedOrderAheadTimeOptionsParams).itemText
}

export const selectActiveMenuNextAvailable = createShallowEqualSelector(computeActiveMenuNextAvailableParams, computeItemNextAvailableValue)

export const selectSelectedItemNextAvailable = createShallowEqualSelector(
  computeSelectedItemNextAvailableParams,
  computeItemNextAvailableValue
)

const computeScheduleNowRangeParams = state => ({
  menus: selectMenus(state),
  venueNowMoment: nowToNearestMinute(state),
  startOfDayHour: selectStartOfDayHour(state),
  cartItems: selectCartItemsById(state),
  pickupDeliverySelection: selectPickupDeliverySelection(state),
  drivingTimeSeconds: selectDrivingTimeSeconds(state),
  timeSlotWidthMinutes: selectTimeSlotWidthMinutes(state),
  isAddressValid: selectIsAddressValid(state),
  isAddressValidating: selectIsAddressValidating(state),
  hasCartItems: selectHasCartItems(state),
  orderAheadTimeOptionsParams: computeOrderAheadTimeOptionsParams(state),
})

const computeScheduleNowRangeValue = ({
  menus,
  venueNowMoment,
  startOfDayHour,
  cartItems,
  pickupDeliverySelection,
  drivingTimeSeconds,
  timeSlotWidthMinutes,
  isAddressValid,
  isAddressValidating,
  hasCartItems,
  orderAheadTimeOptionsParams,
}) => {
  if (pickupDeliverySelection === FULFILLMENT_METHOD.DELIVERY && !isAddressValid) {
    return isAddressValidating ? 'calculating' : ''
  }
  const menusAvailable = computeMenusAvailableAtTime(
    menus,
    venueNowMoment,
    venueNowMoment,
    startOfDayHour,
    true /* isAsapNextAvailableSearch */,
    orderAheadTimeOptionsParams.throttledTimeKeys
  )
  if (_.isEmpty(menusAvailable)) {
    return null
  }
  let cartFilteredMenus = computeMenusInCartValue({
    menusAvailable,
    cartItems,
  })
  if (_.isEmpty(cartFilteredMenus)) {
    cartFilteredMenus = menusAvailable
  }
  const rangeStart = computeAvailableTimeOffset(
    pickupDeliverySelection,
    drivingTimeSeconds,
    cartFilteredMenus,
    hasCartItems,
    venueNowMoment,
    orderAheadTimeOptionsParams,
    startOfDayHour
  )
  if (!rangeStart) {
    return null
  }
  const rangeEnd = rangeStart + timeSlotWidthMinutes
  return `${rangeStart}-${rangeEnd}`
}

const computeAvailableTimeOffset = (
  pickupDeliverySelection,
  drivingTimeSeconds,
  cartFilteredMenus,
  hasCartItems,
  venueNowMoment,
  orderAheadTimeOptionsParams,
  startOfDayHour
) => {
  const targetReadyTimestamp = computeAsapOrderTargetReadyTimestamp(
    cartFilteredMenus,
    hasCartItems,
    venueNowMoment,
    orderAheadTimeOptionsParams,
    startOfDayHour
  )
  if (!targetReadyTimestamp) {
    return null
  }
  const prepAndThrottledTimeMinutes = targetReadyTimestamp.diff(venueNowMoment, 'minutes')
  const drivingOffset = computeDrivingTimeOffset(pickupDeliverySelection, drivingTimeSeconds)
  return prepAndThrottledTimeMinutes + drivingOffset
}

const computeDrivingTimeOffset = (pickupDeliverySelection, drivingTimeSeconds, roundFn = x => x) => {
  const drivingTimeMinutes =
    pickupDeliverySelection === FULFILLMENT_METHOD.DELIVERY
      ? _.round(drivingTimeSeconds / 60)
      : pickupDeliverySelection === FULFILLMENT_METHOD.ROOM_SERVICE
      ? ROOM_SERVICE_DRIVING_TIME_MIN
      : 0
  return roundFn(drivingTimeMinutes)
}

const getPrepTimeForMenus = (menus, hasCartItems) =>
  _.isEmpty(menus) ? 25 : hasCartItems ? getMaxPrepTimeForMenus(menus) : getMinPrepTimeForMenus(menus)

const computeCheckoutPrepTimeParams = state => ({
  menus: selectMenusForCartItems(state),
})

const computeCheckoutPrepTimeValue = ({ menus }) => getMaxPrepTimeForMenus(menus)

export const selectCheckoutPrepTime = createShallowEqualSelector(computeCheckoutPrepTimeParams, computeCheckoutPrepTimeValue)

const getMinPrepTimeForMenus = menus => Math.min(...listPrepTimeForMenus(menus))
const getMaxPrepTimeForMenus = menus => Math.max(...listPrepTimeForMenus(menus))
const listPrepTimeForMenus = menus => _.map(menus, 'prepTimeMins')

const getMaxMenuStartDate = menus => {
  const startDates = menus.map(menu => menu.dateRangeFrom).filter(dateRangeFrom => !!dateRangeFrom)
  if (startDates.length === 0) {
    return null
  }
  return moment.max(startDates)
}

const selectCartItemsById = state => state.ordering.cart.cartItemsById
const selectHasCartItems = state => _.some(selectCartItemsById(state))

export const selectScheduleNowRange = createShallowEqualSelector(computeScheduleNowRangeParams, computeScheduleNowRangeValue)

const computeScheduleNextAvailableParams = state => ({
  scheduleNowRange: selectScheduleNowRange(state),
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeScheduleNextAvailableValue = params => {
  const { scheduleNowRange } = params
  const orderAheadTimeOptionsParams = { ...params }
  if (!_.isNil(scheduleNowRange)) {
    return {
      menuText: '',
      itemText: '',
    }
  }
  return scheduleNextAvailableForMenus(orderAheadTimeOptionsParams)
}

const MAX_ALLOWED_ORDER_AHEAD_MINUTES = moment.duration(90, 'days').asMinutes()

const scheduleNextAvailableForMenus = (orderAheadTimeOptionsParams, enforceOrderAheadMaxMinutes = false) => {
  const { venueTodayMoment, venueNowMoment, locale, menus } = orderAheadTimeOptionsParams
  /**
   * The farthest out next available day is the later of today or the last start day for future menus,
   * plus 8 days plus maximum prep time
   */
  const maxMenuPrepTime = getMaxPrepTimeForMenus(menus)
  const maxMenuStartDate = getMaxMenuStartDate(menus) || venueTodayMoment
  const maxNextAvailableDate = moment.max([venueTodayMoment, maxMenuStartDate]).clone().add({ days: 8, minutes: maxMenuPrepTime })
  const maxNextAvailableMinutes = moment.duration(maxNextAvailableDate.diff(venueNowMoment)).asMinutes()
  const orderAheadMaxMinutes = Math.min(
    MAX_ALLOWED_ORDER_AHEAD_MINUTES, // capped at 90 days
    enforceOrderAheadMaxMinutes
      ? Math.min(maxNextAvailableMinutes, orderAheadTimeOptionsParams.orderAheadMaxMinutes)
      : maxNextAvailableMinutes
  )

  const orderAheadDate = venueTodayMoment.clone()
  const mutatedOrderAheadTimeOptionsParams = {
    ...orderAheadTimeOptionsParams,
    orderAheadDate,
    orderAheadMaxMinutes,
    drivingTimeSeconds: 0,
    returnFirstResult: true,
  }
  const maxVenueDate = venueTodayMoment.clone().add({ minutes: maxNextAvailableMinutes })
  let numDaysFromToday = 0
  let orderAheadTimeOptions = []
  while (_.isEmpty(orderAheadTimeOptions) && orderAheadDate.isBefore(maxVenueDate)) {
    orderAheadTimeOptions = computeOrderAheadTimeOptionsValue(mutatedOrderAheadTimeOptionsParams)
    if (_.some(orderAheadTimeOptions)) {
      break
    }
    orderAheadDate.add({ days: 1 })
    numDaysFromToday += 1
  }
  const nextAvailTime = _.isEmpty(orderAheadTimeOptions) ? null : orderAheadTimeOptions[0]
  return {
    menuText: formatNextAvailableMenuText(nextAvailTime, orderAheadDate, numDaysFromToday, locale),
    itemText: formatNextAvailableItemText(nextAvailTime, orderAheadDate, numDaysFromToday, locale),
  }
}
const formatNextAvailableMenuText = (nextAvailTime, orderAheadDate, numDaysFromToday, locale) => {
  if (nextAvailTime === null) {
    return ''
  }
  let todayTomoDateDisplay
  if (numDaysFromToday === 0) {
    todayTomoDateDisplay = 'today'
  } else if (numDaysFromToday === 1) {
    todayTomoDateDisplay = 'tomorrow'
  } else {
    todayTomoDateDisplay = formatOrderAheadDate(orderAheadDate, locale)
  }
  return `${todayTomoDateDisplay} at ${nextAvailTime.format('LT')}`
}
const formatNextAvailableItemText = (nextAvailTime, orderAheadDate, numDaysFromToday, locale) => {
  if (nextAvailTime === null) {
    return 'Not Available'
  }
  let futureDayDisplay
  if (numDaysFromToday === 0) {
    futureDayDisplay = `at ${nextAvailTime.format('LT')}`
  } else if (numDaysFromToday === 1) {
    futureDayDisplay = 'Tomorrow'
  } else if (numDaysFromToday < 7) {
    futureDayDisplay = _.capitalize(orderAheadDate.format('dddd'))
  } else {
    futureDayDisplay = formatMonthDay(nextAvailTime, locale)
  }
  return `Available ${futureDayDisplay}`
}

export const selectScheduleNextAvailable = createShallowEqualSelector(computeScheduleNextAvailableParams, computeScheduleNextAvailableValue)

const computeShowBrowseMenuOptionParams = state => ({
  scheduleNowRange: selectScheduleNowRange(state),
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeShowBrowseMenuOptionValue = params => {
  const { scheduleNowRange } = params
  const orderAheadTimeOptionsParams = { ...params }
  if (!_.isNil(scheduleNowRange)) {
    return false
  }
  const nextAvailTime = scheduleNextAvailableForMenus(orderAheadTimeOptionsParams, true)
  return _.isEmpty(nextAvailTime.menuText)
}

export const selectShowBrowseMenuOption = createShallowEqualSelector(computeShowBrowseMenuOptionParams, computeShowBrowseMenuOptionValue)

const computeOpenOrClosedTextParams = state => ({
  fulfillmentMethod: selectOrderingSite(state).fulfillmentMethod,
  scheduleNextAvailable: selectScheduleNextAvailable(state),
})

const computeOpenOrClosedTextValue = ({ fulfillmentMethod, scheduleNextAvailable }) => {
  const fulfillmentMethodDisplay = OPEN_CLOSED_STATUS_FULFILLMENT_METHOD_TITLE[fulfillmentMethod] || ''

  const isClosed = _.some(scheduleNextAvailable.menuText)
  const openOrClosed = isClosed ? 'Closed' : 'Open'
  const suffix = isClosed ? ` until ${scheduleNextAvailable.menuText}` : ''
  return `${openOrClosed} for ${fulfillmentMethodDisplay}${suffix}`
}

export const selectOpenOrClosedText = createShallowEqualSelector(computeOpenOrClosedTextParams, computeOpenOrClosedTextValue)

const computeScheduleTimeDisplayParams = state => ({
  scheduleType: selectScheduleType(state),
  scheduleNowRange: selectScheduleNowRange(state),
  orderAheadDate: selectOrderAheadDate(state),
  orderAheadTime: selectOrderAheadTime(state),
  timeSlotWidthMinutes: selectTimeSlotWidthMinutes(state),
  venueTodayMoment: venueToday(state.venue),
  locale: state.venue.locale,
})

const computeScheduleTimeDisplayValue = ({
  scheduleType,
  scheduleNowRange,
  orderAheadDate,
  orderAheadTime,
  timeSlotWidthMinutes,
  venueTodayMoment,
  locale,
}) => {
  if (scheduleType === SCHEDULE_NOW) {
    if (_.isNil(scheduleNowRange)) {
      return 'Select a Time'
    }
    if (_.isEmpty(scheduleNowRange)) {
      return 'Now'
    }
    return `Now (${scheduleNowRange} min)`
  }
  const dateDisplay = orderAheadDate.isSame(venueTodayMoment) ? 'Today' : formatOrderAheadDate(orderAheadDate, locale)
  const timeSlotWindow = formatTimeSlotWindow(orderAheadTime, timeSlotWidthMinutes)
  return `${dateDisplay} ${timeSlotWindow}`
}

export const selectScheduleTimeDisplay = createShallowEqualSelector(computeScheduleTimeDisplayParams, computeScheduleTimeDisplayValue)

const computeOrderAheadMaxDaysParams = state => ({
  venueTodayMoment: venueToday(state.venue),
  orderAheadMaxMinutes: selectOrderAheadMaxMinutes(state),
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeOrderAheadMaxDays = params => {
  const { venueNowMoment, venueTodayMoment, orderAheadMaxMinutes, startOfDayHour } = params
  const maxOrderAheadMoment = venueNowMoment.clone().add({ minutes: orderAheadMaxMinutes })
  if (maxOrderAheadMoment.hour() < startOfDayHour) {
    maxOrderAheadMoment.subtract({ days: 1 })
  }
  return formatDateOnly(maxOrderAheadMoment).diff(formatDateOnly(venueTodayMoment), 'days')
}

export const selectOrderAheadMaxDays = createShallowEqualSelector(computeOrderAheadMaxDaysParams, computeOrderAheadMaxDays)

const computeOrderAheadDateOptionsParams = state => ({
  venueTodayMoment: venueToday(state.venue),
  orderAheadMaxDays: selectOrderAheadMaxDays(state),
  ...computeOrderAheadTimeOptionsParams(state),
})

const computeOrderAheadDateOptionsValue = params => {
  const { venueTodayMoment, orderAheadMaxDays } = params
  const orderAheadTimeOptionsParams = { ...params }
  const mutableOrderAheadTimeOptionsParams = {
    ...orderAheadTimeOptionsParams,
    returnFirstResult: true,
  }

  return _.range(orderAheadMaxDays + 1)
    .map(d => venueTodayMoment.clone().add({ days: d }))
    .filter(orderAheadDate => {
      mutableOrderAheadTimeOptionsParams.orderAheadDate = orderAheadDate
      return _.some(computeOrderAheadTimeOptionsValue(mutableOrderAheadTimeOptionsParams))
    })
}
export const selectOrderAheadDateOptions = createShallowEqualSelector(computeOrderAheadDateOptionsParams, computeOrderAheadDateOptionsValue)

const computeOrderAheadTimeOptionsParams = state => ({
  menus: selectMenus(state),
  venueTodayMoment: venueToday(state.venue),
  venueNowMoment: nowToNearestMinute(state),
  orderAheadDate: selectOrderAheadDate(state),
  orderAheadMaxMinutes: selectOrderAheadMaxMinutes(state),
  timeSlotSpacingMinutes: selectTimeSlotSpacingMinutes(state),
  startOfDayHour: selectStartOfDayHour(state),
  throttledTimeKeys: selectThrottledTimeKeys(state),
  pickupDeliverySelection: selectPickupDeliverySelection(state),
  drivingTimeSeconds: selectDrivingTimeSeconds(state),
  locale: state.venue.locale,
})

const SPEED_SEARCH_INTERVAL = 15

const computeOrderAheadTimeOptionsValue = ({
  menus,
  venueTodayMoment,
  venueNowMoment,
  orderAheadDate,
  orderAheadMaxMinutes,
  timeSlotSpacingMinutes,
  startOfDayHour,
  throttledTimeKeys,
  pickupDeliverySelection,
  drivingTimeSeconds,
  returnFirstResult = false,
}) => {
  const targetDate = orderAheadDate || venueTodayMoment
  if (!anyMenuOpenOnDay(menus, targetDate)) {
    return []
  }
  const startOfDayMoment = makeStartOfDayMoment(startOfDayHour, targetDate)
  const iterMoment = startOfDayMoment.clone()
  const hasCartItems = false
  const orderAheadTimeOptions = []
  let isSpeedSearching = false
  let isFirstSearch = true
  const maxOrderAheadMoment = venueNowMoment.clone().add({ minutes: orderAheadMaxMinutes })
  const drivingOffset = computeDrivingTimeOffset(pickupDeliverySelection, drivingTimeSeconds, roundToNext5)
  while (isIterMomentSameDay(iterMoment, startOfDayMoment, targetDate)) {
    if (iterMoment.isAfter(maxOrderAheadMoment)) {
      break
    }
    const menusOpenAtTime = computeMenusAvailableAtTime(
      menus,
      venueNowMoment,
      iterMoment,
      startOfDayHour,
      false /* isAsapNextAvailableSearch */,
      null,
      true /* includeMenusBeyondPrepTimeLimit */
    )
    if (_.some(menusOpenAtTime)) {
      if (isSpeedSearching) {
        isSpeedSearching = false
        iterMoment.subtract({ minute: SPEED_SEARCH_INTERVAL })
        continue
      }
      if (isIterMomentAvailable(menus, venueNowMoment, iterMoment, startOfDayHour, hasCartItems, throttledTimeKeys)) {
        orderAheadTimeOptions.push(iterMoment.clone().add({ minutes: drivingOffset }))
        if (returnFirstResult) {
          return orderAheadTimeOptions
        }
      }
      iterMoment.add({ minute: timeSlotSpacingMinutes || 15 })
    } else {
      if (isFirstSearch) {
        isSpeedSearching = true
      }
      iterMoment.add({ minute: isSpeedSearching ? SPEED_SEARCH_INTERVAL : 1 })
    }
    isFirstSearch = false
  }
  return orderAheadTimeOptions
}

const isIterMomentAvailable = (menus, venueNowMoment, iterMoment, startOfDayHour, hasCartItems, throttledTimeKeys) => {
  if (isIterMomentThrottled(iterMoment, startOfDayHour, throttledTimeKeys)) {
    return false
  }
  const availableMenus = computeMenusAvailableAtTime(menus, venueNowMoment, iterMoment, startOfDayHour)
  if (!_.some(availableMenus)) {
    return false
  }
  const prepTimeOffset = getPrepTimeForMenus(availableMenus, hasCartItems)
  const availableMoment = iterMoment.clone().subtract({ minutes: prepTimeOffset })
  return availableMoment.isSameOrAfter(venueNowMoment)
}

const isIterMomentThrottled = (iterMoment, startOfDayHour, throttledTimeKeys) => {
  if (throttledTimeKeys.size === 0) {
    return false
  }
  // See order_pacing_app.py: ThrottledTimesBuilder.build_throttled_time_key()
  const nearestMinute = Math.floor(iterMoment.minute() / 15) * 15
  const timeOrdinal = iterMoment.hour() * 60 + nearestMinute
  const dateMoment = venueDateForDatetime(iterMoment, startOfDayHour)
  const timeKey = `${dateMoment.format('YYYY-MM-DD')}#${timeOrdinal}`
  return throttledTimeKeys.has(timeKey)
}

const roundToNext5 = x => Math.ceil(x / 5) * 5

const isIterMomentSameDay = (iterMoment, startOfDayMoment, targetDate) => {
  const iterMinusStartOfDay = iterMoment.clone().subtract({ hour: startOfDayMoment.hour() })
  if (!iterMinusStartOfDay.isDST() && iterMoment.isDST()) {
    iterMinusStartOfDay.add({ hour: 1 })
  }
  if (iterMinusStartOfDay.isDST() && !iterMoment.isDST()) {
    iterMinusStartOfDay.subtract({ hour: 1 })
  }
  return iterMinusStartOfDay.date() === targetDate.date()
}

export const selectOrderAheadTimeOptions = createShallowEqualSelector(computeOrderAheadTimeOptionsParams, computeOrderAheadTimeOptionsValue)

const computeNearestAvailableOrderAheadTimeParams = state => ({
  orderAheadTime: selectOrderAheadTime(state),
  orderAheadTimeOptions: selectOrderAheadTimeOptions(state),
})

const computeNearestAvailableOrderAheadTimeValue = ({ orderAheadTime, orderAheadTimeOptions }) => {
  if (_.isNil(orderAheadTime)) {
    return null
  }
  let bestTime = null
  let bestTimeDiff = 0
  const timeDiff = o => Math.abs(secondsSinceMidnight(o) - secondsSinceMidnight(orderAheadTime))
  for (const orderAheadTimeOption of orderAheadTimeOptions) {
    if (bestTime === null || timeDiff(orderAheadTimeOption) < bestTimeDiff) {
      bestTime = orderAheadTimeOption
      bestTimeDiff = timeDiff(orderAheadTimeOption)
    }
  }
  return bestTime
}

export const selectNearestAvailableOrderAheadTime = createShallowEqualSelector(
  computeNearestAvailableOrderAheadTimeParams,
  computeNearestAvailableOrderAheadTimeValue
)

const computeMenuNameAvailableHoursParams = state => ({
  menus: selectMenus(state),
  locale: selectVenueLocale(state),
  startOfDayHour: selectStartOfDayHour(state),
})
const computeMenuNameAvailableHoursValue = ({ menus, locale, startOfDayHour }) => mergeMenuAvailableHours(menus, locale, startOfDayHour)

export const selectMenuNameAvailableHours = createShallowEqualSelector(
  computeMenuNameAvailableHoursParams,
  computeMenuNameAvailableHoursValue
)
