import { SUCCESS_PAGE_FETCH_SUCCESSFUL, SUCCESS_PAGE_START } from 'widget/universal/actions/ordering/ActionTypes'

const initialState = {
  order: {},
  orderItems: [],
}

const successPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_PAGE_START: {
      return {
        ...initialState,
      }
    }
    case SUCCESS_PAGE_FETCH_SUCCESSFUL: {
      return {
        ...state,
        order: {
          ...state.order,
          ...action.order,
        },
        orderItems: [...action.orderItems],
      }
    }
    default:
      return state
  }
}

export default successPageReducer
