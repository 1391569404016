import styled from 'styled-components'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'

const CheckoutTitle = styled.h2`
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};
  font-weight: normal;
  padding: ${props => props.theme.gutter.medium} 0;
  margin-bottom: ${props => props.theme.gutter.medium};
  margin-top: ${props => props.theme.gutter.medium};
  ${props => props.mobileOnly && 'display: none;'};

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    ${props => props.mobileOnly && 'display: block;'};
  }
`

export default CheckoutTitle
