import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Dots from 'svr/component-lib/Generic/Loading/Dots'
import Button from 'svr/component-lib/Widget/Buttons/Ordering'
import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'

const CheckoutButtonContainer = styled.div`
  min-height: 40px;
`

const invalidHandler = invalidFields => {
  if (!_.isEmpty(invalidFields)) {
    document.querySelector('.checkout-details-form').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

    return false
  }
  return true
}

const CheckoutButton = ({
  invalidFields,
  paymentsLoaded,
  paymentChannel,
  checkingOut,
  processingCheckout,
  canCheckout,
  action,
  actionText,
  checkoutWithoutPayment,
  cartTotals,
  launchButtonRequired,
}) => {
  const platformButton = ![PAYMENT_CHANNELS.PAY_LATER, PAYMENT_CHANNELS.NEW_CREDIT_CARD].includes(paymentChannel)
  return (
    <CheckoutButtonContainer>
      {checkingOut ? (
        <div>
          {paymentChannel === PAYMENT_CHANNELS.PAY_LATER || cartTotals.total === 0 ? (
            <Button
              id="checkout-button"
              disabled={!canCheckout || processingCheckout}
              data-test="checkout-button"
              onClick={checkoutWithoutPayment}
            >
              {actionText}
            </Button>
          ) : paymentsLoaded && (!platformButton || launchButtonRequired) ? (
            <Button
              data-test="checkout-button"
              id="checkout-button"
              onClick={() => {
                action()
                invalidHandler(invalidFields)
              }}
              disabled={!canCheckout || processingCheckout}
            >
              {processingCheckout ? <Dots dotSize="12px" color="#fff" /> : <span id="checkout-button-text">{actionText}</span>}
            </Button>
          ) : null}

          {/* platform-pay-button element must be present and cannot be removed or interface will fail */}
          <div id="platform-pay-button" style={platformButton ? {} : { display: 'none' }} />
        </div>
      ) : (
        <Button id="checkout-button" disabled={!canCheckout} data-test="checkout-button" onClick={action}>
          {actionText}
        </Button>
      )}
    </CheckoutButtonContainer>
  )
}

export default CheckoutButton
