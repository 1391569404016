import type { OrderingMenu, OrderingMenuItem } from '@sevenrooms/core/domain'
import { FormatService } from '@sevenrooms/core/locales'
import styled, { css, useTheme } from '@sevenrooms/core/ui-kit' // to be fixed in VX-3719
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useMediaQuery } from '@sevenrooms/react-components/hooks/useMediaQuery'
import { useOrderingBrandSettings } from '../hooks/useOrderingBrandSettings'
import { MenuItemCardCount } from './MenuItemCardCount'
import { MenuItemCardImage } from './MenuItemCardImage'
import { ProductTags } from './ProductTags'

interface MenuItemCardProps {
  item: OrderingMenuItem
  quantity?: number
  onItemClick: () => void
  testId: string
  isItemSnoozed: boolean
  currencyCode: string
  activeMenuNextAvailable: OrderingMenu
}

export function MenuItemCard({
  item,
  quantity,
  onItemClick,
  testId,
  isItemSnoozed,
  currencyCode,
  activeMenuNextAvailable,
}: MenuItemCardProps) {
  const theme = useTheme()
  const brandSettings = useOrderingBrandSettings()
  const imageURL = item.imageUrls.medium || item.imageUrls.external_urls?.[0]
  const isDisabled = activeMenuNextAvailable.isDisabled || isItemSnoozed
  const isNormalPhoneScreen = useMediaQuery(`(max-width:${theme.breakpoints.s}px)`)
  const isSmallPhoneScreen = useMediaQuery(`(max-width:${theme.breakpoints.xs}px)`)
  const primaryTextFontSize = isSmallPhoneScreen ? 'm' : 'l'
  const secondaryTextFontSize = isSmallPhoneScreen ? 's' : 'm'

  return (
    <MenuItemContainer isDisabled={isDisabled} onClick={onItemClick} data-test={testId} brandLinkColor={brandSettings.fontsColorLinks}>
      <MenuItemDetails height="149px" width="100%" justifyContent="space-between" spacing={isNormalPhoneScreen ? 's' : 'm'}>
        <VStack spacing={isNormalPhoneScreen ? 's' : 'm'}>
          <VStack spacing={isNormalPhoneScreen ? 'xs' : 's'}>
            <Name
              color={isDisabled ? 'inactive' : 'primaryFont'}
              fontSize={primaryTextFontSize}
              lineHeight="m"
              fontWeight={500}
              textOverflow="twoLinesEllipsis"
            >
              {item.name}
            </Name>
            <Text
              color={isDisabled ? 'inactive' : 'secondaryFont'}
              fontSize={secondaryTextFontSize}
              lineHeight="s"
              fontWeight={400}
              textOverflow="twoLinesEllipsis"
            >
              {item.description || ''}
            </Text>
          </VStack>
          <Text color={isDisabled ? 'inactive' : 'secondaryFont'} fontSize={secondaryTextFontSize} lineHeight="s">
            {/* eslint-disable-next-line no-nested-ternary */}
            {activeMenuNextAvailable.isDisabled ? `${activeMenuNextAvailable.itemText} \u2022 ` : isDisabled ? 'Sold Out \u2022 ' : ''}
            {FormatService.formatCurrencyFromCents(item.price, currencyCode)}
          </Text>
        </VStack>
        <ProductTags tags={item.productTags} limitOnWidth isDisabled={isDisabled} />
      </MenuItemDetails>
      {imageURL && <MenuItemCardImage url={imageURL} isDisabled={isDisabled} isSnoozed={isItemSnoozed} />}
      {quantity && (
        <MenuItemCardCountWrapper>
          <MenuItemCardCount count={quantity} />
        </MenuItemCardCountWrapper>
      )}
    </MenuItemContainer>
  )
}

const Name = styled(Text)``

const MenuItemContainer = styled.div<{ isDisabled: boolean; brandLinkColor: string }>`
  position: relative;
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      ${Name} {
        ${props =>
          !props.isDisabled &&
          css`
            color: ${props.brandLinkColor} !important;
          `})
      }
    }
  }
`

const MenuItemDetails = styled(VStack)`
  overflow: hidden;
`

const MenuItemCardCountWrapper = styled(Box)`
  position: absolute;
  top: 4px;
  right: 4px;
`
