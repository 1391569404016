import React from 'react'
import OrderInstructions from 'widget/universal/components/ordering/successPage/OrderInstructions'
import VenueAddress from 'widget/universal/components/ordering/successPage/VenueAddress'
import { CardInfoLine, CardHeading, BorderContainer } from './styles'

const OnPremiseDetails = ({
  venueName,
  venueAddress,
  venueCityState,
  venuePostalCode,
  instructions,
  venueGmapsLink,
  onPremiseTableDisplayName,
}) => (
  <BorderContainer>
    <CardHeading>Your Order</CardHeading>
    <CardInfoLine data-test="confirmation-on-premise-venue_name">{venueName}</CardInfoLine>
    <CardInfoLine data-test="confirmation-on-premise-site_name">{onPremiseTableDisplayName}</CardInfoLine>
    <VenueAddress
      venueAddress={venueAddress}
      venueCityState={venueCityState}
      venuePostalCode={venuePostalCode}
      venueGmapsLink={venueGmapsLink}
      dataTest="confirmation-on-premise-venue_address"
    />
    <OrderInstructions instructions={instructions} dataTest="confirmation-on-premise-order_instructions" />
  </BorderContainer>
)

export default OnPremiseDetails

OnPremiseDetails.propTypes = {
  venueName: React.PropTypes.string.isRequired,
  venueAddress: React.PropTypes.string.isRequired,
  venueCityState: React.PropTypes.string.isRequired,
  venuePostalCode: React.PropTypes.string.isRequired,
  instructions: React.PropTypes.array,
  venueGmapsLink: React.PropTypes.string.isRequired,
  onPremiseTableDisplayName: React.PropTypes.string,
}
