import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'
import { arrayToObjectById } from 'widget/universal/utils/objects'

const initialState = {
  currentCartItem: null,
  cartItemsById: {},
  showItemModal: false,
  isCartEditMode: false,
  isLoadingSync: false,
  lastOrderId: null,
  cartTotals: {
    serviceCharges: [],
    total: 0,
    totalOriginal: 0,
    tax: 0,
    tip: 0,
    tipOriginal: 0,
    discount: 0,
  },
  currentModifierGroups: [],
  currentModifiers: [],
  currentNestedModifierIds: [],
  isValid: true,
  lastModifiedGuid: '',
  promoCode: '',
  promoCodeMin: null,
  promoCodeInfo: {},
  isCartOnlyMode: false,
  promoCodeId: null,
  promoCodeErrors: null,
  syncingCartItemId: null,
}

const getNewCartItem = (
  id,
  menuId,
  menuHierarchyItem,
  index,
  qty = 1,
  specialInstructions = '',
  modifiers = [],
  itemSubtotalWithModifiers = 0,
  totalPriceWithTax = 0,
  isAvailable = true
) => ({
  id,
  menuId,
  qty,
  itemId: menuHierarchyItem.id,
  modifierGroups: menuHierarchyItem.modifier_groups,
  index,
  specialInstructions,
  modifiers,
  itemSubtotalWithModifiers,
  totalPriceWithTax,
  isAvailable,
})

const nextIndex = cartItemsById => _.max(_.map(_.values(cartItemsById), 'index'))

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CART_ITEM_TO_CART: {
      const { cartItemsById } = state

      return {
        ...state,
        cartItemsById: {
          ...cartItemsById,
          [state.currentCartItem.id]: state.currentCartItem,
        },
        currentCartItem: null,
        showItemModal: false,
      }
    }
    case ActionTypes.CREATE_CART_ITEM: {
      const newCartItem = getNewCartItem(uuidv4(), action.menuId, action.item, nextIndex(state.cartItemsById))

      return {
        ...state,
        currentCartItem: newCartItem,
        showItemModal: true,
        currentModifierGroups: [newCartItem.modifierGroups],
        currentNestedModifierIds: [],
      }
    }
    case ActionTypes.UPDATE_CURRENT_CART_ITEM: {
      const { currentCartItem } = state

      return {
        ...state,
        currentCartItem: {
          ...currentCartItem,
          [action.field]: action.value,
        },
      }
    }
    case ActionTypes.UPDATE_CART_ITEM: {
      const cartItem = state.cartItemsById[action.cartItemId]

      return {
        ...state,
        cartItemsById: {
          ...state.cartItemsById,
          [cartItem.id]: {
            ...cartItem,
            [action.field]: action.value,
          },
        },
      }
    }
    case ActionTypes.CANCEL_ADD_CART_ITEM: {
      return {
        ...state,
        selectedItemId: null,
        showItemModal: false,
        currentCartItem: null,
        currentModifierGroups: [],
      }
    }
    case ActionTypes.SHOW_EDIT_CART_ITEM: {
      const cartItem = state.cartItemsById[action.cartItemId]

      return {
        ...state,
        showItemModal: true,
        isCartEditMode: true,
        currentCartItem: cartItem,
        currentModifierGroups: [cartItem.modifierGroups],
        currentNestedModifierIds: [],
      }
    }
    case ActionTypes.SAVE_EDIT_CART_ITEM:
      return {
        ...state,
        cartItemsById: {
          ...state.cartItemsById,
          [state.currentCartItem.id]: state.currentCartItem,
        },
        currentCartItem: null,
        showItemModal: false,
        isCartEditMode: false,
        currentModifierGroups: [],
        currentNestedModifierIds: [],
      }
    case ActionTypes.REMOVE_CART_ITEMS: {
      const itemsByIdWithoutDeletedCartItems = _.omit(state.cartItemsById, action.cartItemIds)
      return {
        ...state,
        cartItemsById: itemsByIdWithoutDeletedCartItems,
      }
    }
    case ActionTypes.CART_SYNC_START:
      return {
        ...state,
        isLoadingSync: true,
        lastModifiedGuid: '',
        syncingCartItemId: action.cartItemId,
      }
    case ActionTypes.CART_SYNC_FAIL:
      return {
        ...state,
        isLoadingSync: false,
        syncingCartItemId: null,
      }
    case ActionTypes.SELECT_MODIFIER_GROUP:
      const currentModifierGroups = [...state.currentModifierGroups, [action.modifierGroup]]
      const currentNestedModifierIds = [...state.currentNestedModifierIds, action.parentModifierId]

      return {
        ...state,
        currentModifierGroups,
        currentNestedModifierIds,
      }
    case ActionTypes.SELECT_PREVIOUS_MODIFIER_GROUP:
      return {
        ...state,
        currentModifierGroups: _.initial(state.currentModifierGroups),
        currentNestedModifierIds: _.initial(state.currentNestedModifierIds),
      }
    case ActionTypes.SET_IS_VALID:
      return {
        ...state,
        isValid: action.isValid,
      }
    case ActionTypes.CART_SYNC_SUCCESSFUL: {
      const { cartItems, menuHierarchyItemsById, lastOrderId, promoCode, promoCodeId, promoCodeMin, lastModifiedGuid, cartId, cartTotals } =
        action
      const cartItemsByIdWithTotals = arrayToObjectById(cartItems, 'cartLineItemId')
      const cartItemsById = arrayToObjectById(
        _.compact(
          _.keys(state.cartItemsById).map(cartItemId =>
            cartItemsByIdWithTotals[cartItemId]
              ? {
                  ...state.cartItemsById[cartItemId],
                  ...cartItemsByIdWithTotals[cartItemId],
                }
              : null
          )
        )
      )
      const newItems = _.filter(cartItems, i => !cartItemsById[i.cartLineItemId])
      for (const item of newItems) {
        const menuHierarchyItem = menuHierarchyItemsById[item.menuId][item.menuItemId]
        if (_.isNil(menuHierarchyItem)) {
          continue
        }
        cartItemsById[item.cartLineItemId] = getNewCartItem(
          item.cartLineItemId,
          item.menuId,
          menuHierarchyItem,
          nextIndex(cartItemsById),
          item.qty,
          item.specialInstructions,
          item.modifiers,
          item.itemSubtotalWithModifiers,
          item.totalPriceWithTax,
          item.isAvailable
        )
      }
      return {
        ...state,
        isLoadingSync: false,
        lastOrderId,
        promoCode,
        promoCodeId,
        promoCodeMin,
        cartItemsById,
        lastModifiedGuid,
        syncingCartItemId: null,
        cartId,
        cartTotals: {
          serviceCharges: cartTotals.serviceCharges,
          serviceChargeTotal: cartTotals.serviceChargeTotal,
          tax: cartTotals.tax,
          tip: cartTotals.tip,
          tipOriginal: cartTotals.tip,
          discount: cartTotals.discount,
          subtotal: cartTotals.subtotal,
          total: cartTotals.total,
          totalOriginal: cartTotals.total,
          verifyAge: cartTotals.verifyAge,
        },
      }
    }
    case ActionTypes.UPDATE_CHECKOUT_DETAILS:
      if (action.field !== 'paymentChannel') {
        return state
      }
      const isPayLater = PAYMENT_CHANNELS.PAY_LATER === action.value
      const { cartTotals } = state
      const { totalOriginal, tipOriginal } = cartTotals
      const total = totalOriginal - (isPayLater ? tipOriginal : 0)
      const tip = isPayLater ? 0 : tipOriginal
      return {
        ...state,
        cartTotals: {
          ...cartTotals,
          tip,
          total,
        },
      }
    case ActionTypes.UPDATE_PROMO_CODE: {
      return {
        ...state,
        promoCode: action.value.toUpperCase(),
        promoCodeId: null,
      }
    }
    case ActionTypes.REMOVE_PROMO_CODE: {
      return {
        ...state,
        promoCode: '',
        promoCodeId: null,
        promoCodeMin: null,
      }
    }
    case ActionTypes.VERIFY_PROMO_CODE_SUCCESS:
    case ActionTypes.VERIFY_PROMO_CODE_FAILURE: {
      return {
        ...state,
        promoCodeId: action.response.id,
        promoCodeErrors: action.response.errors,
        promoCodeMin: action.response.min_subtotal,
      }
    }
    case ActionTypes.TOGGLE_CART_ONLY_MODE: {
      return {
        ...state,
        isCartOnlyMode: !state.isCartOnlyMode,
      }
    }
    default:
      return state
  }
}

export default cartReducer
