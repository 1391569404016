import { faLongArrowAltLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import Button from 'svr/component-lib/Widget/Buttons/Ordering'
import TextAreaInput from 'svr/lib/TextInputs/TextAreaInput'
import Counter from 'widget/universal/components/ordering/Counter'
import ModifierGroups from 'widget/universal/components/ordering/ModifierGroups'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'
import { FormatService } from '@sevenrooms/core/locales'
import { ProductTags } from '@sevenrooms/ordering/components/ProductTags'

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  width: 450px;
  min-height: min(465px, 92vh);
  max-height: min(812px, 92vh);
  overflow-y: auto;
  position: relative;
  z-index: 2;
  touch-action: manipulation;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    width: 100%;
    height: 100%;
    border-radius: unset;
    min-height: unset;
    max-height: unset;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gutter.double};
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};
`

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.theme.gutter.double};
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.ordering.lightGrey};
`

const ButtonContainer = styled.div`
  button {
    display: flex;
    width: auto;
    padding: 0 ${props => props.theme.gutter.triple};
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;

    & > div:first-child {
      margin-right: ${props => props.theme.gutter.standard};
    }
  }
`

const ItemName = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  padding: 0;
`

const ItemDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: #8a8a8a;
  margin: ${props => props.theme.gutter.double} 0 0 0;
  padding: 0;
`

const SpecialInstructionsContainer = styled.div`
  padding: ${props => props.theme.gutter.double};

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 12px !important;
    position: static !important;
  }
`

const ItemImage = styled.img`
  width: 100%;
  max-height: 300px;
`

const IconWrapper = styled.div`
  width: 18px;
  cursor: pointer;
  color: #1b1c1e;
  transition: opacity 0.3s ease-in-out;
  margin-right: ${props => props.theme.gutter.medium};

  &:hover {
    opacity: 0.7;
  }
`

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`

const XWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gutter.medium};
  right: ${props => props.theme.gutter.medium};
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    opacity: 0.7;
  }
`

const computeModifiersSubtotal = (modifiers, modifiersById) =>
  modifiers.reduce((acc, modifier) => {
    const modifierData = modifiersById[modifier.modifierId]
    return acc + (modifierData.price + computeModifiersSubtotal(modifier.modifiers, modifiersById)) * modifier.qty
  }, 0)

const ItemModal = ({
  item,
  onSave,
  onCancel,
  updateCartItem,
  isCartEditMode,
  onModifierClick,
  modifierGroupsById,
  modifiersById,
  selectModifierGroup,
  currentModifierGroups,
  selectPreviousModifierGroup,
  currentNestedModifierIds,
  setIsValid,
  selectedItemNextAvailable,
  venue,
  allowSpecialInstructions,
}) => {
  const isModifiersNested = currentNestedModifierIds.length > 0
  const nestedModifierGroup = isModifiersNested && currentModifierGroups[0]
  const modifierName = isModifiersNested ? nestedModifierGroup.name || modifierGroupsById[nestedModifierGroup.id].name : item.name
  const modifiersSubtotal = computeModifiersSubtotal(item.modifiers, modifiersById)
  const subtotal = FormatService.formatCurrencyFromCents((item.price + modifiersSubtotal) * item.qty, venue.currency)
  const disabled = selectedItemNextAvailable.isDisabled || item.isItemSnoozed
  const imageURL = item.imageUrls?.medium || item.imageUrls.external_urls?.[0]

  return (
    <ModalCover
      customModalWrapper={css`
        @media (max-width: ${BREAKPOINTS.maxMobile}px) {
          max-height: none;
          width: 100%;
          height: 100%;
        }
      `}
    >
      <ModalContentContainer>
        {!isModifiersNested && (
          <XWrapper data-test="item-modal-button-x" onClick={() => onCancel(item.id)}>
            <FontAwesomeIcon icon={faTimes} />
          </XWrapper>
        )}

        <ContentContainer>
          {!isModifiersNested && imageURL && <ItemImage src={imageURL} />}

          <HeaderContainer>
            <NameContainer>
              {isModifiersNested && (
                <IconWrapper onClick={selectPreviousModifierGroup}>
                  <FontAwesomeIcon icon={faLongArrowAltLeft} />
                </IconWrapper>
              )}

              <ItemName data-test="item-modal-item-name">{modifierName}</ItemName>
            </NameContainer>

            {!isModifiersNested && item.description && (
              <ItemDescription data-test="item-modal-item-description">{item.description}</ItemDescription>
            )}
            {!isModifiersNested && !_.isEmpty(item.productTags) && <ProductTags tags={item.productTags} />}
          </HeaderContainer>

          <ModifierGroups
            modifierGroups={item.modifierGroups}
            onModifierClick={onModifierClick}
            modifiers={item.modifiers}
            modifierGroupsById={modifierGroupsById}
            modifiersById={modifiersById}
            currentModifierGroups={currentModifierGroups}
            selectModifierGroup={selectModifierGroup}
            currentNestedModifierIds={currentNestedModifierIds}
            selectPreviousModifierGroup={selectPreviousModifierGroup}
            setIsValid={setIsValid}
            disabled={disabled}
            currencyCode={venue.currency}
          />

          {!isModifiersNested && allowSpecialInstructions && (
            <SpecialInstructionsContainer>
              <TextAreaInput
                disabled={disabled}
                label="SPECIAL INSTRUCTIONS"
                value={item.specialInstructions}
                dataTest="item-modal-special_instructions"
                placeholder=""
                inputStyle={{
                  resize: 'none',
                  height: '112px',
                  border: '1px solid #E0E0E0',
                  padding: '8px',
                  borderRadius: '4px',
                }}
                disabledColor="#8A8A8A"
                onChange={val => updateCartItem('specialInstructions', val)}
              />
            </SpecialInstructionsContainer>
          )}
        </ContentContainer>

        {!isModifiersNested && (
          <FooterContainer>
            <Counter
              onDecrementClick={() => updateCartItem('qty', item.qty - 1 > 0 ? item.qty - 1 : 1)}
              onIncrementClick={() => updateCartItem('qty', item.qty + 1)}
              count={item.qty}
              disabled={disabled}
              incrementTestId="item-modal-counter-increment"
              decrementTestId="item-modal-counter-decrement"
            />

            <ButtonContainer>
              <Button
                onClick={() => {
                  const invalidBlock = document.querySelectorAll('.invalid-modifier')
                  const isCurrentlyValid = invalidBlock.length === 0
                  setIsValid(isCurrentlyValid)

                  if (isCurrentlyValid) {
                    onSave(item.id)
                  } else {
                    invalidBlock[0].scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                }}
                disabled={disabled}
                data-test="item-modal-button-add_item"
              >
                <div>
                  {selectedItemNextAvailable.isDisabled
                    ? selectedItemNextAvailable.itemText
                    : item.isItemSnoozed
                    ? 'Sold Out'
                    : isCartEditMode
                    ? 'Save'
                    : 'Add Item'}
                </div>
                <div data-test="item-modal-subtotal">{subtotal}</div>
              </Button>
            </ButtonContainer>
          </FooterContainer>
        )}
      </ModalContentContainer>
    </ModalCover>
  )
}

export default ItemModal
