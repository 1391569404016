import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { PAYMENT_CHANNELS, AccountTypes } from 'svr/lib/Payments/Constants'
import Gateway from 'svr/lib/Payments/Gateway'
import {
  showEditCartItem,
  removeCartItem,
  applyPromoCode,
  updatePromoCode,
  removePromoCode,
  toggleCartOnlyMode,
  loadCart,
} from 'widget/universal/actions/ordering/cart'
import {
  initPayments,
  submitOrder,
  checkoutWithoutPayment,
  updateCheckoutDetails,
  updateCustomFieldValue,
  toggleMarketingOptIn,
  paymentsLoaded,
  updateTip,
  gatewayException,
} from 'widget/universal/actions/ordering/checkout'
import { showError } from 'widget/universal/actions/ordering/errormodal'
import * as pickupDeliveryActions from 'widget/universal/actions/ordering/pickupdelivery'
import Cart from 'widget/universal/components/ordering/Cart'
import CheckoutForm from 'widget/universal/components/ordering/CheckoutForm'
import CartModals from 'widget/universal/containers/ordering/CartModals'
import { selectCartWithItems } from 'widget/universal/selectors/ordering/cart'
import {
  selectTipItems,
  selectShouldOfferTip,
  selectCustomFields,
  selectOnlinePaymentEnabled,
} from 'widget/universal/selectors/ordering/checkout'
import {
  selectScheduleTimeDisplay,
  selectDeliveryAddressLine1,
  selectScheduleNowRange,
} from 'widget/universal/selectors/ordering/schedules'
import { BREAKPOINTS, DELIVERY, PICKUP, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'

const ContentContainer = styled.div`
  display: ${props => (props.hideDuringProcessing ? 'none' : 'flex')};
  flex: 1;

  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    flex-direction: column;
  }
`

const SubLabel = styled.div`
  color: ${props => props.theme.color.grey};
  line-height: 1;
  padding-bottom: ${props => props.theme.padding.medium};
`

const getPaymentSetupFailedText = phoneNumber => (
  <>
    Unfortunately we cannot process your order.
    <br />
    Please contact venue for ordering support:
    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
  </>
)

const getPayButtonOptions = (payOptions, pickupDeliverySelection, isOnlinePaymentEnabled, paymentType) => {
  let payButtonOptions = []
  const preButtonOptions = []
  const postButtonOptions = []

  if (payOptions) {
    if (isOnlinePaymentEnabled && payOptions.includes(PAYMENT_CHANNELS.GOOGLE_PAY)) {
      preButtonOptions.push({
        value: PAYMENT_CHANNELS.GOOGLE_PAY,
        label: 'Pay with Google Pay',
      })
    }
    if (isOnlinePaymentEnabled && payOptions.includes(PAYMENT_CHANNELS.APPLE_PAY)) {
      preButtonOptions.push({
        value: PAYMENT_CHANNELS.APPLE_PAY,
        label: 'Pay with Apple Pay',
      })
    }
    if (payOptions.includes(PAYMENT_CHANNELS.PAY_LATER)) {
      const laterText = (
        <div>
          <div>{pickupDeliverySelection === DELIVERY ? 'Pay on delivery' : 'Pay later at venue'}</div>
          {isOnlinePaymentEnabled && paymentType === AccountTypes.FREEDOMPAY && (
            <SubLabel>The pre-selected tip will be removed; You will be able to leave a tip at time of payment</SubLabel>
          )}
        </div>
      )
      postButtonOptions.push({
        value: PAYMENT_CHANNELS.PAY_LATER,
        label: laterText,
      })
    }

    const newCreditCardOption = isOnlinePaymentEnabled
      ? [
          {
            value: PAYMENT_CHANNELS.NEW_CREDIT_CARD,
            label: 'Pay with credit card',
          },
        ]
      : []

    if (preButtonOptions.length || postButtonOptions.length) {
      payButtonOptions = [...preButtonOptions, ...newCreditCardOption, ...postButtonOptions]
    }
  }
  return payButtonOptions
}

class Checkout extends PureComponent {
  componentDidMount() {
    this.payOptions = []

    const errHandler = () => {
      alert('Failed to load payment capability.')
    }

    if (this.props.isOnlinePaymentEnabled) {
      try {
        this.gateway = new Gateway(this.props.venue, window.PRELOADED.is_prod, 'orderWidget', this.props.cartTotals)
      } catch (e) {
        this.props.gatewayException()
        return
      }
      this.gateway.setPayButtonCallback(paymentMethod => {
        this.props.submitOrder(paymentMethod)
      })
      this.gateway.setLoadedCallback(options => {
        if (!this.gateway.gate.bypassChannelScan) {
          options = this.gateway.scanForChannels(options)
        }
        this.props.paymentsLoaded(options)
      })
      this.props.initPayments(this.gateway)
      this.gateway.attachPrerequisites('card-input-mount', errHandler)

      this.gateway.channelSelector(this.gateway.gate.defaultChannel)
      this.props.updateCheckoutDetails('paymentChannel', this.gateway.gate.defaultChannel)
    } else if (this.props.allowPayLater) {
      this.props.paymentsLoaded([PAYMENT_CHANNELS.PAY_LATER])
    } else {
      // No supported payment gateway and no pay later enabled
      this.props.showError(getPaymentSetupFailedText(this.props.venue.phoneNumber), loadCart)
    }
    if (this.props.roomNumber && this.props.orderingRoomNumbers.find(roomNumber => roomNumber.number === this.props.roomNumber)) {
      this.props.updateCheckoutDetails('roomNumber', this.props.roomNumber)
    }
  }

  componentDidUpdate() {
    if (this.props.isOnlinePaymentEnabled) {
      this.gateway.componentUpdate()
    }
  }

  render() {
    const {
      venue,
      siteName,
      checkoutDetails = {},
      cartItems = [],
      cartTotals = {},
      isLoadingSync = false,
      showEditCartItem = () => {},
      removeCartItem = () => {},
      applyPromoCode = () => {},
      promoCode = '',
      updatePromoCode = () => {},
      removePromoCode = () => {},
      promoCodeId = null,
      promoCodeErrors = null,
      payOptions,
      paymentChannel,
      updateCheckoutDetails = () => {},
      updateCustomFieldValue = () => {},
      toggleMarketingOptIn = () => {},
      submitOrder = () => {},
      checkoutWithoutPayment = () => {},
      openPickupDeliveryModal = () => {},
      pickupDeliverySelection = '',
      isGuestWillPickUpRoomNumberFromList = false,
      orderingRoomNumbers = [],
      deliveryAddress = '',
      deliveryAddressLine1 = '',
      scheduleTimeDisplay = '',
      orderAheadTime = null,
      scheduleNowRange = '',
      processingCheckout,
      toggleCartOnlyMode,
      invalidFields,
      updateTip,
      tipItems,
      shouldOfferTip,
      syncingCartItemId,
      customFields,
      deliveryIntegration = null,
      widgetSettings,
      hideAlternateInterface,
      ageToConsent,
      isOnlinePaymentEnabled,
      paymentType,
      isPosTableMultiselect,
      posTables,
      showPromoCode,
      showNoteToRestaurant,
    } = this.props

    const channelSelector = (name, v, suppressSubmit) => {
      const supportInfo = suppressSubmit ? {} : { submitOrder }
      if (isOnlinePaymentEnabled) {
        this.gateway.channelSelector(v, supportInfo)
      }
      updateCheckoutDetails(name, v)
    }

    const defaultChannel = this.gateway ? this.gateway.gate.defaultChannel : null
    const launchButtonRequired = this.gateway ? this.gateway.gate.launchButtonRequired : null

    const payButtonOptions = getPayButtonOptions(payOptions, pickupDeliverySelection, isOnlinePaymentEnabled, paymentType)
    const actionText = `Place ${
      pickupDeliverySelection === PICKUP
        ? 'Pickup'
        : pickupDeliverySelection === DELIVERY
        ? 'Delivery'
        : pickupDeliverySelection === ROOM_SERVICE
        ? 'Room Service'
        : ''
    } Order`

    return (
      <ContentContainer hideDuringProcessing={checkoutDetails.processingCheckout}>
        <CartModals />
        <CheckoutForm
          {...{
            checkoutDetails,
            venue,
            siteName,
            updateCheckoutDetails,
            customFields,
            updateCustomFieldValue,
            openPickupDeliveryModal,
            pickupDeliverySelection,
            isGuestWillPickUpRoomNumberFromList,
            orderingRoomNumbers,
            deliveryAddress,
            toggleMarketingOptIn,
            scheduleTimeDisplay,
            orderAheadTime,
            scheduleNowRange,
            toggleCartOnlyMode,
            payButtonOptions,
            invalidFields,
            updateTip,
            tipItems,
            shouldOfferTip,
            widgetSettings,
            checkoutWithoutPayment,
            hideAlternateInterface,
            channelSelector,
            ageToConsent,
            cartTotals,
            defaultChannel,
            isPosTableMultiselect,
            posTables,
            showNoteToRestaurant,
          }}
        />

        <Cart
          onEditItemClick={showEditCartItem}
          onRemoveItemClick={removeCartItem}
          applyPromoCodeClick={applyPromoCode}
          promoCode={promoCode}
          updatePromoCode={updatePromoCode}
          removePromoCode={removePromoCode}
          promoCodeId={promoCodeId}
          promoCodeErrors={promoCodeErrors}
          showPromoCode={showPromoCode}
          checkingOut
          items={cartItems}
          isLoading={isLoadingSync}
          action={() => submitOrder()}
          actionText={actionText}
          processingCheckout={processingCheckout}
          paymentsLoaded={checkoutDetails.paymentsLoaded}
          {...{
            venue,
            cartTotals,
            paymentChannel,
            openPickupDeliveryModal,
            pickupDeliverySelection,
            deliveryAddressLine1,
            scheduleTimeDisplay,
            orderAheadTime,
            scheduleNowRange,
            invalidFields,
            syncingCartItemId,
            shouldOfferTip,
            deliveryIntegration,
            widgetSettings,
            checkoutWithoutPayment,
            launchButtonRequired,
          }}
        />
      </ContentContainer>
    )
  }
}

const mapStateToProps = state => ({
  venue: state.venue,
  siteName: state.ordering.orderingSite.orderingSite.name,
  checkoutDetails: state.ordering.checkout,
  cartItems: selectCartWithItems(state),
  cartTotals: state.ordering.cart.cartTotals,
  customFields: selectCustomFields(state),
  isLoadingSync: state.ordering.cart.isLoadingSync,
  promoCode: state.ordering.cart.promoCode,
  promoCodeId: state.ordering.cart.promoCodeId,
  promoCodeErrors: state.ordering.cart.promoCodeErrors,
  payOptions: state.ordering.checkout.payOptions,
  paymentChannel: state.ordering.checkout.paymentChannel,
  paymentType: state.venue.paymentType,
  isOnlinePaymentEnabled: selectOnlinePaymentEnabled(state),
  allowPayLater: state.ordering.checkout.allowPayLater,
  showPromoCode: state.ordering.checkout.showPromoCode,
  showNoteToRestaurant: state.ordering.checkout.showNoteToRestaurant,
  pickupDeliverySelection: state.ordering.pickupDelivery.selection,
  isGuestWillPickUpRoomNumberFromList: state.ordering.orderingSite.orderingSite.isGuestWillPickUpRoomNumberFromList,
  orderingRoomNumbers: state.ordering.orderingSite.orderingRoomNumbers,
  deliveryAddress: state.ordering.pickupDelivery.deliveryAddress,
  deliveryAddressLine1: selectDeliveryAddressLine1(state),
  scheduleTimeDisplay: selectScheduleTimeDisplay(state),
  orderAheadTime: state.ordering.pickupDelivery.orderAheadTime,
  processingCheckout: state.ordering.checkout.processingCheckout,
  scheduleNowRange: selectScheduleNowRange(state),
  invalidFields: state.ordering.checkout.invalidFields,
  tipItems: selectTipItems(state),
  shouldOfferTip: selectShouldOfferTip(state),
  syncingCartItemId: state.ordering.cart.syncingCartItemId,
  deliveryIntegration: state.ordering.orderingSite.deliveryIntegration,
  widgetSettings: state.venue.widgetSettings,
  hideAlternateInterface: state.ordering.checkout.hideAlternateInterface,
  ageToConsent: state.ordering.checkout.ageToConsent,
  isPosTableMultiselect: state.ordering.orderingSite.orderingSite.isPosTableMultiselect,
  posTables: state.ordering.orderingSite.orderingSite.posTables,
  roomNumber: decodeURIComponent(state.router.location.query.room_number ?? ''),
})

const mapDispatchToProps = {
  showEditCartItem,
  removeCartItem,
  applyPromoCode,
  updatePromoCode,
  removePromoCode,
  updateCheckoutDetails,
  updateCustomFieldValue,
  toggleMarketingOptIn,
  initPayments,
  submitOrder,
  checkoutWithoutPayment,
  openPickupDeliveryModal: pickupDeliveryActions.openPickupDeliveryModal,
  toggleCartOnlyMode,
  paymentsLoaded,
  updateTip,
  gatewayException,
  showError,
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
