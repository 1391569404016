import _ from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import DropdownArrowsPicker, { ICONS } from 'mgr/lib/components/DropdownArrowsPicker'
import MenuTabs, { TABS_VARIATIONS } from 'widget/universal/components/ordering/MenuTabs'
import { MaxWidthContainer } from 'widget/universal/components/ordering/Responsive'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'
import { MenuCategory } from '@sevenrooms/ordering/components/MenuCategory'
import { VStack } from '@sevenrooms/core/ui-kit/layout'

const MenuContainer = styled.div`
  flex: 1;
  padding-bottom: ${props => props.theme.gutter.triple};

  & > div {
    padding-left: ${props => props.theme.gutter.triple};
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    overflow: unset;
  }
`

const CategoriesContainer = styled(VStack)`
  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    margin-top: -20px;
  }
`

const CategoriesTabs = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${props => props.theme.color.white};
`

const HideOnMobileStyles = css`
  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    display: none;
  }
`

const Delimiter = styled.div`
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};
  margin-left: ${props => props.theme.gutter.triple};

  ${HideOnMobileStyles}
`

const ResponsiveContainer = styled(MaxWidthContainer)`
  padding: 0 ${props => props.theme.gutter.triple} ${props => props.theme.gutter.triple} 0;
`

const MobileMenusContainer = styled.div`
  display: none;

  > div {
    padding-top: ${props => props.theme.gutter.double};
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    display: block;
  }
`
const CategoryMenusContainer = styled(MobileMenusContainer)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${props => props.theme.color.white};

  > div {
    padding-bottom: ${props => props.theme.gutter.double};
  }
`

const OrderingDropdown = ({ name, choices, value, onChangeHandler }) => (
  <DropdownArrowsPicker
    name={name}
    choices={choices}
    value={value}
    isLightTheme
    useOutsideLabel={false}
    disabled={false}
    wrapText={false}
    onChangeHandler={id => onChangeHandler(id)}
    height={46}
    optionsContainerStyle={{
      width: '100%',
    }}
    showCaret
    IconCaret={ICONS.fontAwesome}
    style={{
      width: '100%',
    }}
  />
)

const MenuItems = ({
  navMenus,
  updateSelectedMenu,
  updateSelectedCategory,
  activeMenuId,
  activeMenuNextAvailable,
  categories,
  cartItemsCountById,
  onItemClick,
  itemsById,
  activeCategoryId,
  venue,
  isCartOnlyMode,
  hideEightySixMenuItems,
}) => {
  const categoriesRef = useRef([])
  const categoryVisibilityMap = useMemo(() => {
    const visibilityMap = new Map()
    categories.forEach(category => visibilityMap.set(category.id, false))
    return visibilityMap
  }, [categories])

  // debounce could be removed, if move state from redux to local, redux state update with a lot of events is slow enough
  const updateWithDebounce = useMemo(
    () =>
      _.debounce(() => {
        const firstVisibleCategory = _.find(categories, category => categoryVisibilityMap.get(category.id))
        if (firstVisibleCategory && firstVisibleCategory.id !== activeCategoryId) {
          updateSelectedCategory(firstVisibleCategory.id)
        }
      }, 200),
    [activeCategoryId, categories, categoryVisibilityMap, updateSelectedCategory]
  )

  const onCategoryVisibilityChange = useCallback(
    (categoryId, inView) => {
      if (categoryVisibilityMap.get(categoryId) !== inView) {
        categoryVisibilityMap.set(categoryId, inView)
        updateWithDebounce()
      } else {
        categoryVisibilityMap.set(categoryId, inView)
      }
    },
    [categoryVisibilityMap, updateWithDebounce]
  )

  const onCategorySelect = useCallback(
    id => {
      const selectedCategoryIndex = _.findIndex(categories, category => category.id === id)
      categoriesRef.current[selectedCategoryIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    [categories]
  )
  return (
    <ResponsiveContainer>
      <MenuContainer>
        {navMenus.length > 1 && (
          <MenuTabs
            onTabClick={updateSelectedMenu}
            activeItemId={activeMenuId}
            items={navMenus}
            identifier="menu"
            customStyles={HideOnMobileStyles}
          />
        )}
        {categories.length > 0 && (
          <>
            <CategoriesTabs>
              <MenuTabs
                onTabClick={id => onCategorySelect(id)}
                activeItemId={activeCategoryId}
                items={categories}
                identifier="category"
                variation={TABS_VARIATIONS.open}
                customStyles={css`
                  padding-top: 0;
                  padding-bottom: 0;
                  margin-top: ${props => (navMenus.length > 1 ? 0 : props.theme.gutter.quadruple)};
                  ${HideOnMobileStyles}
                `}
              />
            </CategoriesTabs>
            <Delimiter />
          </>
        )}
        <MobileMenusContainer>
          {navMenus.length > 1 && (
            <div>
              <OrderingDropdown
                name="Menu"
                choices={navMenus.map(menu => ({
                  ...menu,
                  name: `${menu.name} (${menu.subHeaderText})`,
                  value: menu.id,
                }))}
                value={activeMenuId}
                onChangeHandler={id => updateSelectedMenu(id)}
              />
            </div>
          )}
        </MobileMenusContainer>
        <CategoryMenusContainer>
          {categories.length > 1 && (
            <div>
              <OrderingDropdown
                name="Category"
                choices={categories.map(cat => ({
                  ...cat,
                  value: cat.id,
                }))}
                value={activeCategoryId}
                onChangeHandler={id => onCategorySelect(id)}
              />
            </div>
          )}
        </CategoryMenusContainer>

        <CategoriesContainer>
          {categories.length > 0 &&
            categories.map((category, index) => (
              <MenuCategory
                onCategoryVisibilityChange={onCategoryVisibilityChange}
                categoryRef={el => {
                  // eslint-disable-next-line no-param-reassign
                  categoriesRef.current[index] = el
                }}
                key={category.id}
                testId={`category-${category.name}-${index}`}
                name={category.name}
                menuId={activeMenuId}
                items={category.items}
                onItemClick={onItemClick}
                itemsById={itemsById}
                cartItemsCountById={cartItemsCountById}
                categoryId={category.id}
                activeMenuNextAvailable={activeMenuNextAvailable}
                currencyCode={venue.currency}
                isCartOnlyMode={isCartOnlyMode}
                hideEightySixMenuItems={hideEightySixMenuItems}
              />
            ))}
        </CategoriesContainer>
      </MenuContainer>
    </ResponsiveContainer>
  )
}

export default MenuItems
