/* global PRELOADED */

import { routerMiddleware } from 'connected-react-router'
import React from 'react' // Required for ReactDOM despite being unused
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import history from 'mgr/pages/shared/utils/History'
import { camelCaseObject } from 'svr/common/ObjectUtils'
import { initializeOrderingReducers } from 'widget/universal/reducers/ordering'
import { initializeVenueReducer } from 'widget/universal/reducers/venue'
import { setDefaultTimezone } from 'widget/universal/selectors/common/today'
import App from './App'
import rootReducer from './reducers'

const venue = camelCaseObject(window.PRELOADED.base_venue)
const widgetSettings = camelCaseObject(window.PRELOADED.widget_settings)
setDefaultTimezone(venue.timezone)
const orderingBrandSettings = camelCaseObject(PRELOADED.ordering_brand_settings)

const store = createStore(
  enableBatching(rootReducer),
  {
    venue: initializeVenueReducer(venue, widgetSettings, orderingBrandSettings),
    ordering: initializeOrderingReducers(
      window.PRELOADED.menu_data,
      venue,
      JSON.parse(window.PRELOADED.ordering_site_json),
      JSON.parse(window.PRELOADED.checkout_settings_json),
      window.PRELOADED.delivery_integration,
      JSON.parse(window.PRELOADED.ordering_room_numbers)
    ),
    language: {
      languageStrings: camelCaseObject(window.PRELOADED.selected_language_strings),
    },
  },
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))
)

const AppWithStore = (
  <Provider store={store}>
    <AppContainer>
      <App widgetSettings={widgetSettings} venue={venue} />
    </AppContainer>
  </Provider>
)

ReactDOM.render(AppWithStore, document.getElementById('app'))

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(AppWithStore, document.getElementById('app'))
  })
}
