import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'svr/component-lib/Generic/Buttons/StandardButton'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'

const PromoCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.gutter.triple};
  padding-left: ${props => props.theme.gutter.triple};
  padding-right: ${props => props.theme.gutter.triple};
  padding-bottom: ${props => props.theme.gutter.medium};
  border-top: 1px solid ${props => props.theme.ordering.lightGrey};
`

const PromoHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const PromoLabel = styled.div`
  display: flex;
  flex-direction: row;
`

const PromoCodeRemove = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
  font-size: 14px;

  button {
    background: white;
    border: none;
    outline: none;
    box-shadow: none;
  }

  button:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const PromoCodeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding-top: ${props => props.theme.gutter.standard};
  padding-bottom: ${props => props.theme.gutter.standard};
`

const PromoCodeInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const PromoCodeApply = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 86px;
`

const PromoCodeError = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => props.theme.fontSize.small};
  font-size: ${props => props.theme.fontSize.small};
  color: ${props => props.theme.error};
  padding-bottom: ${props => props.theme.padding.xSmall};
`

const RemovePromoButton = styled.button`
  color: ${props => props.theme.ordering.fontsColorLinks};
`

const PromoCodeForm = ({ applyPromoCodeClick, promoCode, updatePromoCode, removePromoCode, promoCodeId, promoCodeErrors }) => (
  <PromoCodeContainer>
    <PromoHeader>
      <PromoLabel>Promo Code</PromoLabel>
      {promoCodeId && (
        <PromoCodeRemove>
          <RemovePromoButton type="button" onClick={removePromoCode} data-test="remove-promo-code-button">
            Remove
          </RemovePromoButton>
        </PromoCodeRemove>
      )}
    </PromoHeader>
    <PromoCodeInputWrapper>
      <PromoCodeInput>
        <TextInput
          inputId="promo-code-input"
          dataTest="promo-code-input"
          value={promoCode}
          disabled={promoCodeId}
          onChange={updatePromoCode}
          customInput={css`
            border-radius: 4px 0px 0px 4px;
            border-right: 0px;
            border-width: 1px;
            font-family: 'Inter', sans-serif;
          `}
          customInputWrapper={css`
            width: 100%;
          `}
        />
        {promoCodeId && (
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              color: '#219653',
              height: '36px',
              lineHeight: '36px',
              position: 'absolute',
              right: '12px',
            }}
          />
        )}
      </PromoCodeInput>
      {!promoCodeId && (
        <PromoCodeApply>
          <Button
            data-test="apply-promo-code-button"
            onClick={applyPromoCodeClick}
            customButton={css`
              font-family: 'Inter', sans-serif;
              height: 36px;
              min-width: 86px;
              line-height: 36px;
              background-color: ${props => props.theme.ordering.primary} !important;
              border-color: ${props => props.theme.ordering.primary} !important;
              color: #ffffff !important;
              font-weight: 400;
              border-radius: 0px 4px 4px 0px;
            `}
          >
            Apply
          </Button>
        </PromoCodeApply>
      )}
    </PromoCodeInputWrapper>
    <PromoCodeError data-test="promo_code_error">{promoCodeErrors}</PromoCodeError>
  </PromoCodeContainer>
)

export default PromoCodeForm
