// /* @flow */
import * as React from 'react'
import styled, { css } from 'styled-components'
import CheckboxSymbol from '../CheckboxSymbol'

/* type Props = {
  checkboxId?: string,
  field: string,
  onClick: (field: string) => mixed,
  label: string,
  isSelected: boolean,
  isDisabled: boolean,
  isValid: boolean,
  customCheckboxIcon: Array<string | (() => mixed)>,
  customCheckboxImg: Array<string | (() => mixed)>,
  customCheckboxWrapper: Array<string | (() => mixed)>,
  customCheckboxLabel: Array<string | (() => mixed)>,
} */

class CheckboxLine extends React.Component /* <Props> */ {
  static defaultProps = {
    isSelected: false,
    isDisabled: false,
    isValid: true,
    customCheckboxIcon: [],
    customCheckboxImg: [],
    customCheckboxWrapper: [],
    customCheckboxLabel: [],
  }

  checkboxId = this.props.checkboxId || `sr-checkbox-${this.props.field}`

  clickHandler = () => this.props.onClick(this.props.field)

  render() {
    return (
      <CheckboxWrapper customCheckboxWrapper={this.props.customCheckboxWrapper}>
        <CheckboxSymbol
          dataTest={this.props.dataTest}
          checkboxId={this.checkboxId}
          onClick={this.clickHandler}
          isSelected={this.props.isSelected}
          isDisabled={this.props.isDisabled}
          isValid={this.props.isValid}
          customCheckboxIcon={this.props.customCheckboxIcon}
          customCheckboxImg={this.props.customCheckboxImg}
        />
        <CheckboxLabel
          htmlFor={this.checkboxId}
          isDisabled={this.props.isDisabled}
          isValid={this.props.isValid}
          customCheckboxLabel={this.props.customCheckboxLabel}
        >
          {this.props.label}
        </CheckboxLabel>
      </CheckboxWrapper>
    )
  }
}

const CheckboxWrapper = styled.div`
  height: 30px;
  padding: 0 0 0 24px;
  position: relative;
  box-sizing: border-box;
  ${props => props.customCheckboxWrapper};
`

const CheckboxLabel = styled.label`
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  font-family: inherit;
  box-sizing: inherit;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  ${props =>
    !props.isValid &&
    css`
      color: ${props => props.theme.error};
    `}
  ${props => props.customCheckboxLabel};
`

export default CheckboxLine
