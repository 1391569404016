import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'svr/component-lib/Widget/Buttons/Ordering'
import {
  saveCartItem,
  prepareCartItem,
  showEditCartItem,
  removeCartItem,
  toggleCartOnlyMode,
  clickProceedToCheckout,
} from 'widget/universal/actions/ordering/cart'
import { updateSelectedMenu, updateSelectedCategory } from 'widget/universal/actions/ordering/menus'
import { showDetailedSiteInfo } from 'widget/universal/actions/ordering/orderingsite'
import * as pickupDeliveryActions from 'widget/universal/actions/ordering/pickupdelivery'
import Cart from 'widget/universal/components/ordering/Cart'
import { MenuContent } from 'widget/universal/components/ordering/MenuContent'
import { MobileCheckoutContainer } from 'widget/universal/components/ordering/Responsive'
import CartModals from 'widget/universal/containers/ordering/CartModals'
import { selectCartItemsQuantity, selectCartWithItems, selectTotalCartQuantity } from 'widget/universal/selectors/ordering/cart'
import { selectShouldOfferTip } from 'widget/universal/selectors/ordering/checkout'
import {
  selectScheduleTimeDisplay,
  selectOpenOrClosedText,
  selectRequiresPickupDeliverySelection,
  selectDeliveryAddressLine1,
  selectScheduleNowRange,
  selectNavMenus,
  selectActiveMenuNextAvailable,
} from 'widget/universal/selectors/ordering/schedules'
import { BREAKPOINTS, ON_PREMISE } from 'widget/universal/utils/ordering/constants'

const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    max-height: none;
    height: auto;
  }
`

const MobileActionButton = ({ totalCartQuantity, pickupDeliverySelection, onClick = () => {} }) => {
  const methodTitle = pickupDeliverySelection === ON_PREMISE ? '' : ` for ${pickupDeliverySelection.toLowerCase().replace('_', ' ')}`
  const content = `${totalCartQuantity} item${totalCartQuantity === 1 ? '' : 's'}${methodTitle}`

  return (
    <Button onClick={onClick} data-test="checkout-button-mobile">
      {content}
    </Button>
  )
}

class Menu extends PureComponent {
  render() {
    const {
      venue,
      categories = [],
      cartItems = [],
      navMenus = [],
      siteName = '',
      activeMenuId = '',
      activeMenuNextAvailable,
      isLoadingSync = false,
      itemsById = {},
      updateSelectedMenu = () => {},
      cartItemsCountById,
      cartTotals = {},
      prepareCartItem = () => {},
      showEditCartItem = () => {},
      removeCartItem = () => {},
      openPickupDeliveryModal = () => {},
      pickupDeliverySelection = '',
      isGuestWillPickUpRoomNumberFromList = false,
      deliveryAddressLine1 = '',
      scheduleTimeDisplay = 'Next Available',
      openOrClosedText = '',
      orderAheadTime = null,
      scheduleNowRange = '',
      widgetSettings = {},
      orderingBrandSettings = {},
      updateSelectedCategory,
      activeCategoryId,
      isCartOnlyMode = false,
      toggleCartOnlyMode = () => {},
      totalCartQuantity,
      requiresPickupDeliverySelection = false,
      syncingCartItemId,
      shouldOfferTip,
      deliveryIntegration = null,
      clickProceedToCheckout,
      showDetailedSiteInfo,
      hideEightySixMenuItems,
      bannerMessage,
    } = this.props

    const onItemClick = requiresPickupDeliverySelection ? openPickupDeliveryModal : prepareCartItem
    let imgSrc = null
    if (orderingBrandSettings.headerImg) {
      imgSrc = `/.h/download/${orderingBrandSettings.headerImg}`
    }
    return (
      <ContentContainer>
        <CartModals />
        <MenuContent
          {...{
            venue,
            categories,
            cartItemsCountById,
            navMenus,
            siteName,
            activeMenuId,
            activeCategoryId,
            activeMenuNextAvailable,
            itemsById,
            updateSelectedMenu,
            onItemClick,
            openPickupDeliveryModal,
            pickupDeliverySelection,
            scheduleTimeDisplay,
            openOrClosedText,
            imgSrc,
            widgetSettings,
            updateSelectedCategory,
            isCartOnlyMode,
            showDetailedSiteInfo,
            hideEightySixMenuItems,
            bannerMessage,
          }}
        />
        <Cart
          onEditItemClick={showEditCartItem}
          onRemoveItemClick={removeCartItem}
          items={cartItems}
          isLoading={isLoadingSync}
          isCartOnlyMode={isCartOnlyMode}
          toggleCartOnlyMode={toggleCartOnlyMode}
          action={() => clickProceedToCheckout(this.props.location.search)}
          actionText="Proceed to Checkout"
          {...{
            venue,
            siteName,
            cartTotals,
            openPickupDeliveryModal,
            pickupDeliverySelection,
            isGuestWillPickUpRoomNumberFromList,
            deliveryAddressLine1,
            scheduleTimeDisplay,
            orderAheadTime,
            scheduleNowRange,
            syncingCartItemId,
            shouldOfferTip,
            deliveryIntegration,
            widgetSettings,
          }}
        />

        {cartItems.length > 0 && (
          <MobileCheckoutContainer isCartOnlyMode={isCartOnlyMode}>
            <MobileActionButton
              totalCartQuantity={totalCartQuantity}
              pickupDeliverySelection={pickupDeliverySelection}
              onClick={toggleCartOnlyMode}
            />
          </MobileCheckoutContainer>
        )}
      </ContentContainer>
    )
  }
}

const mapStateToProps = state => {
  const { cart, menus, items, pickupDelivery, orderingSite } = state.ordering
  const { activeMenuId } = state.ordering.menus
  const { venue } = state

  return {
    venue,
    widgetSettings: venue.widgetSettings,
    orderingBrandSettings: venue.orderingBrandSettings,
    categories: !_.isEmpty(menus.byId) ? menus.byId[activeMenuId].hierarchy.categories : [],
    cartItems: selectCartWithItems(state),
    navMenus: selectNavMenus(state),
    activeMenuNextAvailable: selectActiveMenuNextAvailable(state),
    activeMenuId,
    activeCategoryId: state.ordering.menus.activeCategoryId,
    isLoadingSync: cart.isLoadingSync,
    cartTotals: cart.cartTotals,
    itemsById: items.byId,
    showItemModal: cart.showItemModal,
    isCartEditMode: cart.isCartEditMode,
    pickupDeliverySelection: pickupDelivery.selection,
    isGuestWillPickUpRoomNumberFromList: orderingSite.isGuestWillPickUpRoomNumberFromList,
    deliveryAddressLine1: selectDeliveryAddressLine1(state),
    scheduleTimeDisplay: selectScheduleTimeDisplay(state),
    isCartOnlyMode: cart.isCartOnlyMode,
    openOrClosedText: selectOpenOrClosedText(state),
    orderAheadTime: pickupDelivery.orderAheadTime,
    cartItemsCountById: selectCartItemsQuantity(state),
    totalCartQuantity: selectTotalCartQuantity(state),
    requiresPickupDeliverySelection: selectRequiresPickupDeliverySelection(state),
    scheduleNowRange: selectScheduleNowRange(state),
    syncingCartItemId: state.ordering.cart.syncingCartItemId,
    shouldOfferTip: selectShouldOfferTip(state),
    deliveryIntegration: orderingSite.deliveryIntegration,
    siteName: orderingSite.orderingSite.name,
    hideEightySixMenuItems: state.ordering.checkout.hideEightySixMenuItems,
    bannerMessage: orderingSite.orderingSite.bannerMessage,
  }
}

const mapDispatchToProps = {
  updateSelectedMenu,
  saveCartItem,
  prepareCartItem,
  showEditCartItem,
  removeCartItem,
  updateSelectedCategory,
  toggleCartOnlyMode,
  clickProceedToCheckout,
  showDetailedSiteInfo,
  openPickupDeliveryModal: pickupDeliveryActions.openPickupDeliveryModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu))
