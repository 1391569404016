/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { faInfoCircle, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'
import { StyledIcons, VmsIcons } from 'svr/common/VmsIcons'
import Checkbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import RadioButton from 'svr/component-lib/Generic/RadioButtons/RadioButton'
import { selectNestedModifiers } from 'widget/universal/selectors/ordering/cart'
import { FormatService } from '@sevenrooms/core/locales'
import { checkIsItemSnoozed } from '@sevenrooms/ordering/utils/menuItems'
import { Counter } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

const ModifierGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ModifierGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};
  padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.double} 0 ${props => props.theme.gutter.double};
`

const ModifierContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.gutter.double};
`

const ModifierGroupLabel = styled.div`
  display: flex;
  align-items: center;

  /* New Black */
  color: ${props => (props.isDisabled ? props.theme.darkGrey : '#1b1c1e')};
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gutter.double};

  & > div {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
  }
`

const IsRequiredLabel = styled.div`
  color: ${props => (props.isRequired ? '#1b1c1e' : props.theme.darkGrey)};
`

const AdditionalOptionsContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.medium};
  margin-top: ${props => props.theme.gutter.double};
  margin-bottom: ${props => props.theme.gutter.standard};
  border: 1px solid ${props => (props.isValid ? '#E0E0E0' : '#EB5757')};
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`

const Label = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.darkGrey};
  margin-bottom: ${props => props.theme.gutter.standard};
`

const SelectedModifier = styled.div`
  font-size: 14px;
  color: #1b1c1e;
`

const SelectedModifierContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gutter.standard};
  }
`

const OptionsLabelContainer = styled.div`
  flex: 1;
`

// !mod.modifierGroupId check for backward compatibility with items already added to the card, could be removed later
const findModifier = (modifiers, modifier, modifierGroup) =>
  _.find(modifiers, mod => mod.modifierId === modifier.id && (!mod.modifierGroupId || mod.modifierGroupId === modifierGroup.id))

const amountLimitsText = (minAmount, maxAmount) => {
  if (minAmount) {
    if (minAmount === maxAmount) {
      return `(Choose ${minAmount})`
    } else if (maxAmount) {
      return `(Choose ${minAmount} to ${maxAmount})`
    }
    return `(Choose minimum ${minAmount})`
  } else if (maxAmount) {
    return `(Up to ${maxAmount})`
  }
  return ''
}

const AdditionalOptions = ({
  modifierGroup,
  parentModifier,
  onSelectOption,
  modifiersById,
  modifiers,
  setIsValid,
  disabled,
  currencyCode,
}) => {
  const { minAmount, maxAmount } = modifierGroup
  const selectedModifiersById = _.reduce(
    modifiers,
    (accum, modifier) => {
      // eslint-disable-next-line no-param-reassign
      accum[modifier.modifierId] = modifier
      return accum
    },
    {}
  )
  const displayedModifiersWithInfo = modifierGroup.modifiers
    .map(modifier => ({ ...modifiersById[modifier.id], qty: selectedModifiersById[modifier.id]?.qty }))
    .filter(modifier => findModifier(modifiers, modifier, modifierGroup) && !checkIsItemSnoozed(modifier))
  const isValid = displayedModifiersWithInfo.length >= minAmount && (_.isNil(maxAmount) || displayedModifiersWithInfo.length <= maxAmount)

  return (
    <AdditionalOptionsContainer
      className={isValid ? '' : 'invalid-modifier'}
      isValid={isValid}
      onClick={() => {
        if (disabled) {
          return
        }
        onSelectOption(modifierGroup, parentModifier.id)
        setIsValid(document.querySelectorAll('.invalid-modifier') === 0)
      }}
    >
      <OptionsLabelContainer>
        <Label>{modifierGroup.name}</Label>
        <SelectedModifier>
          {displayedModifiersWithInfo.length === 0 ? (
            <>Selection {minAmount > 0 ? 'Required' : 'Optional'}</>
          ) : (
            displayedModifiersWithInfo.map(modifier => (
              <SelectedModifierContainer key={modifier.id}>
                <SelectedModifier>
                  {modifier.name}{' '}
                  {modifier.price > 0 && `(+ ${FormatService.formatCurrencyFromCents(modifier.price * modifier.qty, currencyCode)})`}
                </SelectedModifier>
              </SelectedModifierContainer>
            ))
          )}
        </SelectedModifier>
      </OptionsLabelContainer>
      <FontAwesomeIcon icon={faLongArrowAltRight} />
    </AdditionalOptionsContainer>
  )
}

const Required = styled.div`
  color: ${props => (props.isDisabled ? props.theme.darkGrey : props.isValid ? '#219653' : '#EB5757')};
`

const ModDetails = styled.div`
  display: flex;
  justify-content: space-between;
`

const Price = styled.div`
  color: #8a8a8a;
  font-size: 14px;
  min-width: min-content;
  white-space: nowrap;
  text-align: right;
  margin-left: ${props => props.theme.gutter.double};
`

const ModControl = styled.div`
  display: flex;
  word-break: break-all;
`

const RadioInputIcon = css`
  margin-right: ${props => props.theme.gutter.standard};
  min-width: 16px;
`

const RadioInputContainer = css`
  min-height: 21px;
  align-items: flex-start;
  font-size: 14px;
`

const CheckboxIconStyles = css`
  align-self: flex-start;
`

const TooltipIcon = styled(StyledIcons.XS)`
  color: #888c92;
  padding: 0 0 0 ${props => props.theme.gutter.standard};
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
`

const ModifierGroup = ({
  modifierGroup,
  onModifierClick,
  modifiers,
  modifiersById,
  modifierGroupsById,
  selectModifierGroup,
  currentNestedModifierIds,
  setIsValid,
  disabled,
  dataTestIndex,
  currencyCode,
}) => {
  const {
    allowOrderSameModifiers,
    id: modifierGroupId,
    name: modifierGroupName,
    minAmount,
    maxAmount,
    maxSameModifiersAmount,
  } = modifierGroup
  const isRequired = minAmount > 0
  const displayedModifiers = modifierGroup.modifiers.filter(modifier => !checkIsItemSnoozed(modifiersById[modifier.id]))
  const modifiersAtLevel = selectNestedModifiers(modifiers, currentNestedModifierIds)
  const numSelected = _.sum(displayedModifiers.map(mod => findModifier(modifiersAtLevel, mod, modifierGroup)?.qty || 0))
  const isMaxAmountUnlimited = maxAmount === 0 || _.isNil(maxAmount)
  const allModsMaxAmountReached = isMaxAmountUnlimited ? false : numSelected === maxAmount

  const isValid = numSelected >= minAmount && (isMaxAmountUnlimited || numSelected <= maxAmount)
  const isRadio = minAmount === 1 && maxAmount === 1

  return (
    <ModifierGroupContainer>
      <LabelContainer>
        <ModifierGroupLabel data-test="modifier-group-label" isDisabled={disabled}>
          {modifierGroupName} {amountLimitsText(minAmount, maxAmount)}
        </ModifierGroupLabel>
        <IsRequiredLabel isRequired={isRequired} isDisabled={disabled} data-test="modifier-group-is_required">
          {!isRequired ? (
            <div>OPTIONAL</div>
          ) : (
            <Required className={isValid ? '' : 'invalid-modifier'} isValid={isValid} isDisabled={disabled}>
              REQUIRED ({numSelected}/{minAmount})
            </Required>
          )}
        </IsRequiredLabel>
      </LabelContainer>

      {displayedModifiers.map((mod, index) => {
        const modifier = modifiersById[mod.id]
        const modifierData = findModifier(modifiersAtLevel, modifier, modifierGroup)
        const isSelected = !!modifierData
        const modifierId = modifier.id
        const dataTestId = `modifier-group-${dataTestIndex}-modifier-${index}`
        const modifierMaxAmount = (isMaxAmountUnlimited ? maxSameModifiersAmount : Math.min(maxSameModifiersAmount, maxAmount)) || undefined
        const qty = modifierData?.qty || 0

        return (
          <>
            <ReactTooltip id="modifierGroupTooltip" />
            <ModifierContainer key={`${modifier.id}-${index}`}>
              <ModDetails>
                <ModControl>
                  {allowOrderSameModifiers ? (
                    <HStack alignItems="center">
                      <Counter
                        value={qty}
                        onChange={value => {
                          onModifierClick(modifierId, modifierGroupId, currentNestedModifierIds, null, value)
                        }}
                        disabled={disabled}
                        max={allModsMaxAmountReached ? qty : modifierMaxAmount}
                        description={modifier.name}
                        data-test={dataTestId}
                      />
                      {modifier.description && (
                        <TooltipIcon data-for="modifierGroupTooltip" data-tip={modifier.description} isDisabled={disabled}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </TooltipIcon>
                      )}
                    </HStack>
                  ) : isRadio ? (
                    <RadioButton
                      name={modifierGroupName}
                      checked={isSelected}
                      value={modifierId}
                      data-test={dataTestId}
                      disabled={disabled}
                      onChange={() => onModifierClick(modifierId, modifierGroupId, currentNestedModifierIds, modifierGroup)}
                      customRadioIcon={RadioInputIcon}
                      containerStyles={RadioInputContainer}
                    >
                      {modifier.name}
                      {modifier.description && (
                        <TooltipIcon data-for="modifierGroupTooltip" data-tip={modifier.description} isDisabled={disabled}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </TooltipIcon>
                      )}
                    </RadioButton>
                  ) : (
                    <Checkbox
                      field={modifierId}
                      onClick={() => {
                        if (isSelected || !maxAmount || numSelected !== maxAmount) {
                          onModifierClick(modifierId, modifierGroupId, currentNestedModifierIds)
                          setIsValid(document.querySelectorAll('.invalid-modifier').length === 0)
                        }
                      }}
                      iconStyles={CheckboxIconStyles}
                      dataTest={dataTestId}
                      isSelected={isSelected}
                      isDisabled={disabled}
                      label={modifier.name}
                      tooltipText={modifier.description}
                      tooltipWidth="100px"
                      selectedIcon={VmsIcons.CheckBoxSelected}
                      unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                      tooltipIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                      labelStyles={
                        disabled
                          ? css`
                              color: #999;
                              font-size: 14px;
                            `
                          : css`
                              font-size: 14px;
                            `
                      }
                    />
                  )}
                </ModControl>
                {modifier.price > 0 && (
                  <Price data-test={`modifier-group-${dataTestIndex}-modifier-${index}-price`}>
                    (+ {FormatService.formatCurrencyFromCents(modifier.price, currencyCode)})
                  </Price>
                )}
              </ModDetails>
              {mod.modifier_groups.length > 0 &&
                isSelected &&
                mod.modifier_groups.map(nestedModifierGroup => (
                  <AdditionalOptions
                    key={nestedModifierGroup.id}
                    onSelectOption={selectModifierGroup}
                    modifiersById={modifiersById}
                    modifiers={
                      (
                        findModifier(modifiersAtLevel, mod, modifierGroup) || {
                          modifiers: [],
                        }
                      ).modifiers
                    }
                    modifierGroup={{
                      ...nestedModifierGroup,
                      ...modifierGroupsById[nestedModifierGroup.id],
                    }}
                    parentModifier={mod}
                    setIsValid={setIsValid}
                    disabled={disabled}
                    currencyCode={currencyCode}
                  />
                ))}
            </ModifierContainer>
          </>
        )
      })}
    </ModifierGroupContainer>
  )
}

const ModifierGroups = ({
  onModifierClick,
  modifiers = [],
  modifierGroupsById,
  modifiersById,
  selectModifierGroup,
  currentNestedModifierIds,
  currentModifierGroups = [],
  setIsValid,
  disabled,
  currencyCode,
}) => (
  <ModifierGroupsContainer>
    {currentModifierGroups.length > 0 &&
      currentModifierGroups.map(
        (modifierGroup, index) =>
          modifierGroupsById[modifierGroup.id] && (
            <ModifierGroup
              key={`${modifierGroup.id}-${index}`}
              modifierGroup={{
                ...modifierGroup,
                ...modifierGroupsById[modifierGroup.id],
              }}
              modifierGroupsById={modifierGroupsById}
              onModifierClick={onModifierClick}
              modifiers={modifiers}
              modifiersById={modifiersById}
              selectModifierGroup={selectModifierGroup}
              currentNestedModifierIds={currentNestedModifierIds}
              setIsValid={setIsValid}
              disabled={disabled}
              dataTestIndex={index}
              currencyCode={currencyCode}
            />
          )
      )}
  </ModifierGroupsContainer>
)

export default ModifierGroups
