import React from 'react'
import { CardInfoLine, CardHeading, OrderHelp, BorderContainer } from './styles'

const VenueDetails = ({ venueName, venueAddress, venueCityState, venuePostalCode, venuePhoneNumber }) => (
  <BorderContainer>
    <CardHeading>Restaurant Details</CardHeading>
    <CardInfoLine data-test="confirmation-delivery-venue_name">{venueName}</CardInfoLine>
    <CardInfoLine data-test="confirmation-delivery-venue_address">
      {venueAddress}, {venueCityState}, {venuePostalCode}
    </CardInfoLine>
    <OrderHelp>
      Need help with your order? Call {venueName} at {venuePhoneNumber}
    </OrderHelp>
  </BorderContainer>
)

export default VenueDetails
