import React from 'react'
import styled from 'styled-components'
import { LocationMark } from './MapLabels'

const Container = styled.div`
  cursor: pointer !important;
  background-color: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  margin-top: 32px;
  margin-right: 32px;
  padding: 5px 14px 5px 9px;
  z-index: 1;
`

const InnerText = styled.p`
  color: #0a0a0a;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  display: inline;
  vertical-align: middle;
  margin-left: 8px;
`

const GetDirectionControl = ({ venueGmapsLink }) => (
  <Container
    onClick={() => {
      window.open(venueGmapsLink, '_blank')
    }}
  >
    <LocationMark />
    <InnerText>Get Directions</InnerText>
  </Container>
)

export default GetDirectionControl
