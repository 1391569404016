import _ from 'lodash'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'
import { syncCart, removeCartItems, prepareCartItem } from 'widget/universal/actions/ordering/cart'
import { activateFirstAvailableMenu } from 'widget/universal/actions/ordering/menus'
import { getDeliverability } from 'widget/universal/actions/ordering/services'
import {
  selectNearestAvailableOrderAheadTime,
  selectDesiredTimestamp,
  selectTargetReadyTimestamp,
  selectEditOrSavedState,
  selectCartItemsUnavailable,
  selectOrderAheadDateOptions,
} from 'widget/universal/selectors/ordering/schedules'
import { addressPartsFromGooglePlace } from 'widget/universal/utils/convert'
import {
  DELIVERY,
  SCHEDULE_NOW,
  SCHEDULE_FUTURE,
  MODAL_STATE_DEFAULT,
  MODAL_STATE_SELECT_FUTURE_TIME,
} from 'widget/universal/utils/ordering/constants'

export const updateDeliveryAddress = (deliveryAddress, place) => dispatch => {
  dispatch({
    type: ActionTypes.PICKUP_DELIVERY_ADDRESS_CHANGED,
    deliveryAddress,
    place,
  })
  return dispatch(checkDeliverability())
}

const checkDeliverability = () => (dispatch, getState) => {
  const state = getState()
  const { selection, place } = selectEditOrSavedState(state)
  if (selection !== DELIVERY || _.isEmpty(place)) {
    return Promise.resolve()
  }
  if (requiresPostalCode(place) && !hasPostalCode(place)) {
    // eslint-disable-next-line no-console
    console.warn('Address is missing postal code')
    return dispatch(updateDeliveryAddressFail())
  }
  const addressParts = addressPartsFromGooglePlace(place)
  const desiredTimestamp = selectDesiredTimestamp(state)
  const targetReadyTimestamp = selectTargetReadyTimestamp(state)
  dispatch({
    type: ActionTypes.VALIDATE_DELIVERY_ADDRESS_START,
  })
  return getDeliverability({
    venueId: state.venue.id,
    placeId: place.place_id,
    desiredTimestamp,
    targetReadyTimestamp,
    ...addressParts,
  }).then(
    response => {
      const { data } = response
      if (data.is_deliverable) {
        dispatch({
          type: ActionTypes.VALIDATE_DELIVERY_ADDRESS_SUCCESS,
          drivingTimeSeconds: data.driving_time_seconds,
          distanceMeters: data.distance_meters,
          thirdPartyDeliveryFee: data.third_party_delivery_fee,
        })
        dispatch(refreshOrderAheadTime())
      } else {
        dispatch(updateDeliveryAddressFail())
      }
    },
    error => {
      console.error(error)
      dispatch(updateDeliveryAddressFail())
    }
  )
}

const requiresPostalCode = place => {
  const countryComponent = _.find(place.address_components, addr => _.includes(addr.types, 'country'))
  return countryComponent && COUNTRIES_REQUIRING_POSTAL_CODE.has(countryComponent.short_name)
}

// See ORDER-604
const COUNTRIES_REQUIRING_POSTAL_CODE = new Set([
  'AU',
  'AT',
  'BE',
  'BG',
  'CA',
  'IC',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EN',
  'EE',
  'FO',
  'FI',
  'FR',
  'GE',
  'DE',
  'GR',
  'GG',
  'HO',
  'HU',
  'IL',
  'IT',
  'JP',
  'JE',
  'KR',
  'LV',
  'LI',
  'LT',
  'LU',
  'ME',
  'NL',
  'NZ',
  'NB',
  'NO',
  'PL',
  'PT',
  'PR',
  'RU',
  'SA',
  'SF',
  'CS',
  'SK',
  'SI',
  'ZA',
  'ES',
  'SE',
  'CH',
  'GB',
  'US',
  'WL',
])

const hasPostalCode = place =>
  _.find(place.address_components, addr => _.includes(addr.types, 'postal_code') && !_.includes(addr.types, 'postal_code_prefix'))

const updateDeliveryAddressFail = () => dispatch => {
  dispatch({
    type: ActionTypes.VALIDATE_DELIVERY_ADDRESS_FAIL,
  })
  return dispatch(refreshOrderAheadTime())
}

export const refreshOrderAheadTime = () => (dispatch, getState) => {
  const nearestAvailableOrderAheadTime = selectNearestAvailableOrderAheadTime(getState())
  return dispatch(changeOrderAheadTime(nearestAvailableOrderAheadTime))
}

export const changePickupDeliverySelection = selection => dispatch => {
  dispatch({
    type: ActionTypes.PICKUP_DELIVERY_SELECTION_CHANGED,
    selection,
  })
  return dispatch(refreshOrderAheadTime())
}

export const updateAddressLine2 = addressLine2 => ({
  type: ActionTypes.PICKUP_DELIVERY_LINE2_CHANGED,
  addressLine2,
})

const changeScheduleType = scheduleType => ({
  type: ActionTypes.CHANGE_SCHEDULE_TYPE,
  scheduleType,
})

export const saveScheduleNow = () => dispatch => {
  dispatch(changeScheduleType(SCHEDULE_NOW))
  return dispatch(checkDeliverability())
}

export const saveScheduleFuture = () => dispatch => {
  dispatch(changeScheduleType(SCHEDULE_FUTURE))
  return dispatch(checkDeliverability())
}

export const selectScheduleFuture = () => (dispatch, getState) => {
  const state = getState()
  if (state.ordering.pickupDelivery.editState.orderAheadDate === null) {
    // Auto-select the first order ahead date option
    const orderAheadDateOptions = selectOrderAheadDateOptions(state)
    if (orderAheadDateOptions.length) {
      dispatch(changeOrderAheadDate(orderAheadDateOptions[0]))
    }
  }
  dispatch({
    type: ActionTypes.CHANGE_PICKUP_DELIVERY_MODAL_STATE,
    modalState: MODAL_STATE_SELECT_FUTURE_TIME,
  })
}

export const cancelScheduleFuture = () => ({
  type: ActionTypes.CHANGE_PICKUP_DELIVERY_MODAL_STATE,
  modalState: MODAL_STATE_DEFAULT,
})

export const changeOrderAheadDate = orderAheadDate => dispatch => {
  dispatch({
    type: ActionTypes.CHANGE_ORDER_AHEAD_DATE,
    orderAheadDate,
  })
  return dispatch(refreshOrderAheadTime())
}

export const changeOrderAheadTime = orderAheadTime => ({
  type: ActionTypes.CHANGE_ORDER_AHEAD_TIME,
  orderAheadTime,
})

export const openPickupDeliveryModal = (itemClicked = null, itemClickedMenuId = null) => ({
  type: ActionTypes.OPEN_PICKUP_DELIVERY_MODAL,
  itemClicked,
  itemClickedMenuId,
})

export const cancelPickupDeliveryModal = () => dispatch => {
  dispatch(showPendingItemClicked)
  return dispatch({
    type: ActionTypes.CANCEL_PICKUP_DELIVERY_MODAL,
  })
}

const saveRequiresUnavailableItemsWarning = state => _.some(selectCartItemsUnavailable(state))

const showUnavailableItemsWarning = () => (dispatch, getState) => {
  const state = getState()
  const unavailableItems = selectCartItemsUnavailable(state)
  dispatch({
    type: ActionTypes.SHOW_UNAVAILABLE_ITEMS_WARNING,
    unavailableItems,
  })
}

export const agreeUnavailableItemsWarning = () => (dispatch, getState) => {
  const state = getState()
  const unavailableItemIds = selectCartItemsUnavailable(state).map(i => i.id)
  dispatch(removeCartItems(unavailableItemIds))
  dispatch(hideUnavailableItemsWarning())
  return dispatch(commitSave())
}

const hideUnavailableItemsWarning = () => ({
  type: ActionTypes.HIDE_UNAVAILABLE_ITEMS_WARNING,
})

export const cancelUnavailableItemsWarning = hideUnavailableItemsWarning

const FIELDS_REQUIRING_MENU_ACTIVATION = ['scheduleType', 'orderAheadDate', 'orderAheadTime']

const editStateRequiresMenuActivation = pickupDelivery => {
  const beforeValues = _.pick(pickupDelivery, FIELDS_REQUIRING_MENU_ACTIVATION)
  const afterValues = _.pick(pickupDelivery.editState, FIELDS_REQUIRING_MENU_ACTIVATION)
  return !_.isEqual(beforeValues, afterValues)
}

export const savePickupDeliveryModal = () => (dispatch, getState) => {
  const state = getState()
  if (saveRequiresUnavailableItemsWarning(state)) {
    return dispatch(showUnavailableItemsWarning())
  }
  return dispatch(commitSave())
}

const commitSave = () => (dispatch, getState) => {
  const state = getState()
  if (editStateRequiresMenuActivation(state.ordering.pickupDelivery)) {
    dispatch(activateFirstAvailableMenu())
  }
  dispatch(showPendingItemClicked)
  dispatch({
    type: ActionTypes.SAVE_PICKUP_DELIVERY_MODAL,
  })
  return dispatch(syncCart())
}

const showPendingItemClicked = (dispatch, getState) => {
  const state = getState()
  const { itemClicked, itemClickedMenuId } = state.ordering.pickupDelivery
  if (_.isNil(itemClicked)) {
    return Promise.resolve()
  }
  return dispatch(prepareCartItem(itemClicked, itemClickedMenuId))
}
