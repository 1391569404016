import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Banner } from 'widget/universal/components/ordering/Banner'
import MenuItems from 'widget/universal/components/ordering/MenuItems'
import MenuVenueDetails from 'widget/universal/components/ordering/MenuVenueDetails'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'
import { Box } from '@sevenrooms/core/ui-kit/layout'

const NoMenusMessage = styled.div`
  text-align: center;
`

export const MenuContent = ({
  venue,
  categories,
  cartItemsCountById,
  navMenus,
  siteName,
  activeMenuId,
  activeCategoryId,
  activeMenuNextAvailable,
  itemsById,
  updateSelectedMenu,
  onItemClick,
  openPickupDeliveryModal,
  pickupDeliverySelection,
  scheduleTimeDisplay,
  openOrClosedText,
  imgSrc,
  updateSelectedCategory,
  isCartOnlyMode,
  showDetailedSiteInfo,
  hideEightySixMenuItems,
  bannerMessage,
}) => (
  <MenuScroll isCartOnlyMode={isCartOnlyMode}>
    <VenueMenuContainer>
      <VenueHeaderContainer>
        <HeaderImage imageUrl={imgSrc} />
        <MenuVenueDetails
          {...{
            venue,
            siteName,
            openOrClosedText,
            openPickupDeliveryModal,
            pickupDeliverySelection,
            scheduleTimeDisplay,
            showDetailedSiteInfo,
          }}
        />
      </VenueHeaderContainer>
      {!_.isEmpty(bannerMessage) && (
        <Box pt="m">
          <Banner text={bannerMessage} />
        </Box>
      )}
      <MenuItems
        navMenus={navMenus}
        updateSelectedMenu={updateSelectedMenu}
        updateSelectedCategory={updateSelectedCategory}
        activeMenuId={activeMenuId}
        activeMenuNextAvailable={activeMenuNextAvailable}
        categories={categories}
        cartItemsCountById={cartItemsCountById}
        onItemClick={onItemClick}
        itemsById={itemsById}
        activeCategoryId={activeCategoryId}
        venue={venue}
        isCartOnlyMode={isCartOnlyMode}
        hideEightySixMenuItems={hideEightySixMenuItems}
      />
      {navMenus.length === 0 && <NoMenusMessage>No Menus Available</NoMenusMessage>}
    </VenueMenuContainer>
  </MenuScroll>
)

MenuContent.propTypes = {
  venue: React.PropTypes.object.isRequired,
  categories: React.PropTypes.array.isRequired,
  navMenus: React.PropTypes.array.isRequired,
  siteName: React.PropTypes.string.isRequired,
  activeMenuId: React.PropTypes.string.isRequired,
  activeCategoryId: React.PropTypes.string.isRequired,
  activeMenuNextAvailable: React.PropTypes.object.isRequired,
  itemsById: React.PropTypes.object.isRequired,
  updateSelectedMenu: React.PropTypes.func.isRequired,
  onItemClick: React.PropTypes.func.isRequired,
  openPickupDeliveryModal: React.PropTypes.func.isRequired,
  pickupDeliverySelection: React.PropTypes.string.isRequired,
  scheduleTimeDisplay: React.PropTypes.string.isRequired,
  openOrClosedText: React.PropTypes.string.isRequired,
  imgSrc: React.PropTypes.string,
  updateSelectedCategory: React.PropTypes.func.isRequired,
  isCartOnlyMode: React.PropTypes.bool.isRequired,
  bannerMessage: React.PropTypes.string.isRequired,
}

const MenuScroll = styled.div`
  width: 100%;
  flex: 1;

  @media (min-width: ${BREAKPOINTS.maxMobile}px) {
    max-height: calc(100vh - 68px);
    overflow-y: auto;
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    ${props => props.isCartOnlyMode && 'display: none;'}
  }
`

const VenueMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: min-content;
`

const VenueHeaderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.ordering.lightGrey};
  margin-bottom: 54px;

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    margin-bottom: 0;
    border: none;
  }
`

const HeaderImage = styled.div`
  width: 100%;
  height: 250px;
  background-color: ${props => props.theme.ordering.imageScreenColor};
  background-position: 50% center;
  background-size: cover;
  background-image: url(${props => props.imageUrl});

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    height: 145px;
  }
`
