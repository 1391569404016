import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import * as cartActions from 'widget/universal/actions/ordering/cart'
import ItemModal from 'widget/universal/components/ordering/ItemModal'
import { selectCartItemWithModifiers } from 'widget/universal/selectors/ordering/items'
import { selectSelectedItemNextAvailable } from 'widget/universal/selectors/ordering/schedules'

const ItemModalContainer = ({
  venue,
  isCartEditMode,
  modifierGroupsById,
  modifiersById,
  selectedItemWithModifiers,
  saveCartItem,
  cancelAddCartItem,
  saveEditCartItem,
  toggleModifier,
  updateCurrentCartItem,
  currentModifierGroups,
  selectModifierGroup,
  selectPreviousModifierGroup,
  currentNestedModifierIds,
  setIsValid,
  isValid,
  selectedItemNextAvailable,
  allowSpecialInstructions,
}) => (
  <ItemModal
    onSave={isCartEditMode ? saveEditCartItem : saveCartItem}
    onCancel={cancelAddCartItem}
    updateCartItem={updateCurrentCartItem}
    item={selectedItemWithModifiers}
    onModifierClick={toggleModifier}
    {...{
      venue,
      isCartEditMode,
      modifierGroupsById,
      modifiersById,
      selectModifierGroup,
      currentModifierGroups,
      selectPreviousModifierGroup,
      currentNestedModifierIds,
      setIsValid,
      isValid,
      selectedItemNextAvailable,
      allowSpecialInstructions,
    }}
  />
)

const mapStateToProps = state => {
  const { cart, modifiers, checkout } = state.ordering

  return {
    venue: state.venue,
    isCartEditMode: cart.isCartEditMode,
    modifierGroupsById: modifiers.modifierGroupsById,
    modifiersById: modifiers.modifiersById,
    selectedItemWithModifiers: selectCartItemWithModifiers(state),
    currentModifierGroups: _.last(state.ordering.cart.currentModifierGroups),
    currentNestedModifierIds: cart.currentNestedModifierIds,
    isValid: cart.isValid,
    selectedItemNextAvailable: selectSelectedItemNextAvailable(state),
    allowSpecialInstructions: checkout.allowSpecialInstructions,
  }
}

const mapDispatchToProps = {
  saveCartItem: cartActions.saveCartItem,
  cancelAddCartItem: cartActions.cancelAddCartItem,
  saveEditCartItem: cartActions.saveEditCartItem,
  toggleModifier: cartActions.toggleModifier,
  updateCurrentCartItem: cartActions.updateCurrentCartItem,
  selectModifierGroup: cartActions.selectModifierGroup,
  selectPreviousModifierGroup: cartActions.selectPreviousModifierGroup,
  setIsValid: cartActions.setIsValid,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemModalContainer)
