import _ from 'lodash'
import { PAYMENT_CHANNELS } from 'svr/lib/Payments/Constants'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneNumberLocale: '',
  roomNumber: '',
  posTableId: null,
  marketingOptIn: true,
  verifyAge: false,
  ageVerified: false,
  defaultPaymentChannel: PAYMENT_CHANNELS.NEW_CREDIT_CARD,
  paymentChannel: PAYMENT_CHANNELS.NEW_CREDIT_CARD,
  trackingSlug: '',
  paymentsLoaded: false,
  tipPercentage: null,
  tipAmount: 0,
  gateway: null,
  specialInstructions: '',
  processingCheckout: false,
  payOptions: [],
  allowPayLater: false,
  allowPayLaterOnly: false,
  showPromoCode: false,
  showNoteToRestaurant: false,
  invalidFields: {},
  tipItems: [],
  customFields: {},
  showPrepTimeChangedModal: false,
  pendingPaymentState: null,
  hideAlternateInterface: false,
}

export const loadCheckoutSettings = checkoutSettingsData => {
  const {
    tip0,
    tip1,
    tip2,
    defaultTip,
    allowSpecialInstructions,
    allowPayLater,
    allowPayLaterOnly,
    hideEightySixMenuItems,
    ageToConsent,
    showNoteToRestaurant,
    showPromoCode,
  } = checkoutSettingsData.checkoutSettings

  const tipItems = [
    {
      id: tip0,
      name: `${tip0}%`,
    },
    {
      id: tip1,
      name: `${tip1}%`,
    },
    {
      id: tip2,
      name: `${tip2}%`,
    },
    {
      id: 'other',
      name: 'Other',
    },
  ]
  return {
    ...initialState,
    tipItems,
    tipPercentage: tipItems[defaultTip].id,
    allowSpecialInstructions,
    allowPayLater,
    allowPayLaterOnly,
    showNoteToRestaurant,
    showPromoCode,
    hideEightySixMenuItems,
    ageToConsent,
  }
}

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_CHECKOUT_DETAILS:
      return {
        ...state,
        [action.field]: action.value,
      }
    case ActionTypes.UPDATE_CUSTOM_FIELD_VALUE:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          [action.field]: action.value,
        },
      }
    case ActionTypes.TOGGLE_MARKETING_OPT_IN:
      return {
        ...state,
        marketingOptIn: !state.marketingOptIn,
      }
    case ActionTypes.UPDATE_TIP_VALUES:
      return {
        ...state,
        tipPercentage: action.tipPercentage,
        tipAmount: action.tipAmount,
      }
    case ActionTypes.INIT_PAYMENTS:
      return {
        ...state,
        gateway: action.gateway,
      }

    case ActionTypes.PAYMENTS_LOADED:
      let { options } = action
      if (state.allowPayLaterOnly) {
        options = [PAYMENT_CHANNELS.PAY_LATER]
      } else if (state.allowPayLater) {
        options = [PAYMENT_CHANNELS.PAY_LATER, ...options]
      }
      const defaultPaymentChannel = action.options.length ? action.options[0] : state.paymentChannel
      return {
        ...state,
        payOptions: options,
        defaultPaymentChannel,
        paymentChannel: defaultPaymentChannel,
        paymentsLoaded: true,
      }
    case ActionTypes.CART_SYNC_SUCCESSFUL: {
      const { tipPercentage, tipAmount, cartTotals } = action

      const updates = {
        tipPercentage: _.find(state.tipItems, { id: tipPercentage }) ? tipPercentage : 'other',
        tipAmount: parseFloat(tipAmount / 100).toFixed(2),
        verifyAge: cartTotals.verifyAge,
      }

      if (action.isInitialLoad) {
        updates.firstName = action.firstName || ''
        updates.lastName = action.lastName || ''
        updates.email = action.email || ''
        updates.phoneNumberLocale = action.phoneNumberLocale || ''
        updates.phoneNumber = action.phoneNumber || ''
        if (!_.isEmpty(action.customFields)) {
          updates.customFields = _.chain(action.customFields).keyBy('key').mapValues('value').value()
        }
      }

      if (!cartTotals.verifyAge) {
        updates.invalidFields = { ...state.invalidFields }
        delete updates.invalidFields.ageVerified
      }

      return {
        ...state,
        ...updates,
      }
    }
    case ActionTypes.TOGGLE_PROCESSING:
      let processingUpdate = !state.processingCheckout
      if (action.manual !== null) {
        processingUpdate = action.manual
      }
      return {
        ...state,
        processingCheckout: processingUpdate,
      }
    case ActionTypes.SHOW_PREP_TIME_CHANGED_MODAL:
      return {
        ...state,
        showPrepTimeChangedModal: true,
        pendingPaymentState: action.pendingPaymentState,
      }
    case ActionTypes.HIDE_PREP_TIME_CHANGED_MODAL:
      return {
        ...state,
        showPrepTimeChangedModal: false,
        pendingPaymentState: null,
      }
    case ActionTypes.SET_TRACKING_SLUG:
      return {
        ...state,
        trackingSlug: action.value,
      }
    case ActionTypes.OBSCURE_INTERFACE:
      return {
        ...state,
        hideAlternateInterface: action.value,
      }
    case ActionTypes.CHECKOUT_SUCCESS:
      return {
        ...state,
        paymentChannel: state.defaultPaymentChannel,
      }
    default:
      return state
  }
}

export default checkoutReducer
