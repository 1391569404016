import React from 'react'
import OrderInstructions from 'widget/universal/components/ordering/successPage/OrderInstructions'
import { CardInfoLine, CardHeading, BorderContainer } from './styles'

const ClientDetails = ({ firstName, lastName, email, phoneNumberFormatted, notesForRestaurant, showNoteToRestaurant }) => (
  <BorderContainer>
    <CardHeading>Your Details</CardHeading>
    <CardInfoLine data-test="confirmation-pickup-first_name-last_name">
      {firstName} {lastName}
    </CardInfoLine>
    <CardInfoLine data-test="confirmation-pickup-email">{email}</CardInfoLine>
    <CardInfoLine data-test="confirmation-phone_number">{phoneNumberFormatted}</CardInfoLine>
    {showNoteToRestaurant && (
      <OrderInstructions
        instructions={notesForRestaurant}
        label="Notes for restaurant:"
        dataTest="confirmation-pickup-notes_for_restaurant"
      />
    )}
  </BorderContainer>
)

export default ClientDetails
