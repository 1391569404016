import { UPDATE_SELECTED_MENU, UPDATE_SELECTED_CATEGORY } from 'widget/universal/actions/ordering/ActionTypes'
import { selectNavMenus } from 'widget/universal/selectors/ordering/schedules'

export const updateSelectedMenu = menuId => ({
  type: UPDATE_SELECTED_MENU,
  menuId,
})

export const updateSelectedCategory = categoryId => ({
  type: UPDATE_SELECTED_CATEGORY,
  categoryId,
})

export const activateFirstAvailableMenu = () => (dispatch, getState) => {
  const state = getState()
  const navMenus = selectNavMenus(state)
  return dispatch(updateSelectedMenu(navMenus.length && navMenus[0].id))
}
