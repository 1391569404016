import _ from 'lodash'
import moment from 'moment-timezone'
import history from 'mgr/pages/shared/utils/History'
import { parsePromoError } from 'svr/lib/Payments/Util'
import {
  CREATE_CART_ITEM,
  CANCEL_ADD_CART_ITEM,
  ADD_CART_ITEM_TO_CART,
  SHOW_EDIT_CART_ITEM,
  REMOVE_CART_ITEMS,
  SAVE_EDIT_CART_ITEM,
  CART_SYNC_SUCCESSFUL,
  CART_SYNC_FAIL,
  CART_SYNC_START,
  UPDATE_CURRENT_CART_ITEM,
  SELECT_MODIFIER_GROUP,
  SELECT_PREVIOUS_MODIFIER_GROUP,
  SET_IS_VALID,
  UPDATE_PROMO_CODE,
  REMOVE_PROMO_CODE,
  VERIFY_PROMO_CODE_SUCCESS,
  VERIFY_PROMO_CODE_FAILURE,
  TOGGLE_CART_ONLY_MODE,
  SET_TRACKING_SLUG,
} from 'widget/universal/actions/ordering/ActionTypes'
import { showError, handleError } from 'widget/universal/actions/ordering/errormodal'
import { activateFirstAvailableMenu } from 'widget/universal/actions/ordering/menus'
import { fetchCart, updateCart, verifyPromoCode, updateGateway } from 'widget/universal/actions/ordering/services'
import { venueToday } from 'widget/universal/selectors/common/today'
import { selectNestedModifiers, selectIsSubtotalAllowed } from 'widget/universal/selectors/ordering/cart'
import { selectShouldOfferTip } from 'widget/universal/selectors/ordering/checkout'
import { FormatService } from '@sevenrooms/core/locales'

export const prepareCartItem = (item, menuId) => dispatch => dispatch(createCartItem(item, menuId))

export const showEditCartItem = cartItemId => ({
  type: SHOW_EDIT_CART_ITEM,
  cartItemId,
})

const createCartItem = (item, menuId) => ({
  type: CREATE_CART_ITEM,
  item,
  menuId,
})

export const cancelAddCartItem = cartItemId => ({
  type: CANCEL_ADD_CART_ITEM,
  cartItemId,
})

const formatCartItems = cartItemsById =>
  Object.values(cartItemsById)
    .sort((a, b) => a.index - b.index)
    .map(cartItem => ({
      cartLineItemId: cartItem.id,
      menuId: cartItem.menuId,
      menuItemId: cartItem.itemId,
      qty: cartItem.qty,
      modifiers: cartItem.modifiers,
      specialInstructions: cartItem.specialInstructions,
    }))

export const syncCart =
  (errHandler = () => {}, cartItemId = null) =>
  (dispatch, getState) => {
    dispatch(cartSyncStart(cartItemId))
    const state = getState()
    const { cart, pickupDelivery, checkout } = state.ordering
    const { cartItemsById, lastOrderId, promoCodeId, cartTotals } = cart
    let { tipPercentage, tipAmount } = checkout
    const isOtherTip = tipPercentage === 'other'
    const shouldOfferTip = selectShouldOfferTip(state)
    if (shouldOfferTip) {
      tipPercentage = isOtherTip ? null : parseFloat(tipPercentage)
      tipAmount = Math.round(isOtherTip ? parseFloat(tipAmount) * 100 : parseFloat(tipPercentage) * cartTotals.subtotal)
    } else {
      tipPercentage = 0
      tipAmount = 0
    }
    const {
      selection,
      fulfillmentSelectionTimestamp,
      place,
      addressLine2,
      drivingTimeSeconds,
      distanceMeters,
      thirdPartyDeliveryFee,
      orderAheadDate,
      orderAheadTime,
    } = pickupDelivery
    const updateParams = {
      ...cartIdParams(state),
      cartItems: formatCartItems(cartItemsById),
      fulfillmentMethod: selection,
      googlePlace: place,
      cartId: cart.cartId,
      fulfillmentSelectionTimestamp,
      addressLine2,
      drivingTimeSeconds,
      distanceMeters,
      thirdPartyDeliveryFee,
      tipPercentage,
      tipAmount,
    }
    if (orderAheadDate && orderAheadTime) {
      updateParams.orderAheadDate = orderAheadDate.format('YYYY-MM-DD')
      updateParams.orderAheadTime = orderAheadTime.format('HH:mm')
    }
    if (lastOrderId) {
      updateParams.lastOrderId = lastOrderId
    }
    if (_.some(promoCodeId)) {
      updateParams.promoCodeId = promoCodeId
    }
    return updateCart(updateParams).then(
      response => {
        updateGateway(response.data.cartTotals, state.ordering.checkout.gateway)
        dispatch(revalidatePromo(response.data))
        dispatch(cartSyncSuccessful(response.data, state))
      },
      error => {
        dispatch(handleError(error, loadCart))
        dispatch(cartSyncFail())
        errHandler()
      }
    )
  }

export const loadCart =
  (isInitialLoad = false) =>
  (dispatch, getState) => {
    dispatch(cartSyncStart())
    const state = getState()

    return fetchCart(cartIdParams(state)).then(
      response => {
        updateGateway(response.data.cartTotals, state.ordering.checkout.gateway)
        dispatch(cartSyncSuccessful(response.data, state, isInitialLoad))
        if (isInitialLoad) {
          dispatch(activateFirstAvailableMenu())
        }
      },
      error => dispatch(cartSyncFail())
    )
  }

const cartIdParams = state => ({
  venueId: state.venue.id,
  orderSiteId: state.ordering.orderingSite.orderingSite.id,
})

const cartSyncStart = (cartItemId = null) => ({
  type: CART_SYNC_START,
  cartItemId,
})

const cartSyncFail = () => ({
  type: CART_SYNC_FAIL,
})

const revalidatePromo = data => (dispatch, getState) => {
  const state = getState()
  const { cart } = state.ordering
  const { currency } = state.venue

  if (data.cartTotals.subtotal < cart.promoCodeMin) {
    dispatch(removePromoCode())
    const amount = FormatService.formatCurrencyFromCents(cart.promoCodeMin, currency)
    const message = `Your promo code has been removed because the
      cart total has dropped below the minimum subtotal required of
      ${amount}. Re-apply the promo if the cart subtotal reaches that
      minimum level again.`
    dispatch(showError(message, false, 'Warning'))
  }
}

const cartSyncSuccessful = (data, state, isInitialLoad) => {
  const { orderAheadDate, orderAheadTime } = sanitizeOrderAheadDateAndTime(data, state)
  return {
    type: CART_SYNC_SUCCESSFUL,
    ...data,
    orderAheadDate,
    orderAheadTime,
    isInitialLoad,
    menuHierarchyItemsById: state.ordering.menus.menuHierarchyItemsById,
  }
}

const sanitizeOrderAheadDateAndTime = (data, state) => {
  let { orderAheadDate, orderAheadTime } = data
  if (!orderAheadDate || !orderAheadTime) {
    orderAheadDate = null
    orderAheadTime = null
  } else {
    orderAheadTime = moment(`${orderAheadDate} ${orderAheadTime}`)
    orderAheadDate = moment(orderAheadDate)
    const venueTodayMoment = venueToday(state.venue)
    if (orderAheadDate.isBefore(venueTodayMoment)) {
      orderAheadDate = null
      orderAheadTime = null
    }
  }
  return { orderAheadDate, orderAheadTime }
}

export const removeCartItems = cartItemIds => dispatch => {
  dispatch({
    type: REMOVE_CART_ITEMS,
    cartItemIds,
  })

  return dispatch(syncCart())
}
export const removeCartItem = cartItemId => removeCartItems([cartItemId])

export const saveCartItem = cartItemId => dispatch => {
  dispatch({
    type: ADD_CART_ITEM_TO_CART,
    cartItemId,
  })

  const errHandler = () => dispatch(removeCartItem(cartItemId))

  return dispatch(syncCart(errHandler, cartItemId))
}

export const setIsValid = isValid => ({
  type: SET_IS_VALID,
  isValid,
})

export const saveEditCartItem = cartItemId => dispatch => {
  dispatch({
    type: SAVE_EDIT_CART_ITEM,
    cartItemId,
  })

  return dispatch(syncCart(null, cartItemId))
}

export const updateCurrentCartItem = (field, value) => ({
  type: UPDATE_CURRENT_CART_ITEM,
  field,
  value,
})

export const toggleModifier =
  (modifierId, modifierGroupId, currentNestedModifierIds, modifierGroup = null, modifierCount) =>
  (dispatch, getState) => {
    const state = getState()
    const modifiers = [...state.ordering.cart.currentCartItem.modifiers]
    const modifierListToMutate = selectNestedModifiers(modifiers, currentNestedModifierIds)
    const selectedModifier = _.find(
      modifierListToMutate,
      mod => mod.modifierId === modifierId && (!mod.modifierGroupId || mod.modifierGroupId === modifierGroupId)
    )

    // if radio button (modifierGroup supplied), have to remove all siblings on selection
    const modifiersToRemove = modifierGroup ? modifierGroup.modifiers.map(mod => mod.id) : [modifierId]
    // !mod.modifierGroupId check for backward compatibility with items already added to the card, could be removed later
    const removedModifiers = _.remove(
      modifierListToMutate,
      mod => (!mod.modifierGroupId || mod.modifierGroupId === modifierGroupId) && modifiersToRemove.includes(mod.modifierId)
    )

    if (removedModifiers.length === 0 || modifierGroup || modifierCount > 0) {
      modifierListToMutate.push({ modifierId, modifierGroupId, qty: modifierCount || 1, modifiers: selectedModifier?.modifiers || [] })
    }

    dispatch(updateCurrentCartItem('modifiers', modifiers))
  }

export const selectModifierGroup = (modifierGroup, parentModifierId) => ({
  type: SELECT_MODIFIER_GROUP,
  modifierGroup,
  parentModifierId,
})

export const selectPreviousModifierGroup = modifierGroup => ({
  type: SELECT_PREVIOUS_MODIFIER_GROUP,
  modifierGroup,
})

export const applyPromoCode = () => (dispatch, getState) => {
  const state = getState()
  const { promoCode } = state.ordering.cart

  if (!promoCode) {
    return Promise.resolve()
  }
  return verifyPromoCode(state).then(
    response => {
      if (!response.errors) {
        dispatch(verifyPromoCodeSuccess(response))
        dispatch(syncCart())
      } else {
        dispatch(
          verifyPromoCodeFailure({
            ...response,
            errors: parsePromoError(response.errors),
          })
        )
      }
    },
    error =>
      error.json().then(error => {
        const errorResponse = {
          errors: parsePromoError(error.errors),
        }
        dispatch(verifyPromoCodeFailure(errorResponse))
      })
  )
}

export const updatePromoCode = value => ({
  type: UPDATE_PROMO_CODE,
  value,
})

export const removePromoCode = value => dispatch => {
  dispatch({
    type: REMOVE_PROMO_CODE,
    value,
  })
  dispatch(syncCart())
}

export const verifyPromoCodeSuccess = response => ({
  type: VERIFY_PROMO_CODE_SUCCESS,
  response,
})

export const verifyPromoCodeFailure = response => ({
  type: VERIFY_PROMO_CODE_FAILURE,
  response,
})

export const toggleCartOnlyMode = () => ({
  type: TOGGLE_CART_ONLY_MODE,
})

export const setTrackingSlug = value => ({
  type: SET_TRACKING_SLUG,
  value,
})
export const clickProceedToCheckout = queryString => (dispatch, getState) => {
  const state = getState()
  const isSubtotalAllowed = selectIsSubtotalAllowed(state)
  if (isSubtotalAllowed.valid) {
    history.push(`checkout/${queryString}`)
  } else {
    dispatch(showError(isSubtotalAllowed.message))
  }
}
