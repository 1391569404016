import _ from 'lodash'
import { useVisibleChildrenCount } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ProductTag } from './ProductTag'

interface ProductTagsProps {
  tags: string[]
  isDisabled?: boolean
  limitOnWidth?: boolean
}

export function ProductTags({ tags, isDisabled = false, limitOnWidth = false }: ProductTagsProps) {
  const [displayedTagsCount, productTagsRef, tagsContainerRef, moreTagsRef] = useVisibleChildrenCount(limitOnWidth, tags)

  return !_.isEmpty(tags) ? (
    <HStack ref={productTagsRef} justifyContent="start" alignItems="center" spacing="xs">
      <HStack ref={tagsContainerRef} flexWrap={limitOnWidth ? 'nowrap' : 'wrap'} spacing="xs">
        {tags.map(tag => (
          <ProductTag key={tag} content={tag} isDisabled={isDisabled} limitOnWidth={limitOnWidth} />
        ))}
      </HStack>
      {limitOnWidth && (
        <Box ref={moreTagsRef} height="20px" minWidth="50px">
          <Text color={isDisabled ? 'inactive' : 'secondaryFont'} fontSize="xs" textTransform="uppercase" fontWeight="bold">
            +{tags.length - displayedTagsCount} MORE
          </Text>
        </Box>
      )}
    </HStack>
  ) : null
}
