import _ from 'lodash'
import moment from 'moment-timezone'
import { FULFILLMENT_METHOD } from 'svr/constants'
import * as ActionTypes from 'widget/universal/actions/ordering/ActionTypes'
import { SCHEDULE_FUTURE, SCHEDULE_NOW, MODAL_STATE_DEFAULT } from 'widget/universal/utils/ordering/constants'

const initialAddressState = {
  deliveryAddress: '',
  place: {},
  addressLine2: '',
  isAddressValid: false,
  drivingTimeSeconds: 0,
  distanceMeters: 0,
  thirdPartyDeliveryFee: 0.0,
  isAddressValidating: false,
}

const initialState = {
  editState: {},
  showModal: false,
  modalState: MODAL_STATE_DEFAULT,
  selection: FULFILLMENT_METHOD.PICKUP,
  orderingSiteFulfillmentMethod: FULFILLMENT_METHOD.PICKUP,
  ...initialAddressState,
  scheduleType: SCHEDULE_NOW,
  orderAheadDate: null,
  orderAheadTime: null,
  fulfillmentSelectionTimestamp: null,
  throttledTimeKeys: {},
  showUnavailableItemsWarning: false,
  unavailableItems: [],
  itemClicked: null,
  itemClickedMenuId: null,
}

const defaultSelection = orderingSiteFulfillmentMethod =>
  orderingSiteFulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_DELIVERY ||
  orderingSiteFulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE
    ? FULFILLMENT_METHOD.PICKUP
    : orderingSiteFulfillmentMethod

/* eslint-disable camelcase */
export const formatInitialPickupDeliveryState = ordering_site => ({
  ...initialState,
  selection: defaultSelection(ordering_site.fulfillment_method),
  orderingSiteFulfillmentMethod: ordering_site.fulfillment_method,
})
/* eslint-enable camelcase */

const buildEditState = state =>
  _.pick(state, [
    'modalState',
    'selection',
    'deliveryAddress',
    'place',
    'addressLine2',
    'isAddressValid',
    'isAddressValidating',
    'drivingTimeSeconds',
    'distanceMeters',
    'thirdPartyDeliveryFee',
    'scheduleType',
    'orderAheadDate',
    'orderAheadTime',
  ])

const pickupdelivery = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_PICKUP_DELIVERY_MODAL: {
      return {
        ...state,
        showModal: true,
        editState: buildEditState(state),
        itemClicked: action.itemClicked,
        itemClickedMenuId: action.itemClickedMenuId,
      }
    }
    case ActionTypes.CANCEL_PICKUP_DELIVERY_MODAL: {
      return {
        ...state,
        fulfillmentSelectionTimestamp: moment(),
        editState: {},
        showModal: false,
        itemClicked: null,
        itemClickedMenuId: null,
      }
    }
    case ActionTypes.SAVE_PICKUP_DELIVERY_MODAL: {
      const clearAddressFields = state.editState.selection === FULFILLMENT_METHOD.PICKUP && initialAddressState
      return {
        ...state,
        ...state.editState,
        ...clearAddressFields,
        fulfillmentSelectionTimestamp: moment(),
        editState: {},
        showModal: false,
        itemClicked: null,
        itemClickedMenuId: null,
      }
    }
    case ActionTypes.PICKUP_DELIVERY_SELECTION_CHANGED: {
      const { selection } = action
      return {
        ...state,
        editState: {
          ...state.editState,
          selection,
        },
      }
    }
    case ActionTypes.PICKUP_DELIVERY_ADDRESS_CHANGED: {
      const { deliveryAddress, place } = action
      return {
        ...state,
        editState: {
          ...state.editState,
          deliveryAddress,
          place,
        },
      }
    }
    case ActionTypes.PICKUP_DELIVERY_LINE2_CHANGED: {
      const { addressLine2 } = action
      return {
        ...state,
        editState: {
          ...state.editState,
          addressLine2,
        },
      }
    }
    case ActionTypes.VALIDATE_DELIVERY_ADDRESS_START: {
      return {
        ...state,
        editState: {
          ...state.editState,
          isAddressValid: false,
          isAddressValidating: true,
        },
      }
    }
    case ActionTypes.VALIDATE_DELIVERY_ADDRESS_SUCCESS: {
      const { drivingTimeSeconds, distanceMeters, thirdPartyDeliveryFee } = action
      return {
        ...state,
        editState: {
          ...state.editState,
          isAddressValid: true,
          isAddressValidating: false,
          drivingTimeSeconds,
          distanceMeters,
          thirdPartyDeliveryFee,
        },
      }
    }
    case ActionTypes.VALIDATE_DELIVERY_ADDRESS_FAIL: {
      return {
        ...state,
        editState: {
          ...state.editState,
          isAddressValid: false,
          isAddressValidating: false,
          drivingTimeSeconds: initialState.drivingTimeSeconds,
          distanceMeters: initialState.distanceMeters,
          thirdPartyDeliveryFee: initialState.thirdPartyDeliveryFee,
        },
      }
    }
    case ActionTypes.CHANGE_SCHEDULE_TYPE: {
      const { scheduleType } = action
      const editState = {
        ...state.editState,
        scheduleType,
        modalState: MODAL_STATE_DEFAULT,
      }
      if (scheduleType === SCHEDULE_NOW) {
        editState.orderAheadDate = null
        editState.orderAheadTime = null
      }
      return {
        ...state,
        editState,
        modalState: MODAL_STATE_DEFAULT,
      }
    }
    case ActionTypes.CHANGE_ORDER_AHEAD_DATE: {
      const { orderAheadDate } = action
      return {
        ...state,
        editState: {
          ...state.editState,
          orderAheadDate,
        },
      }
    }
    case ActionTypes.CHANGE_ORDER_AHEAD_TIME: {
      const { orderAheadTime } = action
      const editState = {
        ...state.editState,
        orderAheadTime,
      }
      if (_.isNil(orderAheadTime)) {
        editState.scheduleType = SCHEDULE_NOW
      }
      return {
        ...state,
        editState,
      }
    }
    case ActionTypes.CHANGE_PICKUP_DELIVERY_MODAL_STATE: {
      const { modalState } = action
      return {
        ...state,
        editState: {
          ...state.editState,
          modalState,
        },
      }
    }
    case ActionTypes.CART_SYNC_SUCCESSFUL: {
      const {
        orderAheadDate,
        orderAheadTime,
        fulfillmentMethod,
        googlePlace,
        addressLine2,
        drivingTimeSeconds,
        distanceMeters,
        thirdPartyDeliveryFee,
        fulfillmentSelectionTimestamp,
        throttledTimeKeys,
      } = action
      const { orderingSiteFulfillmentMethod } = state
      const hasOrderAheadDateTime = orderAheadDate && orderAheadTime
      // Defend against ordering site configuration changes
      const isCartFulfillmentMethodSafe =
        fulfillmentMethod &&
        (orderingSiteFulfillmentMethod === fulfillmentMethod ||
          orderingSiteFulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_DELIVERY ||
          orderingSiteFulfillmentMethod === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE)
      return {
        ...state,
        selection: isCartFulfillmentMethodSafe ? fulfillmentMethod : defaultSelection(orderingSiteFulfillmentMethod),
        scheduleType: hasOrderAheadDateTime ? SCHEDULE_FUTURE : initialState.scheduleType,
        orderAheadDate: hasOrderAheadDateTime ? orderAheadDate : initialState.orderAheadDate,
        orderAheadTime: hasOrderAheadDateTime ? orderAheadTime : initialState.orderAheadTime,
        place: googlePlace || initialState.place,
        deliveryAddress: _.get(googlePlace, 'formatted_address', initialState.deliveryAddress),
        addressLine2: addressLine2 || initialState.addressLine2,
        drivingTimeSeconds: drivingTimeSeconds || initialState.drivingTimeSeconds,
        distanceMeters: distanceMeters || initialState.distanceMeters,
        thirdPartyDeliveryFee: thirdPartyDeliveryFee || initialState.thirdPartyDeliveryFee,
        isAddressValid: !_.isEmpty(googlePlace),
        fulfillmentSelectionTimestamp,
        /* eslint-disable no-param-reassign */
        throttledTimeKeys: Object.entries(throttledTimeKeys).reduce((acc, [orderMethod, keys]) => {
          acc[orderMethod] = new Set(keys)
          return acc
        }, {}),
        /* eslint-enable no-param-reassign */
      }
    }
    case ActionTypes.SHOW_UNAVAILABLE_ITEMS_WARNING: {
      const { unavailableItems } = action
      return {
        ...state,
        showUnavailableItemsWarning: true,
        unavailableItems,
      }
    }
    case ActionTypes.HIDE_UNAVAILABLE_ITEMS_WARNING: {
      return {
        ...state,
        showUnavailableItemsWarning: false,
      }
    }
    default:
      return state
  }
}

export default pickupdelivery
