import React from 'react'
import OrderInstructions from 'widget/universal/components/ordering/successPage/OrderInstructions'
import VenueAddress from 'widget/universal/components/ordering/successPage/VenueAddress'
import { CardInfoLine, CardHeading, OrderHelp, BorderContainer } from './styles'

const PickupDetails = ({ venueName, venueAddress, venueCityState, venuePostalCode, venuePhoneNumber, instructions, venueGmapsLink }) => (
  <BorderContainer>
    <CardHeading>Pickup Details</CardHeading>
    <CardInfoLine data-test="confirmation-pickup-venue_name">{venueName}</CardInfoLine>
    <VenueAddress
      venueAddress={venueAddress}
      venueCityState={venueCityState}
      venuePostalCode={venuePostalCode}
      venueGmapsLink={venueGmapsLink}
      dataTest="confirmation-pickup-venue_address"
    />
    <OrderInstructions instructions={instructions} dataTest="confirmation-pickup-pickup_instructions" />
    <OrderHelp>
      Need help with your order? Call {venueName} at {venuePhoneNumber}
    </OrderHelp>
  </BorderContainer>
)

export default PickupDetails
