export const UPDATE_SELECTED_MENU = 'UPDATE_SELECTED_MENU'

export const CREATE_CART_ITEM = 'CREATE_CART_ITEM'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const ADD_CART_ITEM_TO_CART = 'ADD_CART_ITEM_TO_CART'
export const CANCEL_ADD_CART_ITEM = 'CANCEL_ADD_CART_ITEM'
export const SHOW_EDIT_CART_ITEM = 'SHOW_EDIT_CART_ITEM'
export const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS'
export const SAVE_EDIT_CART_ITEM = 'SAVE_EDIT_CART_ITEM'
export const CART_SYNC_START = 'CART_SYNC_START'
export const CART_SYNC_SUCCESSFUL = 'CART_SYNC_SUCCESSFUL'
export const CART_SYNC_FAIL = 'CART_SYNC_FAIL'
export const UPDATE_CURRENT_CART_ITEM = 'UPDATE_CURRENT_CART_ITEM'

export const UPDATE_CHECKOUT_DETAILS = 'UPDATE_CHECKOUT_DETAILS'
export const UPDATE_CUSTOM_FIELD_VALUE = 'UPDATE_CUSTOM_FIELD_VALUE'
export const TOGGLE_MARKETING_OPT_IN = 'TOGGLE_MARKETING_OPT_IN'

export const INIT_PAYMENTS = 'INIT_PAYMENTS'
export const PAYMENTS_LOADED = 'PAYMENTS_LOADED'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'

export const OPEN_PICKUP_DELIVERY_MODAL = 'OPEN_PICKUP_DELIVERY_MODAL'
export const CANCEL_PICKUP_DELIVERY_MODAL = 'CANCEL_PICKUP_DELIVERY_MODAL'
export const SAVE_PICKUP_DELIVERY_MODAL = 'SAVE_PICKUP_DELIVERY_MODAL'

export const PICKUP_DELIVERY_SELECTION_CHANGED = 'PICKUP_DELIVERY_SELECTION_CHANGED'
export const PICKUP_DELIVERY_ADDRESS_CHANGED = 'PICKUP_DELIVERY_ADDRESS_CHANGED'
export const PICKUP_DELIVERY_LINE2_CHANGED = 'PICKUP_DELIVERY_LINE2_CHANGED'
export const CHANGE_SCHEDULE_TYPE = 'CHANGE_SCHEDULE_TYPE'
export const VALIDATE_DELIVERY_ADDRESS_START = 'VALIDATE_DELIVERY_ADDRESS_START'
export const VALIDATE_DELIVERY_ADDRESS_SUCCESS = 'VALIDATE_DELIVERY_ADDRESS_SUCCESS'
export const VALIDATE_DELIVERY_ADDRESS_FAIL = 'VALIDATE_DELIVERY_ADDRESS_FAIL'
export const SHOW_UNAVAILABLE_ITEMS_WARNING = 'SHOW_UNAVAILABLE_ITEMS_WARNING'
export const HIDE_UNAVAILABLE_ITEMS_WARNING = 'HIDE_UNAVAILABLE_ITEMS_WARNING'

export const SELECT_MODIFIER_GROUP = 'SELECT_MODIFIER_GROUP'
export const SELECT_PREVIOUS_MODIFIER_GROUP = 'SELECT_PREVIOUS_MODIFIER_GROUP'
export const SET_IS_VALID = 'SET_IS_VALID'

export const UPDATE_SELECTED_CATEGORY = 'UPDATE_SELECTED_CATEGORY'

export const CHANGE_PICKUP_DELIVERY_MODAL_STATE = 'CHANGE_PICKUP_DELIVERY_MODAL_STATE'
export const CHANGE_ORDER_AHEAD_DATE = 'CHANGE_ORDER_AHEAD_DATE'
export const CHANGE_ORDER_AHEAD_TIME = 'CHANGE_ORDER_AHEAD_TIME'
export const UPDATE_PROMO_CODE = 'UPDATE_PROMO_CODE'
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE'
export const VERIFY_PROMO_CODE_SUCCESS = 'VERIFY_PROMO_CODE_SUCCESS'
export const VERIFY_PROMO_CODE_FAILURE = 'VERIFY_PROMO_CODE_FAILURE'
export const TOGGLE_PROCESSING = 'TOGGLE_PROCESSING'
export const SHOW_PREP_TIME_CHANGED_MODAL = 'SHOW_PREP_TIME_CHANGED_MODAL'
export const HIDE_PREP_TIME_CHANGED_MODAL = 'HIDE_PREP_TIME_CHANGED_MODAL'

export const TOGGLE_CART_ONLY_MODE = 'TOGGLE_CART_ONLY_MODE'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE '
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE '

export const SUCCESS_PAGE_FETCH_SUCCESSFUL = 'SUCCESS_PAGE_FETCH_SUCCESSFUL'
export const SUCCESS_PAGE_FETCH_FAIL = 'SUCCESS_PAGE_FETCH_FAIL'

export const UPDATE_TIP_VALUES = 'UPDATE_TIP_VALUES'
export const SUCCESS_PAGE_START = 'SUCCESS_PAGE_START'

export const SET_TRACKING_SLUG = 'SET_TRACKING_SLUG'

export const OBSCURE_INTERFACE = 'OBSCURE_INTERFACE'

export const SHOW_DETAILED_SITE_INFO = 'SHOW_DETAILED_SITE_INFO'
export const CLOSE_DETAILED_SITE_INFO = 'CLOSE_DETAILED_SITE_INFO'
