import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled, { css } from 'styled-components'

const TabsContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.triple} 0;
  ${props => props.customStyles};
`

const TabContainerBase = styled.button`
  background-color: ${props => props.theme.background};
  display: flex;
  flex-direction: column;
  outline: ${props => props.theme.ordering.formOutline};
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  min-width: 64px;
  min-height: 55px;
  position: relative;
  justify-content: center;
  align-items: center;
`

const TabContainerDefault = styled(TabContainerBase)`
  border-radius: 4px;
  padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.triple};
  border: none;
  font-weight: 500;

  &:not(:last-child) {
    margin-right: ${props => props.theme.gutter.double};
  }

  &:hover {
    opacity: 0.7;
    background: ${props => (props.hasColor ? props.theme.ordering.primary : props.theme.ordering.lightGrey)};

    div {
      color: white;
    }
  }

  ${props =>
    props.isActive &&
    css`
      div {
        ${props => props.hasColor && 'color: white;'}
      }

      background: ${props => (props.hasColor ? props.theme.ordering.primary : 'rgba(33, 63, 99, 0.1)')};
      border: 1px solid #213f63;
      ${props => props.hasColor && 'border: none;'}
    `};
`

const TabContainerOpen = styled(TabContainerBase)`
  border: none;
  border-radius: 2px;
  font-weight: 500;
  font-size: 16px;
  padding: ${props => props.theme.gutter.medium};
  max-width: 120px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-bottom: 2px solid transparent;

  ${props =>
    props.isActive &&
    css`
      border-color: #213f63;
    `};
`

const TabHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 96px;
  align-items: center;
`

const IconWrapper = styled.div`
  margin-left: ${props => props.theme.gutter.standard};
  svg {
    transform: scale(0.8);
  }
`

const TabSubHeader = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-top: ${props => props.theme.gutter.standard};
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.darkGrey};
`

export const TABS_VARIATIONS = {
  default: 'default',
  open: 'open',
}

const VariationWrapper = ({ variation, children, ...props }) =>
  variation === TABS_VARIATIONS.default ? (
    <TabContainerDefault {...props}>{children}</TabContainerDefault>
  ) : (
    <TabContainerOpen {...props}>{children}</TabContainerOpen>
  )

const Select = styled.select`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const MoreContainer = styled.div`
  cursor: pointer;
`

const SelectMoreTab = ({ items, onSelect, activeItemId, text, variation, isActive }) => (
  <VariationWrapper variation={variation} isActive={isActive} onClick={() => {}} data-test="" hasColor={false}>
    <MoreContainer>
      <TabHeader>
        {text}
        <IconWrapper>
          <FontAwesomeIcon icon={faChevronDown} />
        </IconWrapper>
      </TabHeader>

      <Select name="sr-select-more-items" onChange={onSelect} value={isActive ? activeItemId : 'default'} data-test="sr-select-more-items">
        <option value="default" disabled hidden />
        {items.map(item => (
          <option value={item.id} key={item.id} data-test={`sr-select-item-${item.id}`}>
            {item.name}
          </option>
        ))}
      </Select>
    </MoreContainer>
  </VariationWrapper>
)

const TabItem = ({ headerText, subHeaderText, onTabClick, isActive, testId, variation, hasColor }) => (
  <VariationWrapper variation={variation} isActive={isActive} onClick={onTabClick} data-test={testId} hasColor={hasColor}>
    <>
      <TabHeader>{headerText}</TabHeader>
      {subHeaderText && <TabSubHeader>{subHeaderText}</TabSubHeader>}
    </>
  </VariationWrapper>
)

const CheckoutTipTabs = ({
  items,
  onTabClick,
  activeItemId,
  identifier,
  customStyles,
  headerText,
  variation = TABS_VARIATIONS.default,
  hasColor = false,
}) => {
  const MAX_VISIBLE_ITEMS = 6
  const shouldShorten = items.length > MAX_VISIBLE_ITEMS + 1
  const visibleItems = shouldShorten ? items.slice(0, MAX_VISIBLE_ITEMS) : items
  const moreItems = shouldShorten ? items.slice(MAX_VISIBLE_ITEMS) : []
  const selectedMoreItem = moreItems.find(item => item.id === activeItemId)

  return (
    <TabsContainer customStyles={customStyles}>
      {visibleItems.map(item => {
        const itemId = item.id
        const key = `tab-item-${identifier}-${itemId || headerText}`

        return (
          <TabItem
            key={key}
            testId={key}
            headerText={item.name}
            subHeaderText={item.subHeaderText}
            onTabClick={() => onTabClick(itemId)}
            isActive={itemId === activeItemId}
            variation={variation}
            hasColor={hasColor}
          />
        )
      })}

      {moreItems.length > 0 && (
        <SelectMoreTab
          items={moreItems}
          onSelect={e => onTabClick(e.target.value)}
          activeItemId={activeItemId}
          text={selectedMoreItem ? selectedMoreItem.name : 'More'}
          variation={variation}
          isActive={!!selectedMoreItem}
        />
      )}
    </TabsContainer>
  )
}

export default CheckoutTipTabs
