import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'
import Icon from 'svr/component-lib/Generic/Icon'

const Container = styled.div`
  display: flex;
  position: relative;
`

const SelectWrapper = styled.select`
  font-size: 0px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 44px;
  width: 100%;
  padding: 0 10px;
  appearance: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 14px;
  pointer-events: none;
`

const DisplayValue = styled.span`
  margin-right: ${props => props.theme.gutter.standard};
  line-height: 14px;
`

const CustomLabelSelect = ({ disabled, value, displayValue, onChange, options, ...props }) => {
  const dataProps = passDataAttrs(props)

  return (
    <Container>
      <IconWrapper>
        <DisplayValue data-test="item-modal-selected-menu-name">{displayValue}</DisplayValue>
        <Icon icon={Icon.ICONS.ChevronDown} />
      </IconWrapper>
      <SelectWrapper
        {...dataProps}
        disabled={disabled}
        value={value}
        onChange={e => {
          onChange(e.target.value)
        }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectWrapper>
    </Container>
  )
}

CustomLabelSelect.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

CustomLabelSelect.defaultProps = {
  disabled: false,
}

export default CustomLabelSelect
