import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { checkIsItemSnoozed } from '@sevenrooms/ordering/utils/menuItems'

const computeCartItemWithModifiersParams = state => ({
  itemsById: state.ordering.items.byId,
  modifierGroupsById: state.ordering.modifiers.modifierGroupsById,
  modifiersById: state.ordering.modifiers.modifiersById,
  currentCartItem: state.ordering.cart.currentCartItem,
})

const computeCartItemWithModifiersValues = ({ currentCartItem = null, itemsById = {} }) => {
  if (!currentCartItem) {
    return {}
  }

  const item = itemsById[currentCartItem.itemId]
  return {
    ...item,
    ...currentCartItem,
    isItemSnoozed: checkIsItemSnoozed(item),
  }
}

export const selectCartItemWithModifiers = createShallowEqualSelector(
  computeCartItemWithModifiersParams,
  computeCartItemWithModifiersValues
)
