import { css } from 'styled-components'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'

const inputStyle = css`
  flex: 1;
  width: 100%;

  input {
    height: 44px;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: ${props => props.theme.darkGrey};
    }
  }

  @media (max-width: ${BREAKPOINTS.maxMobile}px) {
    width: 100%;
    margin-bottom: ${props => props.theme.gutter.standard};
  }
`

export default inputStyle
