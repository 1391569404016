import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { DELIVERY, ROOM_SERVICE } from 'widget/universal/utils/ordering/constants'
import { FormatService } from '@sevenrooms/core/locales'

const computeCartWithItemsParams = state => ({
  cartItemsById: state.ordering.cart.cartItemsById,
  itemsById: state.ordering.items.byId,
  modifiersById: state.ordering.modifiers.modifiersById,
})

const computeCartWithItemsValue = ({ cartItemsById = {}, itemsById = {}, modifiersById = {} }) =>
  Object.values(cartItemsById)
    .sort((a, b) => a.index - b.index)
    .map(cartItem => ({
      ...itemsById[cartItem.itemId],
      ...cartItem,
      modifiers: annotateNestedModifiers(cartItem.modifiers, modifiersById),
    }))

const annotateNestedModifiers = (modifiers, modifiersById) =>
  modifiers.map(modifier => ({
    ...modifier,
    ...modifiersById[modifier.modifierId],
    modifiers: annotateNestedModifiers(modifier.modifiers, modifiersById),
  }))

export const selectCartWithItems = createShallowEqualSelector(computeCartWithItemsParams, computeCartWithItemsValue)

const computeTotalCartQuantityParams = state => ({
  cartItemsById: state.ordering.cart.cartItemsById,
})

const computeTotalCartQuantity = ({ cartItemsById = {} }) => _.sum(Object.values(cartItemsById).map(item => item.qty))

export const selectTotalCartQuantity = createShallowEqualSelector(computeTotalCartQuantityParams, computeTotalCartQuantity)

const computeCartItemsQuantity = ({ cartItemsById = {} }) =>
  Object.values(cartItemsById).reduce(
    (acc, item) => ({
      ...acc,
      [[item.itemId]]: (acc[item.itemId] || 0) + item.qty,
    }),
    {}
  )

export const selectCartItemsQuantity = createShallowEqualSelector(computeTotalCartQuantityParams, computeCartItemsQuantity)

export const selectNestedModifiers = (cartModifiers, currentNestedModifierIds = []) => {
  let nestedModifiers = cartModifiers
  for (const parentModifierId of currentNestedModifierIds) {
    nestedModifiers =
      _.find(nestedModifiers, {
        modifierId: parentModifierId,
      })?.modifiers || []
  }
  return nestedModifiers
}

const computeIsSubtotalAllowedParams = state => ({
  orderingSite: state.ordering.orderingSite.orderingSite,
  subtotal: state.ordering.cart.cartTotals.subtotal / 100,
  fulfillmentMethod: state.ordering.pickupDelivery.selection,
  currencyCode: state.venue.currency,
})

const computeIsSubtotalAllowed = ({ orderingSite, subtotal, fulfillmentMethod, currencyCode }) => {
  if (fulfillmentMethod === DELIVERY) {
    return checkMinMaxSubtotal(subtotal, orderingSite.deliverySubtotalMin, orderingSite.deliverySubtotalMax, 'Delivery', currencyCode)
  } else if (fulfillmentMethod === ROOM_SERVICE) {
    return checkMinMaxSubtotal(
      subtotal,
      orderingSite.roomServiceSubtotalMin,
      orderingSite.roomServiceSubtotalMax,
      'Room Service',
      currencyCode
    )
  }
  return checkMinMaxSubtotal(subtotal, orderingSite.subtotalMin, orderingSite.subtotalMax, 'Pickup', currencyCode)
}

const checkMinMaxSubtotal = (subtotal, subtotalMin, subtotalMax, methodDisplay, currencyCode) => {
  if (subtotalMin && subtotal < subtotalMin) {
    return {
      valid: false,
      message: `Minimum order subtotal is ${FormatService.formatCurrency(subtotalMin, currencyCode)} for ${methodDisplay}`,
    }
  }
  if (subtotalMax && subtotal > subtotalMax) {
    return {
      valid: false,
      message: `Maximum order subtotal is ${FormatService.formatCurrency(subtotalMax, currencyCode)} for ${methodDisplay}`,
    }
  }
  return { valid: true }
}

export const selectIsSubtotalAllowed = createShallowEqualSelector(computeIsSubtotalAllowedParams, computeIsSubtotalAllowed)
