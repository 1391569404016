// /* @flow */
import * as React from 'react'
import styled from 'styled-components'
import checkboxSrc from './checkbox.png'

/* type Props = {
  checkboxId: string,
  onClick: () => mixed,
  imgSrc: string,
  isSelected: boolean,
  isDisabled: boolean,
  isValid: boolean,
  customCheckboxIcon: Array<string | (() => mixed)>,
  customCheckboxImg: Array<string | (() => mixed)>,
} */

const CheckboxSymbol = (props /* : Props */) => (
  <CheckboxIcon
    id={props.checkboxId}
    data-test={props.dataTest}
    role="checkbox"
    aria-checked={props.isSelected}
    onClick={!props.isDisabled && props.onClick}
    isDisabled={props.isDisabled}
    isValid={props.isValid}
    customCheckboxIcon={props.customCheckboxIcon}
  >
    {props.isSelected && <CheckboxImg src={props.imgSrc} customCheckboxImg={props.customCheckboxImg} />}
  </CheckboxIcon>
)

CheckboxSymbol.defaultProps = {
  isSelected: false,
  isDisabled: false,
  isValid: true,
  imgSrc: checkboxSrc,
  customCheckboxIcon: [],
  customCheckboxImg: [],
}

const CheckboxIcon = styled.button`
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  left: 0;
  top: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.isValid ? 'rgba(204, 204, 204, 1)' : props.theme.error)};
  border-radius: 2px;
  padding: 1px;
  outline: none;
  :focus {
    border: 2px solid ${props => (props.isValid ? 'rgba(95, 193, 250, 1)' : props.theme.error)};
    padding: 0px;
  }
  background-color: ${props => (props.isDisabled ? 'lightgray' : 'white')};
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  ${props => props.customCheckboxIcon};
`

const CheckboxImg = styled.img.attrs({
  alt: 'checkmark',
})`
  width: 14px;
  height: 14px;
  ${props => props.customCheckboxImg};
`

export default CheckboxSymbol
