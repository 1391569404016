import React from 'react'
import styled, { css } from 'styled-components'
import CurrencyInput from 'svr/component-lib/Generic/TextInputs/CurrencyInput'
import Button from 'svr/component-lib/Widget/Buttons/Ordering'
import inputStyle from 'widget/universal/components/ordering/checkoutInputStyle'
import CheckoutTipTabs from 'widget/universal/components/ordering/CheckoutTipTabs'
import CheckoutTitle from 'widget/universal/components/ordering/CheckoutTitle'
import { BREAKPOINTS } from 'widget/universal/utils/ordering/constants'
import { useStoreSelector } from '@sevenrooms/admin'

const CurrencyInputText = css`
  padding: 0 4px 0 24px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: left;
`

const TipAmountContainer = styled.div`
  display: flex;
  max-width: 376px;
`

const TipOtherSpacingPlaceholder = styled.div`
  padding-top: 60px;
  @media (max-width: ${BREAKPOINTS.minDesktop}px) {
    padding-top: 0;
  }
`

const CheckoutTip = ({ venue, checkoutDetails, updateTip, tipItems }) => {
  const orderingSiteTipLabel = useStoreSelector(state => state.language.languageStrings.orderingSiteTipLabel)
  return (
    <>
      <CheckoutTitle>{orderingSiteTipLabel}</CheckoutTitle>

      <CheckoutTipTabs
        onTabClick={v => updateTip(v, true)}
        activeItemId={checkoutDetails.tipPercentage}
        items={tipItems}
        identifier="tips"
        hasColor
      />

      {checkoutDetails.tipPercentage === 'other' ? (
        <TipAmountContainer>
          <CurrencyInput
            value={checkoutDetails.tipAmount || 0}
            placeholder="0.00"
            currencySymbol={venue.currencySymbol}
            disabled={checkoutDetails.tipPercentage !== 'other'}
            onChange={v => updateTip(checkoutDetails.tipPercentage, false, v)}
            validator={() => {}}
            customInputWrapper={css`
              ${inputStyle}

              width: 200px;
              margin-bottom: ${props => props.theme.gutter.double};

              .currency-symbol {
                top: 50%;
                transform: translateY(-50%);
              }
            `}
            customInput={CurrencyInputText}
            dataTest="tip-currency-input"
          />
          <Button
            data-test="apply-custom-tip"
            onClick={() => updateTip(checkoutDetails.tipPercentage, true, checkoutDetails.tipAmount)}
            styled={css`
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            `}
          >
            Apply
          </Button>
        </TipAmountContainer>
      ) : (
        <TipOtherSpacingPlaceholder />
      )}
    </>
  )
}

export default CheckoutTip
